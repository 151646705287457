const QUERIES = {
  USERS_LIST: 'users-list',
  CARTYPES_LIST: 'cartypes-list',
  AREAS_LIST: 'areas-list',
  CARS_LIST: 'cars-list',
  BOOKINGS_LIST: 'bookings-list',
  DRIVERS_LIST: 'drivers-list',
  MANAGEUSERS_LIST: 'manageusers-list',
  BOOKINGREPORTS_LIST: 'bookingreports-list',
  AREAWISES_LIST: 'areawises-list',
  CARREPORTS_LIST: 'carreports-list',
  DRIVERREPORTS_LIST: 'driverreports-list',
  CONTACTS_LIST: 'contacts-list',
  EXTRAS_LIST: 'extras-list',
  TRANSACTIONS_LIST: 'transactions-list',
}

export {QUERIES}
