import { useEffect, useState } from 'react'
// import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
// import {initialQueryState, KTSVG} from '../../../../../../../_metronic/helpers'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { MenuComponent } from '../../../../../../_metronic/assets/ts/components'
import { KTSVG, initialQueryState } from '../../../../../../_metronic/helpers'
import { Col, Form, Row } from 'react-bootstrap'
import Select from 'react-select';



const area = [
  { value: 'Maninagar', label: 'Maninagar' },
  { value: 'Shivranjani', label: 'Shivranjani' },
  { value: 'Naroda', label: 'Naroda' },
  { value: 'Thaltej', label: 'Thaltej' },
  { value: 'Bapunagar', label: 'Bapunagar' },
  { value: 'Nikol', label: 'Nikol' },
  { value: 'Paldi', label: 'Paldi' },
];

const destination = [
  { value: 'Goa', label: 'Goa' },
  { value: 'Kerala', label: 'Kerala' },
  { value: 'Agra', label: 'Agra' },
  { value: 'Udaipur', label: 'Udaipur' },
  { value: 'Bombay', label: 'Bombay' },
  { value: 'Manali', label: 'Manali' },
  { value: 'Varanasi', label: 'Varanasi' },
];


const driver_name = [
  { value: 'Aiyub Panara', label: 'Aiyub Panara' },
  { value: 'Parth Dave', label: 'Parth Dave' },
  { value: 'Karan Patel', label: 'Karan Patel' },
  { value: 'Irfan Pathan', label: 'Irfan Pathan' },
  { value: 'Divyesh Rana', label: 'Divyesh Rana' },
  { value: 'Mayur Hole', label: 'Mayur Hole' },
  { value: 'Pravin Rathod', label: 'Pravin Rathod' },
];



const car_modal = [
  { value: 'Kia Seltos', label: 'Kia Seltos' },
  { value: 'Hyundai Exter', label: 'Hyundai Exter' },
  { value: 'Maruti Suzuki Brezza', label: 'Maruti Suzuki Brezza' },
  { value: 'Toyota Innova', label: 'Toyota Innova' },
  { value: 'Honda Amaze', label: 'Honda Amaze' },

];

const car_number = [
  { value: 'GJ-1 JG 0165', label: 'GJ-1 TT 6354' },
  { value: 'GJ-1 TT 6354', label: 'GJ-1 TT 6354' },
  { value: 'GJ-27 MK 8546', label: 'GJ-27 MK 8546' },
  { value: 'GJ-27 DG 8546', label: 'GJ-27 DG 8546' },
  { value: 'GJ-1 PM 6354', label: 'GJ-1 PM 6354' },

];

const BookingsListFilter = () => {
  const { updateState } = useQueryRequest()
  const { isLoading } = useQueryResponse()
  const [role, setRole] = useState<string | undefined>()
  const [lastLogin, setLastLogin] = useState<string | undefined>()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const resetData = () => {
    updateState({ filter: undefined, ...initialQueryState })
  }

  const filterData = () => {
    updateState({
      filter: { role, last_login: lastLogin },
      ...initialQueryState,
    })
  }


  const [selecteddestination, setSelecteddestination] = useState(null);
  const [selectedArea, setSelectedArea] = useState(null);
  const [selectedCarModal, setSelectedCarModal] = useState(null);
  const [selectedDriverName, setSelectedDriverName] = useState(null);
  const [selectedCarNumber, setSelectedCarNumber] = useState(null);

  return (
    <>
      {/* begin::Filter Button */}
      <button
        disabled={isLoading}
        type='button'
        className='btn btn-light-primary me-10'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
        Filter
      </button>
      {/* end::Filter Button */}
      {/* begin::SubMenu */}
      <div className='menu menu-sub menu-sub-dropdown w-350px w-md-400px' data-kt-menu='true'>
        {/* begin::Header */}
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        {/* begin::Content */}
        <div className='px-7 py-5' data-kt-booking-table-filter='form' >
        <Row className="mb-5">
            <Col sm={12} md={6}>
            <Form.Group className="" >
                <Form.Label> Booking No. </Form.Label>
                <Form.Control type='text' className='' placeholder="From"  />
              </Form.Group>
            </Col>

            <Col sm={12} md={6}>
            <Form.Group className="" >
                <Form.Label> &nbsp; </Form.Label>
                <Form.Control type='text' className='' placeholder="to"  />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-5">
          <Col sm={12} md={6} >
              <Form.Group className="" >
                <Form.Label> Pick Up Location </Form.Label>
                <Select
                  defaultValue={selectedArea}
                  options={area}
                   />
              </Form.Group>
            </Col>

          <Col sm={12} md={6}>
              <Form.Group className="" >
                <Form.Label> Destination </Form.Label>
                <Select
                  defaultValue={selecteddestination}
                  options={destination}
                   />
              </Form.Group>

            </Col>
         
          </Row>
          
          <Row className="mb-5">
            <Col sm={12} md={6}>
            <Form.Group className="" >
                <Form.Label> Pick Up Date </Form.Label>
                <Form.Control type='date' className='' placeholder=""  />
              </Form.Group>
            </Col>

            <Col sm={12} md={6}>
            <Form.Group className="" >
                <Form.Label> Pick Up Time </Form.Label>
                <Form.Control type='time' className='' placeholder=""  />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-5">
            <Col sm={12} md={6}>
            <Form.Group className="" >
                <Form.Label> Round Trip Date </Form.Label>
                <Form.Control type='date' className='' placeholder=""  />
              </Form.Group>
            </Col>

            <Col sm={12} md={6}>
            <Form.Group className="" >
                <Form.Label> Round Trip Time </Form.Label>
                <Form.Control type='time' className='' placeholder=""  />
              </Form.Group>
            </Col>
          </Row>

          {/* <Row className="mb-5">
            <Col sm={12} md={6}>
            <Form.Group className="" >
                <Form.Label> User Email </Form.Label>
                <Select
                  defaultValue={selectedUserEmail}
                  options={user_email}
                   />
              </Form.Group>
            </Col>

            <Col sm={12} md={6}>
            <Form.Group className="" >
                <Form.Label> User Phone No </Form.Label>
                <Select
                  defaultValue={selectedUserContact}
                  options={user_contact}
                   />
              </Form.Group>
            </Col>
          </Row> */}
{/* 
          <Row className="mb-5">
            <Col sm={12} md={6}>
            <Form.Group className="" >
                <Form.Label> Driver Name </Form.Label>
                <Select
                  defaultValue={selectedDriverName}
                  options={driver_name}
                   />
              </Form.Group>
            </Col>

            <Col sm={12} md={6}>
            <Form.Group className="" >
                <Form.Label> Licence No </Form.Label>
                <Select
                  defaultValue={selectedLicenceNumber}
                  options={licence_number}
                   />
              </Form.Group>
            </Col>
          </Row> */}
          {/* <Row>
          <Col sm={12} md={6}  className="mb-5">
              <Form.Label>  Total Amout </Form.Label>
              <Form.Control  className='form-control form-control-solid' placeholder="min" required />
            </Col>

            <Col sm={12} md={6}  className="mb-5">
              <Form.Label>  </Form.Label>
              <Form.Control  className='form-control form-control-solid' placeholder="max" required />
            </Col>
            </Row> */}
              <Row className="mb-5">
              <Col sm={12} md={6}>
              <Form.Group className="" >
                <Form.Label> Car Modal </Form.Label>
                <Select
                  defaultValue={selectedCarModal}
                  options={car_modal}
                />
              </Form.Group>
            </Col>
            <Col sm={12} md={6} >
              <Form.Group className="" >
                <Form.Label> Car Number </Form.Label>
                <Select
                  defaultValue={selectedCarNumber}
                  options={car_number}
                />
              </Form.Group>

            </Col>
            
          </Row>

            
            {/* <Col sm={12} md={6} >
              <Form.Label> Paid By </Form.Label>
              <Form.Select aria-label="Default select example">
                <option value="1">Select</option>
                <option value="1">Credite Card</option>
                <option value="2"> Debit Card</option>
              </Form.Select>
            </Col> */}

           <Row>
            <Col sm={12} md={6}>
            <Form.Group className="" >
                <Form.Label> Driver Name </Form.Label>
                <Select
                  defaultValue={selectedDriverName}
                  options={driver_name}
                   />
              </Form.Group>
            </Col>
            <Col sm={12} md={6} >
              <Form.Label> Status </Form.Label>
              <Form.Select aria-label="Default select example">
                <option value="1">Select</option>
                <option value="1">Booked</option>
                <option value="2"> Ongoing</option>
                <option value="3">Completed</option>
              </Form.Select>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col sm={12} md={6}>
            <Form.Group className="" >
                <Form.Label> Rate </Form.Label>
                <Form.Control type='text' className='' placeholder="From"  />
              </Form.Group>
            </Col>

            <Col sm={12} md={6}>
            <Form.Group className="" >
                <Form.Label> &nbsp; </Form.Label>
                <Form.Control type='text' className='' placeholder="to"  />
              </Form.Group>
            </Col>
          </Row>
<div className='separator my-6'></div>

          {/* begin::Actions */}
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              disabled={isLoading}
              onClick={filterData}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-booking-table-filter='reset'
            >
              Reset
            </button>
            <button
              disabled={isLoading}
              type='button'
              onClick={resetData}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-booking-table-filter='filter'
            >
              Apply
            </button>
          </div>
          {/* end::Actions */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::SubMenu */}
    </>
  )
}

export { BookingsListFilter }
