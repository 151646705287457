import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Select from 'react-select';
import AddEditDriverDetails from "./AddEditDriverDetails";




const AddDriverDetails = () => {

    const navigate = useNavigate()
    const navigateToDriverListing = () => {
        // 👇️ navigate to /contacts
        navigate('/driver-list')
    }

    

    return (

        <>

            <Row className="row mx-0">
                <Col md={8} sm={12}><h1> Add Driver Details </h1></Col>
                <Col md={4} sm={12} className="text-end">
                    <button
                        onClick={navigateToDriverListing}
                        type='button'
                        value=''
                        className='btn btn-sm fs-5 me-5 btn btn-primary'
                    >
                        Back
                    </button>
                </Col>
            </Row>



            <div className="card p-6  mt-5">
                <AddEditDriverDetails />
                
            </div>
        </>
    )
}

export default AddDriverDetails