import axios, {AxiosResponse} from 'axios'
// import {ID, Response} from '../../../../../../_metronic/helpers'
import {Transaction, TransactionsQueryResponse} from './_models'
import { ID, Response } from '../../../../../../_metronic/helpers'
// import { ID, Response } from '../../../../../_metronic/helpers'

const API_URL = process.env.REACT_APP_THEME_API_URL
const TRANSACTION_URL = `${API_URL}/transaction`
const GET_TRANSACTIONS_URL = `${API_URL}/users/query`

const getTransactions = (query: string): Promise<TransactionsQueryResponse> => {
  return axios
    .get(`${GET_TRANSACTIONS_URL}?${query}`)
    .then((d: AxiosResponse<TransactionsQueryResponse>) => d.data)
}

const getTransactionById = (id: ID): Promise<Transaction | undefined> => {
  return axios
    .get(`${TRANSACTION_URL}/${id}`)
    .then((response: AxiosResponse<Response<Transaction>>) => response.data)
    .then((response: Response<Transaction>) => response.data)
}

const createTransaction = (transaction: Transaction): Promise<Transaction | undefined> => {
  return axios
    .put(TRANSACTION_URL, transaction)
    .then((response: AxiosResponse<Response<Transaction>>) => response.data)
    .then((response: Response<Transaction>) => response.data)
}

const updateTransaction = (transaction: Transaction): Promise<Transaction | undefined> => {
  return axios
    .post(`${TRANSACTION_URL}/${transaction.id}`, transaction)
    .then((response: AxiosResponse<Response<Transaction>>) => response.data)
    .then((response: Response<Transaction>) => response.data)
}

const deleteTransaction = (transactionId: ID): Promise<void> => {
  return axios.delete(`${TRANSACTION_URL}/${transactionId}`).then(() => {})
}

const deleteSelectedTransactions = (transactionIds: Array<ID>): Promise<void> => {
  const requests = transactionIds.map((id) => axios.delete(`${TRANSACTION_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getTransactions, deleteTransaction, deleteSelectedTransactions, getTransactionById, createTransaction, updateTransaction}
