/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { PageTitle } from '../../../_metronic/layout/core'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  MixedWidget8,
  CardsWidget7,
  CardsWidget17,
  CardsWidget20,
  ListsWidget26,
  EngageWidget10,
  ChartsWidget1,
} from '../../../_metronic/partials/widgets'
import { ChartsWidget9 } from '../../../_metronic/partials/widgets/charts/ChartsWidget9';

const totaluser = 100;
const activeuser = 100;
const varifieduser = 78;
const totalcar = 90;
const availablecar = 60;
const totalbooking = 80;
const todaybooking = 30;
const totaldriver = 100;

const DashboardPage: FC = () => (

  <>
    <div className='dash-wrapper'>
           
      <div className='row'>
        <h2 > Dashboard </h2>
      </div>

      <div className='row dashboard-data'>
      <div className='col-sm-6 col-md-4 col-lg-3 mb-6'>
          <div className='card border total-user'>
            <div className='card_body'>
              <div className='row flex-row flex-row-reverse py-5 px-5'>
                <div className='col-8 text-start d-flex flex-column justify-content-center py-2 px-6'>
                <h2 className='text-start text-charcl fs-4x'> 80 </h2>
                  <h4 className="text-start"> Total Booking </h4>
                  {/* <div className='badge badge-light-info fs-7 py-3 px-5 rounded-pill fw-semibold mt-5'>Total 76</div> */}
                </div>
                <div className='col-4 mx-auto p-2'>
                  <CircularProgressbar
                    value={totalbooking}
                    text={`${totalbooking}%`}
                    styles={buildStyles({
                      textSize: '18px',
                      // pathTransitionDuration: 0.5,
                      pathColor: `#2471A3`,
                      // textColor: '#f88',
                      trailColor: '#A9CCE3',
                      //  backgroundColor: '#E30016',
                    })}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-sm-6 col-md-4 col-lg-3 mb-6'>
          <div className='card border available-user'>
            <div className='card_body'>
              <div className='row flex-row flex-row-reverse py-5 px-5'>
                <div className='col-8 text-start d-flex flex-column justify-content-center py-2 px-6'>
                <h2 className='text-olive text-start  fs-4x'> 4 </h2>
                  <h4 className="text-start"> Today's Booking </h4>
                  {/* <div className='badge badge-light-info fs-7 py-3 px-5 rounded-pill fw-semibold mt-5'>Total 4</div> */}
                </div>
                <div className='col-4 mx-auto p-2'>
                  <CircularProgressbar
                    value={todaybooking}
                    text={`${todaybooking}%`}
                    styles={buildStyles({
                      textSize: '18px',
                      // pathTransitionDuration: 0.5,
                      pathColor: `#16A085`,
                      // textColor: '#f88',
                      trailColor: '#A2D9CE',
                      //  backgroundColor: '#E30016',
                    })}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-sm-6 col-md-4 col-lg-3 mb-6 mb-6'>
          <div className='card border total-user'>
            <div className='card_body'>
              <div className='row flex-row flex-row-reverse py-5 px-5'>
                <div className='col-8 text-start d-flex flex-column justify-content-center py-2 px-6'>
                < h2 className=' text-start text-blue fs-4x'> 55 </h2>
                  <h4 className="text-start"> Total Users </h4>
                  {/* <div className='badge badge-light-success fs-7 py-3 px-5 rounded-pill fw-semibold mt-5'>Active 55</div> */}
                </div>
                <div className='col-4 mx-auto p-2'>
                  <CircularProgressbar
                    value={totaluser}
                    text={`${totaluser}%`}
                    styles={buildStyles({
                      textSize: '18px',
                      // pathTransitionDuration: 0.5,
                      pathColor: `#0F4CB6`,
                      // textColor: '#f88',
                      trailColor: 'D1E2FF',
                      //  backgroundColor: '#E30016',
                    })}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-sm-6 col-md-4 col-lg-3 mb-6'>
          <div className='card border active-user'>
            <div className='card_body'>
              <div className='row flex-row flex-row-reverse py-5 px-5'>
                <div className='col-8 text-start d-flex flex-column justify-content-center py-2 px-6'>
                <h2 className='text-teal text-start fs-4x'> 55 </h2>
                  <h4 className="text-start "> Active Users </h4>
                  {/* <div className='badge badge-light-info fs-7 py-3 px-5 rounded-pill fw-semibold mt-5'>Total 55</div> */}
                </div>
                <div className='col-4 mx-auto p-2'>
                  <CircularProgressbar
                    value={activeuser}
                    text={`${activeuser}%`}
                    styles={buildStyles({
                      textSize: '18px',
                      // pathTransitionDuration: 0.5,
                      pathColor: `#17B1D3`,
                      // textColor: '#f88',
                      trailColor: '#D2F7FF',
                      //  backgroundColor: '#E30016',
                    })}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='col-sm-6 col-md-4 col-lg-3 mb-6'>
          <div className='card border verified-user'>
            <div className='card_body'>
              <div className='row flex-row flex-row-reverse py-5 px-5'>
                <div className='col-8 text-start d-flex flex-column justify-content-center py-2 px-6'>
                <h2 className='text-orange text-start  fs-4x'> 30 </h2>
                  <h4 className="text-start"> Varified Users </h4>
                  {/* <div className='badge badge-light-info fs-7 py-3 px-5 rounded-pill fw-semibold mt-5'>Total 55</div> */}
                </div>
                <div className='col-4 mx-auto p-2'>
                  <CircularProgressbar
                    value={varifieduser}
                    text={`${varifieduser}%`}
                    styles={buildStyles({
                      textSize: '18px',
                      // pathTransitionDuration: 0.5,
                      pathColor: `#F39C12 `,
                      // textColor: '#f88',
                      trailColor: '#F5CBA7',
                      //  backgroundColor: '#E30016',
                    })}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='col-sm-6 col-md-4 col-lg-3 mb-6'>
          <div className='card border total-car'>
            <div className='card_body'>
              <div className='row flex-row flex-row-reverse py-5 px-5'>
                <div className='col-8 text-start d-flex flex-column justify-content-center py-2 px-6'>
                <h2 className='text-purple text-start  fs-4x'> 6 </h2>
                  <h4 className="text-start"> Total Cars </h4>
                  {/* <div className='badge badge-light-success fs-7 py-3 px-5 rounded-pill fw-semibold mt-5'>Active 6</div> */}
                </div>
                <div className='col-4  mx-auto p-2'>
                  <CircularProgressbar
                    value={totalcar}
                    text={`${totalcar}%`}
                    styles={buildStyles({
                      textSize: '18px',
                      // pathTransitionDuration: 0.5,
                      pathColor: `#8E44AD`,
                      // textColor: '#f88',
                      trailColor: '#E8DAEF',
                      //  backgroundColor: '#E30016',
                    })}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='col-sm-6 col-md-4 col-lg-3 mb-6'>
          <div className='card border available-user'>
            <div className='card_body'>
              <div className='row flex-row flex-row-reverse py-5 px-5'>
                <div className='col-8 text-start d-flex flex-column justify-content-center py-2 px-6'>
                <h2 className='text-info text-start  fs-4x'> 4 </h2>
                  <h4 className="text-start"> Available Cars </h4>
                  {/* <div className='badge badge-light-success fs-7 py-3 px-5 rounded-pill fw-semibold mt-5'>Active 4</div> */}
                </div>
                <div className='col-4 mx-auto p-2'>
                  <CircularProgressbar
                    value={availablecar}
                    text={`${availablecar}%`}
                    styles={buildStyles({
                      textSize: '18px',
                      // pathTransitionDuration: 0.5,
                      pathColor: `#7239ea`,
                      // textColor: '#f88',
                      trailColor: '#e7dcff',
                      //  backgroundColor: '#E30016',
                    })}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='col-sm-6 col-md-4 col-lg-3 mb-6'>
          <div className='card border driver'>
            <div className='card_body'>
              <div className='row flex-row flex-row-reverse py-5 px-5'>
                <div className='col-8 text-start d-flex flex-column justify-content-center py-2 px-6'>
                <h2 className=' text-start  text-rust fs-4x'> 55 </h2>
                  <h4 className="text-start"> Total Drivers </h4>
                  {/* <div className='badge badge-light-success fs-7 py-3 px-5 rounded-pill fw-semibold mt-5'>Active 4</div> */}
                </div>
                <div className='col-4 mx-auto p-2'>
                  <CircularProgressbar
                    value={totaldriver}
                    text={`${totaldriver}%`}
                    styles={buildStyles({
                      textSize: '18px',
                      // pathTransitionDuration: 0.5,
                      pathColor: `#C0392B`,
                      // textColor: '#f88',
                      trailColor: '#E6B0AA',
                      //  backgroundColor: '#E30016',
                    })}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      
      </div>
      <div className='row'>
        <div className='col-12 mt-10'>
          <div className='card border'>
            {/* <MixedWidget8
          className='card-xxl-stretch mb-xl-3'
          chartColor='success'
          chartHeight='150px'
        />*/}

            <ChartsWidget1 className='mb-5' />
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-12 mt-10'>
          <div className='card border'>
            {/* <MixedWidget8
          className='card-xxl-stretch mb-xl-3'
          chartColor='success'
          chartHeight='150px'
        />*/}

            <ChartsWidget9 className='mb-5' />
          </div>
        </div>
      </div>
      <div className='row mt-10 dashboard-data'>
        <div className='col-12  mb-6'>
          <div className='card border p-8'>
            <div className='card_body'>
              <h3 className=' mb-5 text-black'> Recent Top 5 Bookings Requests </h3>

              <table className="table  table-responsive dash-table">
                <thead>
                  <tr>
                  
                    <th scope="col" className='p-3 fw-bold fs-4'>Booking No.</th>
                    <th scope="col" className='fw-bold fs-4'>Pickup Location</th>
                    <th scope="col" className='fw-bold fs-4'>Pickup Date/time</th>
                    <th scope="col" className='p-3 fw-bold fs-4'>Destination</th>
                    <th scope="col" className='fw-bold fs-4'>Round Trip Date/Time</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th className='p-3'>1532</th>
                    <td>Prahladnagar</td>
                    <td>17 Mar 2024, 2:30pm</td>
                    <th className='p-3'>Jaipur</th>
                    <td>21 Mar 2024, 5:00pm</td>
                  </tr>
                  <tr>
                  <th className='p-3'>1531</th>
                    
                    <td>Naroda</td>
                    <td>20 Mar 2024, 12:00noon</td>
                    <th className='p-3'>Ooty</th>
                    <td>29 Mar 2024  6:00pm</td>
                  </tr>

                  <tr>
                  <th className='p-3'>1530</th>
                    <td>Maninagar</td>
                    <td>24 Mar 2024 1:00am</td>
                    <th className='p-3'>Shimala</th>
                    <td>2 Apr 2024 11:00pm</td>
                  </tr>


                  <tr>
                  <th className='p-3'>1529</th>
                    <td>Nikol</td>
                    <td>26 Mar 2024, 12:00pm</td>
                    <th className='p-3'>Haridwar</th>
                    <td>31 Mar 2024 9:00pm</td>
                  </tr>

                  <tr>
                  <th className='p-3'>1528</th>
                    <td>Memnagar</td>
                    <td>26 Mar 2024 4:00pm</td>
                    <th className='p-3'>Goa</th>
                    <td>2 Apr 2024 6:00pm</td>
                  </tr>

                </tbody>
              </table>

            </div>
          </div>
        </div>
      </div>
      <div className='row mt-8 dashboard-data'>
        <div className='col-12  mb-6'>
          <div className='card border p-8'>
            <div className='card_body'>
              <h3 className=' mb-5 text-black'> Recent 5 Completed Trips </h3>

              <table className="table  table-responsive dash-table">
                <thead>
                  <tr>
                  <th scope="col" className='p-3 fw-bold fs-4'>Booking No.</th>
                  <th scope="col" className='fw-bold fs-4'>Pickup Location</th>
                  <th scope="col" className='fw-bold fs-4'>Pickup Date/time</th>
                  <th scope="col" className='p-3 fw-bold fs-4'>Name Of Destination</th>
                  <th scope="col" className='fw-bold fs-4'>Round Trip Date/Time</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                  <td scope="col" className='p-3 fs-4'>1492</td>
                    <td>Shivranjani</td>
                    <td>1 Mar 2024, 2:30pm</td>
                    <th className='p-3'>Agra</th>
                    <td>4 Mar 2024, 5:00pm</td>
                  </tr>
                  <tr>
                  <td scope="col" className='p-3 fs-4'>1491</td>
                    <td>Gatlodiya</td>
                    <td>3 Mar 2024, 12:00noon</td>
                    <th className='p-3'>Agra</th>
                    <td>8 Mar 2024  6:00pm</td>
                  </tr>

                  <tr>
                  <td scope="col" className='p-3 fs-4'>1490</td>
                    <td>Bapunagar</td>
                    <td>4 Mar 2024, 1:00am</td>
                    <th className='p-3'>Pondicherry</th>
                    <td>12 Mar 2024 11:00pm</td>
                  </tr>


                  <tr>
                  <td scope="col" className='p-3 fs-4'>1489</td>
                    <td>Nikol</td>
                    <td>7 Mar 2024, 12:00pm</td>
                    <th className='p-3'>Rishikesh</th>
                    <td>11 Mar 2024 9:00pm</td>
                  </tr>

                  <tr>
                  <td scope="col" className='p-3 fs-4'>1488</td>
                    <td>Memnagar</td>
                    <td>8 Mar 2024 4:00pm</td>
                    <th className='p-3'>Rajasthan</th>
                    <td>12 Apr 2024 6:00pm</td>
                  </tr>

                </tbody>
              </table>

            </div>
          </div>
        </div>
      </div>

    </div>










  </>
)

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <DashboardPage />
    </>
  )
}

export { DashboardWrapper }
