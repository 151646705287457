import {useQuery} from 'react-query'
 import {CarreportEditModalForm} from './CarreportEditModalForm'
//  import {isNotEmpty, QUERIES} from '../../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
 import {getCarreportById} from '../core/_requests'
import { QUERIES, isNotEmpty } from '../../../../../../_metronic/helpers'
// import { QUERIES, isNotEmpty } from '../../../../../_metronic/helpers'

const CarreportEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const dataresponse = {name:"demo"}
  const {
    isLoading,
    data: carreport,
    error,
  } = useQuery(
    `${QUERIES.CARREPORTS_LIST}-carreport-${itemIdForUpdate}`,
    () => {
      return getCarreportById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
       // setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return <CarreportEditModalForm isCarreportLoading={false} carreport={{id: undefined}} />
  }

  if (itemIdForUpdate) {
    return <CarreportEditModalForm isCarreportLoading={false} carreport={dataresponse} />
  }

  return null
}

export {CarreportEditModalFormWrapper}
