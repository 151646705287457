import {useQuery} from 'react-query'
 import {BookingreportEditModalForm} from './BookingreportEditModalForm'
//  import {isNotEmpty, QUERIES} from '../../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import { QUERIES, isNotEmpty } from '../../../../../../_metronic/helpers'
  import {getBookingreportById} from '../core/_requests'
// import { QUERIES, isNotEmpty } from '../../../../../_metronic/helpers'

const BookingreportEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const dataresponse = {name:"demo"}
  const {
    isLoading,
    data: bookingreport,
    error,
  } = useQuery(
    `${QUERIES.BOOKINGREPORTS_LIST}-bookingreport-${itemIdForUpdate}`,
    () => {
      return getBookingreportById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
       // setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return <BookingreportEditModalForm isBookingreportLoading={false} bookingreport={{id: undefined}} />
  }

  if (itemIdForUpdate) {
    return <BookingreportEditModalForm isBookingreportLoading={false} bookingreport={dataresponse} />
  }

  return null
}

export {BookingreportEditModalFormWrapper}
