import React from "react";
import { DriverreportListWrapper } from "./driverreports-list/DriverreportsList";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';


const totaldriver = 100;
const availabledriver = 70;


const DriverAssignmentReport = () => {

    return (

        <>
<h2 > Driver Assignment Report</h2>
            <div className="row justify-content-start align-items-end my-6">
                
                
                    <div className='col-sm-12 col-md-4 col-lg-3'>
                        <div className='card  driver' style={{ border: '1px solid #ddd' }}>
                            <div className='card_body'>
                                <div className='row flex-row flex-row-reverse py-5 px-5'>
                                    <div className='col-8 text-start d-flex flex-column justify-content-center py-2 px-6'>
                                        <h2 className=' text-start  text-orange fs-4x'> 55 </h2>
                                        <h4 className="text-start"> Total Drivers </h4>
                                        {/* <div className='badge badge-light-success fs-7 py-3 px-5 rounded-pill fw-semibold mt-5'>Active 4</div> */}
                                    </div>
                                    <div className='col-4 mx-auto p-2'>
                                        <CircularProgressbar
                                            value={totaldriver}
                                            text={`${totaldriver}%`}
                                            styles={buildStyles({
                                                textSize: '18px',
                                                // pathTransitionDuration: 0.5,
                                                pathColor: `#FF5733`,
                                                // textColor: '#f88',
                                                trailColor: '#E97451',
                                                //  backgroundColor: '#E30016',
                                            })}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col-sm-12 col-md-4 col-lg-3'>
                        <div className='card  driver' style={{ border: '1px solid #ddd' }}>
                            <div className='card_body'>
                                <div className='row flex-row flex-row-reverse py-5 px-5'>
                                    <div className='col-8 text-start d-flex flex-column justify-content-center py-2 px-6'>
                                        <h2 className=' text-start  text-success fs-4x'> 70 </h2>
                                        <h4 className="text-start"> Available Drivers </h4>
                                        {/* <div className='badge badge-light-success fs-7 py-3 px-5 rounded-pill fw-semibold mt-5'>Active 4</div> */}
                                    </div>
                                    <div className='col-4 mx-auto p-2'>
                                        <CircularProgressbar
                                            value={availabledriver}
                                            text={`${availabledriver}%`}
                                            styles={buildStyles({
                                                textSize: '18px',
                                                // pathTransitionDuration: 0.5,
                                                pathColor: `#28a745`,
                                                // textColor: '#f88',
                                                trailColor: '#c2f1bb',
                                                //  backgroundColor: '#E30016',
                                            })}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
           
            <DriverreportListWrapper />

        </>
    )
} 

export default DriverAssignmentReport