// @ts-nocheck
import {Column} from 'react-table'
import {TransactionInfoCell} from './TransactionInfoCell'
import {TransactionLastLoginCell} from './TransactionLastLoginCell'
import {TransactionTwoStepsCell} from './TransactionTwoStepsCell'
import {TransactionActionsCell} from './TransactionActionsCell'
import {TransactionSelectionCell} from './TransactionSelectionCell'
import {TransactionCustomHeader} from './TransactionCustomHeader'
import {TransactionSelectionHeader} from './TransactionSelectionHeader'
import {Transaction} from '../../core/_models'

const transactionsColumns: ReadonlyArray<Column<Transaction>> = [
  // {
  //   Header: (props) => <TransactionSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <TransactionSelectionCell id={props.data[props.row.index].id} />,
  // },

  {
    Header: (props) => <TransactionCustomHeader tableProps={props} title='Date' className='min-w-125px text-black' />,
    accessor: 'date',
    Cell: ({...props}) => "15 Feb 2024"
  },


  {
    Header: (props) => <TransactionCustomHeader tableProps={props} title='Tras. ID' className='min-w-125px text-black' />,
    accessor: 'tra_id',
    Cell: ({...props}) => "1708031724431131"
  },

  {
    Header: (props) => <TransactionCustomHeader tableProps={props} title='Payment Method' className='min-w-125px text-black' />,
    accessor: 'tra_method',
    Cell: ({...props}) => "Credit Card"
  },

  {
    Header: (props) => <TransactionCustomHeader tableProps={props} title='Card Type' className='min-w-125px text-black' />,
    accessor: 'card_type',
    Cell: ({...props}) => "Mastercard"
  },

  {
    Header: (props) => <TransactionCustomHeader tableProps={props} title='Rate' className='min-w-125px text-black' />,
    accessor: 'Rate',
    Cell: ({...props}) => "QAR 15000"
  },


  // {
  //   Header: (props) => <TransactionCustomHeader tableProps={props} title='Pending Amount' className='min-w-125px text-black' />,
  //   accessor: 'car_modal',
  //   Cell: ({...props}) => "2000"
  // },

  {
    Header: (props) => (
      <TransactionCustomHeader tableProps={props} title='Status' className='min-w-125px' />
    ),
    id: 'two_steps',
    Cell: ({...props}) => <TransactionTwoStepsCell two_steps={props.data[props.row.index].two_steps} />,
  },
 
  // {
  //   Header: (props) => (
  //     <TransactionCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px text-black' />
  //   ),
  //   id: 'actions',
  //   Cell: ({...props}) => <TransactionActionsCell id={props.data[props.row.index].id} />,
  // },
]

export {transactionsColumns}
