import {useQuery} from 'react-query'
//  import {TransactionEditModalForm} from './TransactionEditModalForm'
//  import {isNotEmpty, QUERIES} from '../../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import { QUERIES, isNotEmpty } from '../../../../../../_metronic/helpers'
  import {getTransactionById} from '../core/_requests'
import { TransactionEditModalForm } from './TransactionEditModalForm'
// import { QUERIES, isNotEmpty } from '../../../../../_metronic/helpers'

const TransactionEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const dataresponse = {name:"demo"}
  const {
    isLoading,
    data: transaction,
    error,
  } = useQuery(
    `${QUERIES.AREAWISES_LIST}-transaction-${itemIdForUpdate}`,
    () => {
      return getTransactionById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
       // setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return <TransactionEditModalForm isTransactionLoading={false} transaction={{id: undefined}} />
  }

  if (itemIdForUpdate) {
    return <TransactionEditModalForm isTransactionLoading={false} transaction={dataresponse} />
  }

  return null
}

export {TransactionEditModalFormWrapper}
