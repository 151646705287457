// @ts-nocheck
import {Column} from 'react-table'
import {BookingInfoCell} from './BookingInfoCell'
import {BookingLastLoginCell} from './BookingLastLoginCell'
import {BookingTwoStepsCell} from './BookingTwoStepsCell'
import {BookingActionsCell} from './BookingActionsCell'
import {BookingSelectionCell} from './BookingSelectionCell'
import {BookingCustomHeader} from './BookingCustomHeader'
import {BookingSelectionHeader} from './BookingSelectionHeader'
import {Booking} from '../../core/_models'

const bookingsColumns: ReadonlyArray<Column<Booking>> = [
  // {
  //   Header: (props) => <BookingSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <BookingSelectionCell id={props.data[props.row.index].id} />,
  // },

  {
    Header: (props) => <BookingCustomHeader tableProps={props} title='Booking No' className='min-w-125px text-black' />,
    accessor: 'booking_number',
    Cell: ({...props}) => "1531"
  },
  {
    Header: (props) => <BookingCustomHeader tableProps={props} title='Pick Up Location' className='min-w-125px text-black' />,
    accessor: 'pick_up_location',
    Cell: ({...props}) => "Maninagar"
  },
  {
    Header: (props) => <BookingCustomHeader tableProps={props} title='Destination' className='min-w-125px text-black' />,
    accessor: 'destination',
    Cell: ({...props}) => "Udaipur"
  },
  {
    Header: (props) => <BookingCustomHeader tableProps={props} title='Pick Up Date' className='min-w-125px text-black' />,
    accessor: 'pick_up_date',
    Cell: ({...props}) => "24 Mar 2024"
  },
  {
    Header: (props) => <BookingCustomHeader tableProps={props} title='Pick Up Time' className='min-w-125px text-black' />,
    accessor: 'pick_time',
    Cell: ({...props}) => "8:00a.m."
  },

  {
    Header: (props) => <BookingCustomHeader tableProps={props} title='Round Trip Date' className='min-w-125px text-black' />,
    accessor: 'round_trip_date',
    Cell: ({...props}) => "29 Mar 2024"
  },
  {
    Header: (props) => <BookingCustomHeader tableProps={props} title='Round Trip Time' className='min-w-125px text-black' />,
    accessor: 'round_trip_time',
    Cell: ({...props}) => "11:00p.m."
  },

  // {
  //   Header: (props) => <BookingCustomHeader tableProps={props} title='User Email' className='min-w-125px text-black' />,
  //   accessor: 'user_email',
  //   Cell: ({...props}) => "thomsonvellay@rentify.com"
  // },
  {
    Header: (props) => <BookingCustomHeader tableProps={props} title='Car Modal' className='min-w-125px text-black' />,
    accessor: 'car_modal',
    Cell: ({...props}) => "Toyota Corolla"
  },
  {
    Header: (props) => <BookingCustomHeader tableProps={props} title='Car Number' className='min-w-125px text-black' />,
    accessor: 'car_number',
    Cell: ({...props}) => "GJ-1 JG 0165"
  },

  {
    Header: (props) => <BookingCustomHeader tableProps={props} title='Rate' className='min-w-125px text-black' />,
    accessor: 'rate',
    Cell: ({...props}) => "QAR 6500"
  },

  // {
  //   Header: (props) => <BookingCustomHeader tableProps={props} title='Paid By' className='min-w-125px text-black' />,
  //   accessor: 'paid_by',
  //   Cell: ({...props}) => "Credit Card"
  // },

  {
    Header: (props) => <BookingCustomHeader tableProps={props} title='Driver Name' className='min-w-125px text-black' />,
    accessor: 'driver_name',
    Cell: ({...props}) => "Thomson Vellay"
  },


  {
    Header: (props) => (
      <BookingCustomHeader tableProps={props} title='Status' className='min-w-100px' />
    ),
    id: 'two_steps',
    Cell: ({...props}) => <BookingTwoStepsCell two_steps={props.data[props.row.index].two_steps} />,
  },
 
  {
    Header: (props) => (
      <BookingCustomHeader tableProps={props} title='Actions' className='text-end  min-w-125px text-black' />
    ),
    id: 'actions',
    Cell: ({...props}) => <BookingActionsCell id={props.data[props.row.index].id} />,
  },
]

export {bookingsColumns}
