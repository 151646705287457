import React from "react";
import { CarreportsListWrapper } from "./carreports-list/CarreportsList";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';


const totalcar= 100;
const availablecar = 50;
const maintenancecar = 30;
const repairingcar = 20;


const CarInventoryReport =() => {

    return(

        <>
         <h2 className=""> Car Inventory Reports</h2>
                <div className="row justify-content-start align-items-end my-6">
                    <div className='col-sm-12 col-md-6 col-lg-3'>
                        <div className='card  driver' style={{ border: '1px solid #ddd' }}>
                            <div className='card_body'>
                                <div className='row flex-row flex-row-reverse py-5 px-5'>
                                    <div className='col-8 text-start d-flex flex-column justify-content-center py-2 px-6'>
                                        <h2 className=' text-start  text-orange fs-4x'> 8 </h2>
                                        <h4 className="text-start"> Total Car </h4>
                                        {/* <div className='badge badge-light-success fs-7 py-3 px-5 rounded-pill fw-semibold mt-5'>Active 4</div> */}
                                    </div>
                                    <div className='col-4 mx-auto p-2'>
                                        <CircularProgressbar
                                            value={totalcar}
                                            text={`${totalcar}%`}
                                            styles={buildStyles({
                                                textSize: '18px',
                                                // pathTransitionDuration: 0.5,
                                                pathColor: `#FF5733`,
                                                // textColor: '#f88',
                                                trailColor: '#E97451',
                                                //  backgroundColor: '#E30016',
                                            })}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col-sm-12 col-md-6 col-lg-3'>
                        <div className='card  driver' style={{ border: '1px solid #ddd' }}>
                            <div className='card_body'>
                                <div className='row flex-row flex-row-reverse py-5 px-5'>
                                    <div className='col-8 text-start d-flex flex-column justify-content-center py-2 px-6'>
                                        <h2 className=' text-start  text-success fs-4x'> 4 </h2>
                                        <h4 className="text-start"> Available car </h4>
                                        {/* <div className='badge badge-light-success fs-7 py-3 px-5 rounded-pill fw-semibold mt-5'>Active 4</div> */}
                                    </div>
                                    <div className='col-4 mx-auto p-2'>
                                        <CircularProgressbar
                                            value={availablecar}
                                            text={`${availablecar}%`}
                                            styles={buildStyles({
                                                textSize: '18px',
                                                // pathTransitionDuration: 0.5,
                                                pathColor: `#28a745`,
                                                // textColor: '#f88',
                                                trailColor: '#c2f1bb',
                                                //  backgroundColor: '#E30016',
                                            })}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col-sm-12 col-md-6 col-lg-3'>
                        <div className='card  driver' style={{ border: '1px solid #ddd' }}>
                            <div className='card_body'>
                                <div className='row flex-row flex-row-reverse py-5 px-5'>
                                    <div className='col-8 text-start d-flex flex-column justify-content-center py-2 px-6'>
                                        <h2 className=' text-start text-warning fs-4x'> 3 </h2>
                                        <h4 className="text-start"> Under Maintenance </h4>
                                        {/* <div className='badge badge-light-success fs-7 py-3 px-5 rounded-pill fw-semibold mt-5'>Active 4</div> */}
                                    </div>
                                    <div className='col-4 mx-auto p-2'>
                                        <CircularProgressbar
                                            value={maintenancecar}
                                            text={`${maintenancecar}%`}
                                            styles={buildStyles({
                                                textSize: '18px',
                                                // pathTransitionDuration: 0.5,
                                                pathColor: `#ffc107`,
                                                // textColor: '#f88',
                                                trailColor: '#efe0b5',
                                                //  backgroundColor: '#E30016',
                                            })}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-12 col-md-6 col-lg-3'>
                        <div className='card  driver' style={{ border: '1px solid #ddd' }}>
                            <div className='card_body'>
                                <div className='row flex-row flex-row-reverse py-5 px-5'>
                                    <div className='col-8 text-start d-flex flex-column justify-content-center py-2 px-6'>
                                        <h2 className=' text-start  text-danger fs-4x'> 1 </h2>
                                        <h4 className="text-start"> Repairing </h4>
                                        {/* <div className='badge badge-light-success fs-7 py-3 px-5 rounded-pill fw-semibold mt-5'>Active 4</div> */}
                                    </div>
                                    <div className='col-4 mx-auto p-2'>
                                        <CircularProgressbar
                                            value={repairingcar}
                                            text={`${repairingcar}%`}
                                            styles={buildStyles({
                                                textSize: '18px',
                                                // pathTransitionDuration: 0.5,
                                                pathColor: `rgb(227, 0, 22)`,
                                                // textColor: '#f88',
                                                trailColor: '#f6c3c8',
                                                //  backgroundColor: '#E30016',
                                            })}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
         <CarreportsListWrapper />
        </>
    )
}

export default CarInventoryReport