import axios, {AxiosResponse} from 'axios'
// import {ID, Response} from '../../../../../../_metronic/helpers'
import {Carreport, CarreportsQueryResponse} from './_models'
import { ID, Response } from '../../../../../../_metronic/helpers'
// import { ID, Response } from '../../../../../_metronic/helpers'

const API_URL = process.env.REACT_APP_THEME_API_URL
const CARREPORT_URL = `${API_URL}/carreport`
const GET_CARREPORTS_URL = `${API_URL}/users/query`

const getCarreports = (query: string): Promise<CarreportsQueryResponse> => {
  return axios
    .get(`${GET_CARREPORTS_URL}?${query}`)
    .then((d: AxiosResponse<CarreportsQueryResponse>) => d.data)
}

const getCarreportById = (id: ID): Promise<Carreport | undefined> => {
  return axios
    .get(`${CARREPORT_URL}/${id}`)
    .then((response: AxiosResponse<Response<Carreport>>) => response.data)
    .then((response: Response<Carreport>) => response.data)
}

const createCarreport = (carreport: Carreport): Promise<Carreport | undefined> => {
  return axios
    .put(CARREPORT_URL, carreport)
    .then((response: AxiosResponse<Response<Carreport>>) => response.data)
    .then((response: Response<Carreport>) => response.data)
}

const updateCarreport = (carreport: Carreport): Promise<Carreport | undefined> => {
  return axios
    .post(`${CARREPORT_URL}/${carreport.id}`, carreport)
    .then((response: AxiosResponse<Response<Carreport>>) => response.data)
    .then((response: Response<Carreport>) => response.data)
}

const deleteCarreport = (carreportId: ID): Promise<void> => {
  return axios.delete(`${CARREPORT_URL}/${carreportId}`).then(() => {})
}

const deleteSelectedCarreports = (carreportIds: Array<ID>): Promise<void> => {
  const requests = carreportIds.map((id) => axios.delete(`${CARREPORT_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getCarreports, deleteCarreport, deleteSelectedCarreports, getCarreportById, createCarreport, updateCarreport}
