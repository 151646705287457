// import {KTSVG} from '../../../../../../../_metronic/helpers'
import { KTSVG } from '../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {AreasListFilter} from './AreasListFilter'

const AreasListToolbar = () => {
  const {setItemIdForUpdate} = useListView()
  const openAddAreaModal = () => {
    setItemIdForUpdate(null)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-area-table-toolbar='base'>
      {/* <AreasListFilter /> */}

      {/* begin::Export */}
      {/* <button type='button' className='btn btn-light-primary me-3'>
        <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
        Export
      </button> */}
      {/* end::Export */}

      {/* begin::Add area */}
      <button type='button' className='btn btn-primary' onClick={openAddAreaModal}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        Add Area
      </button>
      {/* end::Add area */}
    </div>
  )
}

export {AreasListToolbar}
