// import {KTSVG} from '../../../../../../../_metronic/helpers'
import { KTSVG } from '../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {ExtrasListFilter} from './ExtrasListFilter'

const ExtrasListToolbar = () => {
  const {setItemIdForUpdate} = useListView()
  const openAddExtraModal = () => {
    setItemIdForUpdate(null)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-extra-table-toolbar='base'>
      {/* <ExtrasListFilter /> */}

      {/* begin::Export */}
      {/* <button type='button' className='btn btn-light-primary me-3'>
        <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
        Export
      </button> */}
      {/* end::Export */}

      {/* begin::Add extra */}
      <button type='button' className='btn btn-primary' onClick={openAddExtraModal}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        Add Extra
      </button>
      {/* end::Add extra */}
    </div>
  )
}

export {ExtrasListToolbar}
