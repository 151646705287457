import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
// import DashboardWrapper from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import CarTypeListing from '../modules/manage-car-type/CarTypes'
import AreaListing from '../modules/area-management/AreaListing'
import CarListing from '../modules/manage-cars/CarsListing'
import ManageExtra from '../modules/manage-extra/ExtraListing'
import BookingListing from '../modules/manage-booking/BookingListing'
import DriverListing from '../modules/manage-driver/DriverListing'
import UserListing from '../modules/manage-user/UserListing'
import ContactFeedbackList from '../modules/contact-feedback/ContactFeedbackListing'
// import BookingReport from '../modules/Report/BookingReport'
// import TransactionReport from '../modules/Report/TransactionReport'
// import CarInventoryReport from '../modules/Report/CarInvetoryReport'
// import AreaWiseReport from '../modules/Report/AreaWiseReport'
// import DriverAssignmentReport from '../modules/Report/DriverAssignmentReport'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import AddCar from '../modules/manage-cars/AddCar'
import EditCar from '../modules/manage-cars/EditCar'
import ViewCarDetails from '../modules/manage-cars/ViewCarDetails'
import ViewBookingDetails from '../modules/manage-booking/ViewBookingDetails'
import AddDriverDetails from '../modules/manage-driver/AddDriverDetails'
import EditDriverDetails from '../modules/manage-driver/EditDriverDetails'
import ViewDriverDetails from '../modules/manage-driver/ViewDriverDetails'
import ViewUserDetails from '../modules/manage-user/ViewUserDetails'
import AddUserDetails from '../modules/manage-user/AddUserDetails'
import EditUserDetails from '../modules/manage-user/EditUserDetails'
import BookingReport from '../modules/Report/booking-report/BookingReport'
import TransactionReport from '../modules/Report/transaction-report/TransactionReport'
import CarInventoryReport from '../modules/Report/car-inventory-report/CarInvetoryReport'
import AreaWiseReport from '../modules/Report/area-wise-report/AreaWiseReport'
import DriverAssignmentReport from '../modules/Report/driver-assignment-report/DriverAssignmentReport'
import ViewbookingreportDetails from '../modules/Report/booking-report/ViewBookingereportDetails'
import Notifications from '../modules/notifications/Notifications'
import AddBookingDetails from '../modules/manage-booking/AddBookingDetails'
import EditBookingDetails from '../modules/manage-booking/EditBookingForm'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        {/* <Route path='dashboard' element={<DashboardWrapper />} /> */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        <Route path='car-types' element={<CarTypeListing />} />
        <Route path='area' element={<AreaListing />} />
        <Route path='cars' element={<CarListing />} />
        <Route path='addcar' element={<AddCar />} />
        <Route path='viewcar' element={<ViewCarDetails />} />
        <Route path='editcar' element={<EditCar />} />
        <Route path='manage-extra' element={<ManageExtra />} />
        <Route path='booking-list' element={<BookingListing />} />
        <Route path='add-booking' element={<AddBookingDetails />} />
        <Route path='edit-booking' element={<EditBookingDetails />} />
        <Route path='view-booking-detail' element={<ViewBookingDetails />} />
        <Route path='driver-list' element={<DriverListing />} />
        <Route path='add-driver' element={<AddDriverDetails />} />
        <Route path='edit-driver' element={<EditDriverDetails />} />
        <Route path='view-driver' element={<ViewDriverDetails />} />
        <Route path='user-list' element={<UserListing />} />
        <Route path='add-User' element={<AddUserDetails />} />
        <Route path='edit-User' element={<EditUserDetails />} />
        <Route path='view-User' element={<ViewUserDetails />} />
        <Route path='contact-feedback' element={<ContactFeedbackList />} />
        <Route path='report/booking' element={<BookingReport />} />
        <Route path='report/view-bookingreport' element={<ViewbookingreportDetails />} />
        <Route path='report/transaction' element={<TransactionReport />} />
        <Route path='report/Car-Inventory' element={<CarInventoryReport />} />
        <Route path='report/location' element={<AreaWiseReport />} />
        <Route path='report/driver-assignment' element={<DriverAssignmentReport />} />
        <Route path='notifications' element={<Notifications />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
