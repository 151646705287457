import React from "react";
import { ContactsListWrapper } from "./contacts-list/ContactsList";


const ContactFeedbackList =() => {

    return(

        <>
         <h2> Contact/Feedback Message List </h2>
         <ContactsListWrapper />
        </>
    )
}

export default ContactFeedbackList