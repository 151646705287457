import React from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic/helpers";


const ViewCarDetails = () => {

    const navigate = useNavigate()
    const navigateToCarListing = () => {
        // 👇️ navigate to /contacts
        navigate('/cars')
    }

    return (

        <>

            <Row className="row mx-0">
                <Col md={8} sm={12}><h1> View Car Details </h1></Col>
                <Col md={4} sm={12} className="text-end">
                    <button
                        onClick={navigateToCarListing}
                        type='button'
                        value=''
                        className='btn btn-sm fs-5 me-5 btn btn-primary'
                    >
                        Back
                    </button>
                </Col>
            </Row>

            <div className="card p-6 mt-5">
                <Row>
                    <Col sm={12 }md={6}>
                        <h2 className="text-start py-3 text-danger"> Toyota Corolla </h2>
                    </Col>
                    <Col sm={12 }md={6}>
                        <div className="text-end py-3 text-danger"> <span className="badge badge-light-success fs-3 fw-bolder">Ready For Book </span> </div>
                    </Col>
                </Row>

                <Row className="mb-5">
                    <Col md={12}>
                        <h4 className="text-start pt-3"> Specification: </h4>
                        <p>
                            The name Corolla is part of Toyota's naming tradition of using names derived from the Toyota Crown for sedans, with "corolla" Latin for "small crown".[3] The Corolla has always been exclusive in Japan to Toyota Corolla Store locations, and manufactured in Japan with a twin, called the Toyota Sprinter until 2000.
                        </p>
                    </Col>
                </Row>
                <Row className="mb-5">
                    <Col sm={12} md={6}>
                        <Row className="d-blick-inline">
                            <Col className="col-sm-6 col-md-5 col-lg-4 py-2"><h5 className="fw-normal">Select Area:</h5> </Col>
                            <Col className="col-sm-6 col-md-7 col-lg-8 fw-bold fs-4">Maninagar</Col>
                        </Row>
                        <Row className="d-blick-inline">
                            <Col className="col-sm-6 col-md-5 col-lg-4 py-2"><h5 className="fw-normal">Car Type:</h5> </Col>
                            <Col className="col-sm-6 col-md-7 col-lg-8 fw-bold fs-4">Hatchback</Col>
                        </Row>

                        <Row className="d-blick-inline">
                            <Col className="col-sm-6 col-md-5 col-lg-4 py-2"><h5 className="fw-normal">Car Number</h5> </Col>
                            <Col className="col-sm-6 col-md-7 col-lg-8 fw-bold fs-4">GJ-1 JG0165</Col>
                        </Row>
                        <Row className="d-blick-inline">
                            <Col className="col-sm-6 col-md-5 col-lg-4 py-2"><h5 className="fw-normal">Car Capacity:</h5> </Col>
                            <Col className="col-sm-6 col-md-7 col-lg-8 fw-bold fs-4">5</Col>
                        </Row>
                        <Row className="d-blick-inline">
                            <Col className="col-sm-6 col-md-5 col-lg-4 py-2"><h5 className="fw-normal">Car Rental:</h5> </Col>
                            <Col className="col-sm-6 col-md-7 col-lg-8 fw-bold fs-4">Per Day Fee Amount</Col>
                        </Row>
                        <Row className="d-blick-inline">
                            <Col className="col-sm-6 col-md-5 col-lg-4 py-2"><h5 className="fw-normal">Car Rental:</h5> </Col>
                            <Col className="col-sm-6 col-md-7 col-lg-8 fw-bold fs-4"> QAR 5500 </Col>
                        </Row>
                    </Col>
                    <Col sm={12} md={6}>
                        <img src={toAbsoluteUrl('/media/product/toypta-car.png')} style={{maxWidth:'100%'}} />
                    </Col>
                </Row>
                <Row >
                    <Col md={12}>
                        <h4 className="text-start pt-3"> Other Details: </h4>
                        <p>
                            The name Corolla is part of Toyota's naming tradition of using names derived from the Toyota Crown for sedans, with "corolla" Latin for "small crown".[3] The Corolla has always been exclusive in Japan to Toyota Corolla Store locations, and manufactured in Japan with a twin, called the Toyota Sprinter until 2000.
                        </p>
                    </Col>
                </Row>
            </div>

           
        </>
    )
}

export default ViewCarDetails