import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import AddEditBookingForm from "./AddEditBooking";


const AddBookingDetails = () => {

    const navigate = useNavigate()
    const navigateToBookingListing = () => {
        // 👇️ navigate to /contacts
        navigate('/booking-list')
    }

    



    return (

        <>

            <Row className="row mx-0">
                <Col md={8} sm={12}><h1> Add Booking Details </h1></Col>
                <Col md={4} sm={12} className="text-end">
                    <button
                        onClick={navigateToBookingListing}
                        type='button'
                        value=''
                        className='btn btn-sm fs-5 me-5 btn btn-primary'
                    >
                        Back
                    </button>
                </Col>
            </Row>



            <div className="card p-10  mt-5">
                <AddEditBookingForm />
            </div>
        </>
    )
}

export default AddBookingDetails