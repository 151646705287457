import axios, {AxiosResponse} from 'axios'
// import {ID, Response} from '../../../../../../_metronic/helpers'
import {Driver, DriversQueryResponse} from './_models'
import { ID, Response } from '../../../../../_metronic/helpers'

const API_URL = process.env.REACT_APP_THEME_API_URL
const DRIVER_URL = `${API_URL}/driver`
const GET_DRIVERS_URL = `${API_URL}/users/query`

const getDrivers = (query: string): Promise<DriversQueryResponse> => {
  return axios
    .get(`${GET_DRIVERS_URL}?${query}`)
    .then((d: AxiosResponse<DriversQueryResponse>) => d.data)
}

const getDriverById = (id: ID): Promise<Driver | undefined> => {
  return axios
    .get(`${DRIVER_URL}/${id}`)
    .then((response: AxiosResponse<Response<Driver>>) => response.data)
    .then((response: Response<Driver>) => response.data)
}

const createDriver = (driver: Driver): Promise<Driver | undefined> => {
  return axios
    .put(DRIVER_URL, driver)
    .then((response: AxiosResponse<Response<Driver>>) => response.data)
    .then((response: Response<Driver>) => response.data)
}

const updateDriver = (driver: Driver): Promise<Driver | undefined> => {
  return axios
    .post(`${DRIVER_URL}/${driver.id}`, driver)
    .then((response: AxiosResponse<Response<Driver>>) => response.data)
    .then((response: Response<Driver>) => response.data)
}

const deleteDriver = (driverId: ID): Promise<void> => {
  return axios.delete(`${DRIVER_URL}/${driverId}`).then(() => {})
}

const deleteSelectedDrivers = (driverIds: Array<ID>): Promise<void> => {
  const requests = driverIds.map((id) => axios.delete(`${DRIVER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getDrivers, deleteDriver, deleteSelectedDrivers, getDriverById, createDriver, updateDriver}
