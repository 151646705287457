import {useQuery} from 'react-query'
 import {DriverreportEditModalForm} from './DriverreportEditModalForm'
//  import {isNotEmpty, QUERIES} from '../../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import { QUERIES, isNotEmpty } from '../../../../../../_metronic/helpers'
  import {getDriverreportById} from '../core/_requests'
// import { QUERIES, isNotEmpty } from '../../../../../_metronic/helpers'

const DriverreportEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const dataresponse = {name:"demo"}
  const {
    isLoading,
    data: driverreport,
    error,
  } = useQuery(
    `${QUERIES.BOOKINGREPORTS_LIST}-driverreport-${itemIdForUpdate}`,
    () => {
      return getDriverreportById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
       // setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return <DriverreportEditModalForm isDriverreportLoading={false} driverreport={{id: undefined}} />
  }

  if (itemIdForUpdate) {
    return <DriverreportEditModalForm isDriverreportLoading={false} driverreport={dataresponse} />
  }

  return null
}

export {DriverreportEditModalFormWrapper}
