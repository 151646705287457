import axios, {AxiosResponse} from 'axios'
// import {ID, Response} from '../../../../../../_metronic/helpers'
import {Extra, ExtrasQueryResponse} from './_models'
import { ID, Response } from '../../../../../_metronic/helpers'

const API_URL = process.env.REACT_APP_THEME_API_URL
const EXTRA_URL = `${API_URL}/extra`
const GET_EXTRAS_URL = `${API_URL}/users/query`

const getExtras = (query: string): Promise<ExtrasQueryResponse> => {
  return axios
    .get(`${GET_EXTRAS_URL}?${query}`)
    .then((d: AxiosResponse<ExtrasQueryResponse>) => d.data)
}

const getExtraById = (id: ID): Promise<Extra | undefined> => {
  return axios
    .get(`${EXTRA_URL}/${id}`)
    .then((response: AxiosResponse<Response<Extra>>) => response.data)
    .then((response: Response<Extra>) => response.data)
}

const createExtra = (extra: Extra): Promise<Extra | undefined> => {
  return axios
    .put(EXTRA_URL, extra)
    .then((response: AxiosResponse<Response<Extra>>) => response.data)
    .then((response: Response<Extra>) => response.data)
}

const updateExtra = (extra: Extra): Promise<Extra | undefined> => {
  return axios
    .post(`${EXTRA_URL}/${extra.id}`, extra)
    .then((response: AxiosResponse<Response<Extra>>) => response.data)
    .then((response: Response<Extra>) => response.data)
}

const deleteExtra = (extraId: ID): Promise<void> => {
  return axios.delete(`${EXTRA_URL}/${extraId}`).then(() => {})
}

const deleteSelectedExtras = (extraIds: Array<ID>): Promise<void> => {
  const requests = extraIds.map((id) => axios.delete(`${EXTRA_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getExtras, deleteExtra, deleteSelectedExtras, getExtraById, createExtra, updateExtra}
