import { FC, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
// import {isNotEmpty, toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import { initialBooking, Booking } from '../core/_models'
import clsx from 'clsx'
import { useListView } from '../core/ListViewProvider'
import { BookingsListLoading } from '../components/loading/BookingsListLoading'
import { createBooking, updateBooking } from '../core/_requests'
import { useQueryResponse } from '../core/QueryResponseProvider'
import { isNotEmpty, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { Col, Form, Row } from 'react-bootstrap'

import React from 'react';
import Select from 'react-select';

const booking_type = [
  { value: 'Sedan', label: 'Sedan' },
  { value: 'SUV', label: 'SUV' },
  { value: 'MUV', label: 'MUV' },
  { value: 'Hatchback', label: 'Hatchback' },
  { value: 'Luxury', label: 'Luxury' },
  { value: 'Wagon', label: 'Wagon' },
  { value: 'Jeep', label: 'Jeep' },
];

type Props = {
  isBookingLoading: boolean
  booking: Booking
}

const editBookingSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Name is required'),
})

const BookingEditModalForm: FC<Props> = ({ booking, isBookingLoading }) => {
  const { setItemIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()

  const [bookingForEdit] = useState<Booking>({
    ...booking,
    avatar: booking.avatar || initialBooking.avatar,
    role: booking.role || initialBooking.role,
    position: booking.position || initialBooking.position,
    name: booking.name || initialBooking.name,
    email: booking.email || initialBooking.email,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const blankImg = toAbsoluteUrl('/media/svg/avatars/blank.svg')
  const bookingAvatarImg = toAbsoluteUrl(`/media/${bookingForEdit.avatar}`)

  const formik = useFormik({
    initialValues: bookingForEdit,
    validationSchema: editBookingSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          await updateBooking(values)
        } else {
          await createBooking(values)
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  const [selectedBookingtype, setSelectedBookingtype] = useState(null);

  return (
    <>
      <form id='kt_modal_add_floor_form' className='form' >
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column  me-n7 pe-7 Krunal'
          id='kt_modal_add_floor_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_floor_header'
          data-kt-scroll-wrappers='#kt_modal_add_floor_scroll'
          data-kt-scroll-offset='300px'
        >


          <Row className='mb-8'>
            <Col sm={12} md={6}>
              <Form.Group className="mb-8" >
                <Form.Label> Booking Name <span className='fs-6 text-danger'> * </span></Form.Label>
                <Form.Control type="text" className='' placeholder="" required />
              </Form.Group>
            </Col>

            <Col sm={12} md={6}>
              <Form.Group className="mb-8" >
                <Form.Label> Bookinge Type <span className='fs-6 text-danger'> * </span></Form.Label>
                <Select
                  defaultValue={selectedBookingtype}
                  options={booking_type}
                  required />
              </Form.Group>
            </Col>
          </Row>

          <div className='separator mb-4'></div>
          <div className='text-end'>

            <button
              type='submit'
              className='btn btn-primary me-3'
              data-kt-users-modal-action='submit'
            >
              <span className='indicator-label'>Submit</span>
            </button>

            <button
              type='reset'
              onClick={() => cancel()}
              className='btn btn-light-danger '
              data-kt-users-modal-action='cancel'
            >
              Cancel
            </button>
          </div>

        </div>
      </form>
      {(formik.isSubmitting || isBookingLoading) && <BookingsListLoading />}
    </>
  )
}

export { BookingEditModalForm }
