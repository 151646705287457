import React from "react";
import { BookingreportListWrapper } from "./bookingreports-list/BookingreportsList";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const totalbooking = 80;
const todaybooking = 5;

const BookingReport =() => {

    return(

        <>
         <h2 className=""> Booking Report</h2>
         <div className="row justify-content-start align-items-end my-6">
                
         <div className='col-sm-6 col-md-4 col-lg-3'>
          <div className='card border available-user'>
            <div className='card_body'>
              <div className='row flex-row flex-row-reverse py-5 px-5'>
                <div className='col-8 text-start d-flex flex-column justify-content-center py-2 px-6'>
                <h2 className='text-primary text-start  fs-4x'> 80 </h2>
                  <h4 className="text-start"> Total Booking </h4>
                  {/* <div className='badge badge-light-info fs-7 py-3 px-5 rounded-pill fw-semibold mt-5'>Total 76</div> */}
                </div>
                <div className='col-4 mx-auto p-2'>
                  <CircularProgressbar
                    value={totalbooking}
                    text={`${totalbooking}%`}
                    styles={buildStyles({
                      textSize: '18px',
                      // pathTransitionDuration: 0.5,
                      pathColor: `#102A83`,
                      // textColor: '#f88',
                      trailColor: '#c9d2f0',
                      //  backgroundColor: '#E30016',
                    })}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-sm-6 col-md-4 col-lg-3'>
          <div className='card border available-user'>
            <div className='card_body'>
              <div className='row flex-row flex-row-reverse py-5 px-5'>
                <div className='col-8 text-start d-flex flex-column justify-content-center py-2 px-6'>
                <h2 className='text-dark text-start  fs-4x'> 4 </h2>
                  <h4 className="text-start"> Today's Booking </h4>
                  {/* <div className='badge badge-light-info fs-7 py-3 px-5 rounded-pill fw-semibold mt-5'>Total 4</div> */}
                </div>
                <div className='col-4 mx-auto p-2'>
                  <CircularProgressbar
                    value={todaybooking}
                    text={`${todaybooking}%`}
                    styles={buildStyles({
                      textSize: '18px',
                      // pathTransitionDuration: 0.5,
                      pathColor: `#6c757d`,
                      // textColor: '#f88',
                      trailColor: '#e3e3e3',
                      //  backgroundColor: '#E30016',
                    })}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

            </div>
         <BookingreportListWrapper />
        </>
    )
}

export default BookingReport