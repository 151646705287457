// @ts-nocheck
import {Column} from 'react-table'
import {AreaInfoCell} from './AreaInfoCell'
import {AreaLastLoginCell} from './AreaLastLoginCell'
import {AreaTwoStepsCell} from './AreaTwoStepsCell'
import {AreaActionsCell} from './AreaActionsCell'
import {AreaSelectionCell} from './AreaSelectionCell'
import {AreaCustomHeader} from './AreaCustomHeader'
import {AreaSelectionHeader} from './AreaSelectionHeader'
import {Area} from '../../core/_models'

const areasColumns: ReadonlyArray<Column<Area>> = [
  // {
  //   Header: (props) => <AreaSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <AreaSelectionCell id={props.data[props.row.index].id} />,
  // },
  {
    Header: (props) => <AreaCustomHeader tableProps={props} title='Area' className='min-w-125px text-black' />,
    accessor: 'area',
    Cell: ({...props}) => "Maninagar"
  },

  {
    Header: (props) => <AreaCustomHeader tableProps={props} title='Car Type' className='min-w-125px text-black' />,
    accessor: 'car_type',
    Cell: ({...props}) => "Hatchback"
  },

  {
    Header: (props) => (
      <AreaCustomHeader tableProps={props} title='Status' className='min-w-125px' />
    ),
    id: 'two_steps',
    Cell: ({...props}) => <AreaTwoStepsCell two_steps={props.data[props.row.index].two_steps} />,
  },
 
  {
    Header: (props) => (
      <AreaCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px text-black' />
    ),
    id: 'actions',
    Cell: ({...props}) => <AreaActionsCell id={props.data[props.row.index].id} />,
  },
]

export {areasColumns}
