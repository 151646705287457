import React from "react";
import react, { useEffect, useRef, useState } from 'react'
import { Col, Modal } from 'react-bootstrap'
import { createPortal } from 'react-dom'
import { StepperComponent } from "../../../_metronic/assets/ts/components";
import { CKEditor } from "ckeditor4-react";


// import { StepperComponent } from "../../../../_metronic/assets/ts/components";

type Props = {
  show: boolean
  handleClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const ViewContactMessage = ({ show, handleClose }: Props) => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const [isDeleteConfirm, setDeleteConfirm] = useState(false);
  const [isFormSuccess, setFormSuccess] = useState(false);

  const enableDeleteLoading = () => {
    setDeleteConfirm(true);
  };

  useEffect(() => {
    setTimeout(() => {
      handleClose();
    }, 500);
  }, []);



  const parentDivRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (parentDivRef.current) {
      parentDivRef.current.scrollTop = parentDivRef.current.scrollHeight;
    }
  }, []);


  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, []);

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-600px'
      show={show}
      onHide={handleClose}
      onEntered={loadStepper}
      backdrop={true}
    >
      <Modal.Header className="view-msg">
        <Modal.Title className="fs-2">Message Details</Modal.Title>
        <button type='button' value='' className='btn btn-sm px-6 py-3 btn btn-danger fs-4'
          onClick={handleClose}
        >
          X{' '}
        </button>
      </Modal.Header>

      <div className='modal-body  msg-dtl pt-10' ref={containerRef}>
        <div>
          <h3 className="text-black fs-5 fw-bold mb-1">Pravin Rathod</h3>
          <p className="py-3 pe-10 mb-0 fs-5">
            At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident
          </p>
          <p className="text-gray-400 text-mute text-end fs-7"> Posted On 11 Mar 2024</p>
        </div>

        <div>
          <h3 className="text-black fs-5 fw-bold mb-1">You:</h3>
          <p className="py-3 pe-10 mb-0 fs-5">
            It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
          </p>
          <p className="text-gray-400 text-mute text-end fs-7"> Replied On 12 Mar 2024</p>
        </div>
        <div >
          <h3 className="text-black fs-5 fw-bold mb-1">Pravin Rathod</h3>
          <p className="py-3 pe-10 mb-0 fs-5">
            At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident
          </p>
          <p className="text-gray-400 text-mute text-end fs-7"> Posted On 25 Mar 2024</p>
        </div>

        <div>
          <h3 className="text-black fs-5 fw-bold mb-1">You:</h3>
          <p className="py-3 pe-10 mb-0 fs-5">
            It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
          </p>
          <p className="text-gray-400 text-mute text-end fs-7"> Replied Just Now</p>
        </div>
        <div className="separator separator-dashed my-5" ></div>
        <div>
          <h3 className="text-black fs-3 fw-bold">Comment:</h3>

          <CKEditor
            initData=''
            style={{ marginBottom: '30px' }}
            config={{
              toolbar: [
                // ['Styles', 'Format', 'Font', 'FontSize'],
                ['Bold', 'Italic'],
                ['NumberedList', 'BulletedList'],
                ['Undo', 'Redo'],
              ],

            }}
          />

          <button
            type='submit'
            className='btn btn-primary me-3'
            data-kt-users-modal-action='submit'
          >
            <span className='indicator-label'>Submit</span>
          </button>


        </div>
      </div>
    </Modal>,
    modalsRoot
  )
}

export default ViewContactMessage;