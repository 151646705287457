// @ts-nocheck
import {Column} from 'react-table'
import {CarreportInfoCell} from './CarreportInfoCell'
import {CarreportLastLoginCell} from './CarreportLastLoginCell'
import {CarreportTwoStepsCell} from './CarreportTwoStepsCell'
import {CarreportActionsCell} from './CarreportActionsCell'
import {CarreportSelectionCell} from './CarreportSelectionCell'
import {CarreportCustomHeader} from './CarreportCustomHeader'
import {CarreportSelectionHeader} from './CarreportSelectionHeader'
import {Carreport} from '../../core/_models'

const carreportsColumns: ReadonlyArray<Column<Carreport>> = [
  // {
  //   Header: (props) => <CarreportSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <CarreportSelectionCell id={props.data[props.row.index].id} />,
  // },
  {
    Header: (props) => <CarreportCustomHeader tableProps={props} title='Date' className='min-w-125px text-black' />,
    accessor: 'date',
    Cell: ({...props}) => "15 Feb 2024"
  },

  {
    Header: (props) => <CarreportCustomHeader tableProps={props} title='Total Car' className='min-w-125px text-black' />,
    accessor: 'total_car',
    Cell: ({...props}) => "8"
  },
  {
    Header: (props) => <CarreportCustomHeader tableProps={props} title='Car Type' className='min-w-125px text-black' />,
    accessor: 'car_type',
    Cell: ({...props}) => "Hatchback"
  },

  {
    Header: (props) => <CarreportCustomHeader tableProps={props} title='Car Modal' className='min-w-125px text-black' />,
    accessor: 'car_modal',
    Cell: ({...props}) => "Toyota Corolla"
  },

  {
    Header: (props) => (
      <CarreportCustomHeader tableProps={props} title='Available' className='min-w-125px' />
    ),
    id: 'available_car',
    Cell: ({...props}) => "4",
  },

  {
    Header: (props) => (
      <CarreportCustomHeader tableProps={props} title='Under Maitenance' className='min-w-125px' />
    ),
    id: 'maitenance',
    Cell: ({...props}) => "3",
  },

  {
    Header: (props) => (
      <CarreportCustomHeader tableProps={props} title='Repairing' className='min-w-125px' />
    ),
    id: 'Repairing',
    Cell: ({...props}) => "1",
  },


  // {
  //   Header: (props) => (
  //     <CarreportCustomHeader tableProps={props} title='Status' className='min-w-100px' />
  //   ),
  //   id: 'two_steps',
  //   Cell: ({...props}) => <CarreportTwoStepsCell two_steps={props.data[props.row.index].two_steps} />,
  // },
 
  // {
  //   Header: (props) => (
  //     <CarreportCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px text-black' />
  //   ),
  //   id: 'actions',
  //   Cell: ({...props}) => <CarreportActionsCell id={props.data[props.row.index].id} />,
  // },
]

export {carreportsColumns}
