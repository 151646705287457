// import {KTSVG} from '../../../../../../../_metronic/helpers'
import { useNavigate } from 'react-router-dom'
import { KTSVG } from '../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {DriversListFilter} from './DriversListFilter'

const DriversListToolbar = () => {
  const {setItemIdForUpdate} = useListView()
  const openAddDriverModal = () => {
    setItemIdForUpdate(null)
  }

  const navigate = useNavigate()
  const navigateAddDriver = () => {
    // 👇️ navigate to /
    navigate(`/add-driver`)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-driver-table-toolbar='base'>
      <DriversListFilter />

      {/* begin::Export */}
      {/* <button type='button' className='btn btn-light-primary me-3'>
        <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
        Export
      </button> */}
      {/* end::Export */}

      {/* begin::Add driver */}
      <button type='button' className='btn btn-primary' onClick={navigateAddDriver}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        Add Driver
      </button>
      {/* end::Add driver */}
    </div>
  )
}

export {DriversListToolbar}
