// @ts-nocheck
import {Column} from 'react-table'
import {DriverInfoCell} from './DriverInfoCell'
import {DriverLastLoginCell} from './DriverLastLoginCell'
import {DriverTwoStepsCell} from './DriverTwoStepsCell'
import {DriverActionsCell} from './DriverActionsCell'
import {DriverSelectionCell} from './DriverSelectionCell'
import {DriverCustomHeader} from './DriverCustomHeader'
import {DriverSelectionHeader} from './DriverSelectionHeader'
import {Driver} from '../../core/_models'

const driversColumns: ReadonlyArray<Column<Driver>> = [
  // {
  //   Header: (props) => <DriverSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <DriverSelectionCell id={props.data[props.row.index].id} />,
  // },
  {
    Header: (props) => <DriverCustomHeader tableProps={props} title='Name' className='min-w-125px text-black' />,
    accessor: 'driver_name',
    Cell: ({...props}) => "Satpal Seth"
  },


  {
    Header: (props) => <DriverCustomHeader tableProps={props} title='Email Id' className='min-w-125px text-black' />,
    accessor: 'email',
    Cell: ({...props}) => "satpal@rentify.com"
  },

  {
    Header: (props) => <DriverCustomHeader tableProps={props} title='Contact No' className='min-w-125px text-black' />,
    accessor: 'contact_no',
    Cell: ({...props}) => "98254 36589"
  },

  {
    Header: (props) => <DriverCustomHeader tableProps={props} title='State' className='min-w-125px text-black' />,
    accessor: 'state',
    Cell: ({...props}) => "Maharashtra"
  },

  {
    Header: (props) => <DriverCustomHeader tableProps={props} title='City' className='min-w-125px text-black' />,
    accessor: 'city',
    Cell: ({...props}) => "Bombay"
  },

  {
    Header: (props) => <DriverCustomHeader tableProps={props} title='ZipCode' className='min-w-125px text-black' />,
    accessor: 'zipcode',
    Cell: ({...props}) => "485125"
  },

  {
    Header: (props) => <DriverCustomHeader tableProps={props} title='Licence No' className='min-w-125px text-black' />,
    accessor: 'licence_no',
    Cell: ({...props}) => "DL-38065405"
  },

  
  {
    Header: (props) => (
      <DriverCustomHeader tableProps={props} title='Status' className='min-w-100px' />
    ),
    id: 'two_steps',
    Cell: ({...props}) => <DriverTwoStepsCell two_steps={props.data[props.row.index].two_steps} />,
  },
 
  {
    Header: (props) => (
      <DriverCustomHeader tableProps={props} title='Actions' className='text-end min-w-125px text-black' />
    ),
    id: 'actions',
    Cell: ({...props}) => <DriverActionsCell id={props.data[props.row.index].id} />,
  },
]

export {driversColumns}
