// @ts-nocheck
import {Column} from 'react-table'
import {AreawiseInfoCell} from './AreawiseInfoCell'
import {AreawiseLastLoginCell} from './AreawiseLastLoginCell'
import {AreawiseTwoStepsCell} from './AreawiseTwoStepsCell'
import {AreawiseActionsCell} from './AreawiseActionsCell'
import {AreawiseSelectionCell} from './AreawiseSelectionCell'
import {AreawiseCustomHeader} from './AreawiseCustomHeader'
import {AreawiseSelectionHeader} from './AreawiseSelectionHeader'
import {Areawise} from '../../core/_models'

const areawisesColumns: ReadonlyArray<Column<Areawise>> = [
  // {
  //   Header: (props) => <AreawiseSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <AreawiseSelectionCell id={props.data[props.row.index].id} />,
  // },

  {
    Header: (props) => <AreawiseCustomHeader tableProps={props} title='Date' className='min-w-125px text-black' />,
    accessor: 'date',
    Cell: ({...props}) => "15 Feb 2024"
  },

  {
    Header: (props) => <AreawiseCustomHeader tableProps={props} title='Area' className='min-w-125px text-black' />,
    accessor: 'area',
    Cell: ({...props}) => "Maninagar"
  },

  {
    Header: (props) => <AreawiseCustomHeader tableProps={props} title='Car Type' className='min-w-125px text-black' />,
    accessor: 'car_type',
    Cell: ({...props}) => "Hatchback"
  },

  {
    Header: (props) => <AreawiseCustomHeader tableProps={props} title='Car Modal' className='min-w-125px text-black' />,
    accessor: 'car_modal',
    Cell: ({...props}) => "Toyata Corolla"
  },

  {
    Header: (props) => (
      <AreawiseCustomHeader tableProps={props} title='Status' className='min-w-125px' />
    ),
    id: 'two_steps',
    Cell: ({...props}) => <AreawiseTwoStepsCell two_steps={props.data[props.row.index].two_steps} />,
  },
 
  // {
  //   Header: (props) => (
  //     <AreawiseCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px text-black' />
  //   ),
  //   id: 'actions',
  //   Cell: ({...props}) => <AreawiseActionsCell id={props.data[props.row.index].id} />,
  // },
]

export {areawisesColumns}
