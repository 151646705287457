import React from "react";
import { AreasListWrapper } from "./areas-list/AreasList";


const AreaListing =() => {

    return(

        <>
        
        <h2> Area List </h2>

<div className="card  mt-5">
    <AreasListWrapper />
</div>
        
        </>
    )
}

export default AreaListing