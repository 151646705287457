import React from "react";
import { UsersListWrapper } from "./users-list/UsersList";


const UserListing =() => {

    return(

        <>
         <h2> User List </h2>
         <UsersListWrapper />
        </>
    )
}

export default UserListing