import React from "react";
import { TransactionListWrapper } from "./transactions-list/TransactionsList";


const TransactionReport =() => {

    return(

        <>
         <h2> Transaction Report</h2>
         <TransactionListWrapper />
        
        </>
    )
}

export default TransactionReport