import axios, {AxiosResponse} from 'axios'
// import {ID, Response} from '../../../../../../_metronic/helpers'
import {Driverreport, DriverreportsQueryResponse} from './_models'
import { ID, Response } from '../../../../../../_metronic/helpers'
// import { ID, Response } from '../../../../../_metronic/helpers'

const API_URL = process.env.REACT_APP_THEME_API_URL
const DRIVERREPORT_URL = `${API_URL}/driverreport`
const GET_DRIVERREPORTS_URL = `${API_URL}/users/query`

const getDriverreports = (query: string): Promise<DriverreportsQueryResponse> => {
  return axios
    .get(`${GET_DRIVERREPORTS_URL}?${query}`)
    .then((d: AxiosResponse<DriverreportsQueryResponse>) => d.data)
}

const getDriverreportById = (id: ID): Promise<Driverreport | undefined> => {
  return axios
    .get(`${DRIVERREPORT_URL}/${id}`)
    .then((response: AxiosResponse<Response<Driverreport>>) => response.data)
    .then((response: Response<Driverreport>) => response.data)
}

const createDriverreport = (driverreport: Driverreport): Promise<Driverreport | undefined> => {
  return axios
    .put(DRIVERREPORT_URL, driverreport)
    .then((response: AxiosResponse<Response<Driverreport>>) => response.data)
    .then((response: Response<Driverreport>) => response.data)
}

const updateDriverreport = (driverreport: Driverreport): Promise<Driverreport | undefined> => {
  return axios
    .post(`${DRIVERREPORT_URL}/${driverreport.id}`, driverreport)
    .then((response: AxiosResponse<Response<Driverreport>>) => response.data)
    .then((response: Response<Driverreport>) => response.data)
}

const deleteDriverreport = (driverreportId: ID): Promise<void> => {
  return axios.delete(`${DRIVERREPORT_URL}/${driverreportId}`).then(() => {})
}

const deleteSelectedDriverreports = (driverreportIds: Array<ID>): Promise<void> => {
  const requests = driverreportIds.map((id) => axios.delete(`${DRIVERREPORT_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getDriverreports, deleteDriverreport, deleteSelectedDriverreports, getDriverreportById, createDriverreport, updateDriverreport}
