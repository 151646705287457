import {FC} from 'react'

type Props = {
  two_steps?: boolean
}

const CarTwoStepsCell: FC<Props> = ({two_steps}) => (
  <> {two_steps && <div className='badge badge-success fs-4 fw-bolder'>Ready To Book</div>}</>
)

export {CarTwoStepsCell}
