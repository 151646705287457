// @ts-nocheck
import {Column} from 'react-table'
import {ExtraInfoCell} from './ExtraInfoCell'
import {ExtraLastLoginCell} from './ExtraLastLoginCell'
import {ExtraTwoStepsCell} from './ExtraTwoStepsCell'
import {ExtraActionsCell} from './ExtraActionsCell'
import {ExtraSelectionCell} from './ExtraSelectionCell'
import {ExtraCustomHeader} from './ExtraCustomHeader'
import {ExtraSelectionHeader} from './ExtraSelectionHeader'
import {Extra} from '../../core/_models'

const extrasColumns: ReadonlyArray<Column<Extra>> = [
  // {
  //   Header: (props) => <ExtraSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <ExtraSelectionCell id={props.data[props.row.index].id} />,
  // },

  // {
  //   Header: (props) => <ExtraCustomHeader tableProps={props} title='Car Type' className='min-w-125px text-black' />,
  //   accessor: 'car_type',
  //   Cell: ({...props}) => "Hatchback"
  // },

  // {
  //   Header: (props) => <ExtraCustomHeader tableProps={props} title='Car Modal' className='min-w-125px text-black' />,
  //   accessor: 'car_modal',
  //   Cell: ({...props}) => "Toyota Corolla"
  // },


  {
    Header: (props) => <ExtraCustomHeader tableProps={props} title='Extra' className='min-w-125px text-black' />,
    accessor: 'extra',
    Cell: ({...props}) => "GPS"
  },

  {
    Header: (props) => <ExtraCustomHeader tableProps={props} title='Price (Rs)' className='min-w-125px text-black' />,
    accessor: 'Price',
    Cell: ({...props}) => "800"
  },

  {
    Header: (props) => (
      <ExtraCustomHeader tableProps={props} title='Status' className='min-w-125px' />
    ),
    id: 'two_steps',
    Cell: ({...props}) => <ExtraTwoStepsCell two_steps={props.data[props.row.index].two_steps} />,
  },
 
  {
    Header: (props) => (
      <ExtraCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px text-black' />
    ),
    id: 'actions',
    Cell: ({...props}) => <ExtraActionsCell id={props.data[props.row.index].id} />,
  },
]

export {extrasColumns}
