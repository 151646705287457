// import {KTSVG} from '../../../../../../../_metronic/helpers'
import { useNavigate } from 'react-router-dom'
import { KTSVG } from '../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {ContactsListFilter} from './ContactsListFilter'

const ContactsListToolbar = () => {
  const {setItemIdForUpdate} = useListView()
  const openAddContactModal = () => {
    setItemIdForUpdate(null)
  }

  const navigate = useNavigate()
  const navigateAddContact = () => {
    // 👇️ navigate to /
    navigate(`/addcontact`)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-contact-table-toolbar='base'>
      {/* <ContactsListFilter /> */}

      {/* begin::Export */}
      {/* <button type='button' className='btn btn-light-primary me-3'>
        <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
        Export
      </button> */}
      {/* end::Export */}

      {/* begin::Add contact */}
      {/* <button type='button' className='btn btn-primary' onClick={navigateAddContact}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        Add Contact
      </button> */}
      {/* end::Add contact */}
    </div>
  )
}

export {ContactsListToolbar}
