import React from "react";
import { BookingsListWrapper } from "./bookings-list/BookingsList";


const BookingListing = () => {

    return (

        <>
            <h2> Booking List </h2>

            <div className="bookingd mt-5">
                
                <BookingsListWrapper />
            </div>
        </>
    )
}

export default BookingListing