import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
// import {DriverreportListHeader} from './components/header/DriverreportListHeader'
// import {DriverreportTable} from './table/DriverreportTable'
// import { KTCard } from '../../../../_metronic/helpers'
// import { DriverreportEditModal } from './driverreportreportreportreport-edit-modal/DriverreportEditModal'
import { KTCard } from '../../../../../_metronic/helpers'
import { DriverreportsListHeader } from './components/header/DriverreportsListHeader'
import { DriverreportsTable } from './table/DriverreportsTable'
import { DriverreportEditModal } from './driverreport-edit-modal/DriverreportEditModal'
//  import {DriverreportEditModal} from './car-edit-modal/DriverreportEditModal'
// import {KTDriverreportd} from '../../../../../_metronic/helpers'

const DriverreportList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <DriverreportsListHeader />
        <DriverreportsTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <DriverreportEditModal />}
    </>
  )
}

const DriverreportListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <DriverreportList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {DriverreportListWrapper}
