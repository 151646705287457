import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import FormCheckLabel from "react-bootstrap/esm/FormCheckLabel";
import ImageUploading from 'react-images-uploading';
import { useNavigate } from "react-router-dom";
import Select from 'react-select';
import AddEditCarDetails from "./AddEditCarDetails";



const EditCar = () => {

    const navigate = useNavigate()
    const navigateToCarListing = () => {
      // 👇️ navigate to /contacts
      navigate('/cars')
    }

    return (
        <>

<Row className="row mx-0">
        <Col md={8} sm={12}><h1> Edit Car Details </h1></Col>
        <Col md={4} sm={12} className="text-end">
          <button
            onClick={navigateToCarListing}
            type='button'
            value=''
            className='btn btn-sm fs-5 me-5 btn btn-primary'
          >
            Back
          </button>
        </Col>
      </Row>

      <div className="card p-6  mt-5">
            <AddEditCarDetails />
            </div>

        </>
    )
}

export default EditCar