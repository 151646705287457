import {useListView} from '../../core/ListViewProvider'
import {BookingreportsListToolbar} from './BookingreportListToolbar'
import {BookingreportsListGrouping} from './BookingreportsListGrouping'
import { BookingsListSearchComponent } from './BookingreportsListSearchComponent'
// import {BookingreportsListSearchComponent} from './BookingreportsListSearchComponent'

const BookingreportsListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='bookingreportd-header d-flex justify-content-between border-0 pt-6'>
      <BookingsListSearchComponent />
      {/* begin::Bookingreportd toolbar */}
      <div className='bookingreportd-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <BookingreportsListGrouping /> : <BookingreportsListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Bookingreportd toolbar */}
    </div>
  )
}

export {BookingreportsListHeader}
