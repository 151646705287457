// import {KTSVG} from '../../../../../../../_metronic/helpers'
import { useNavigate } from 'react-router-dom'
// import { KTSVG } from '../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {DriverreportsListFilter} from './DriverreportsListFilter'
import { KTSVG } from '../../../../../../../_metronic/helpers'

const DriverreportsListToolbar = () => {
  const {setItemIdForUpdate} = useListView()
  const openAddDriverreportModal = () => {
    setItemIdForUpdate(null)
  }

  const navigate = useNavigate()
  const navigateAddDriverreport = () => {
    // 👇️ navigate to /
    navigate(`/adddriverreport`)
  }

  return (
<>
    

    <div className='row d-flex justify-content-end' data-kt-driverreport-table-toolbar='base'>
      <DriverreportsListFilter />
     
      {/* begin::Export */}
      {/* <button type='button' className='btn btn-light-primary me-3'>
        <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
        Export
      </button> */}
      {/* end::Export */}

      {/* begin::Add driverreport */}
      {/* <button type='button' className='btn btn-primary' onClick={navigateAddDriverreport}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        Add Driverreport
      </button> */}
      {/* end::Add driverreport */}
    </div>
    </>
  )
}

export {DriverreportsListToolbar}
