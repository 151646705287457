import React from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
// import { toAbsoluteUrl } from "../../../_metronic/helpers";



const ViewBookingreportDetails = () => {

    const navigate = useNavigate()
    const navigateToBookingreportListing = () => {
        // 👇️ navigate to /contacts
        navigate('/report/booking')
    }


    const handlePrint = () => {
        window.print()
    }

        
  //the window.print() command is what is telling the browser to print the page
  const onButtonClick = () => {
    const pdfUrl = "/media/sample_pdf.pdf";
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "document.pdf"; // specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

    return (

        <>
            <Row className="row mx-0">
                <Col md={6} sm={12}><h1> View booking Report Details </h1></Col>
                <Col md={6} sm={12} className="text-end">

                    <button
                        onClick={onButtonClick}
                        value=''
                        className='btn btn-sm fs-5 me-5 btn btn-info'
                    >
                        Download
                    </button>
                    <button
                        onClick={navigateToBookingreportListing}
                        type='button'
                        value=''
                        className='btn btn-sm fs-5 me-5 btn btn-primary'
                    >
                        Back
                    </button>
                </Col>

            </Row>



            <div className="card p-10 mt-8" >
                <Row>
                    <Col sm={12} md={6}>
                        <Row className="mb-5">
                            <h2 className="text-start  text-danger"> Toyota Prius <span className="badge badge-light-success fs-3 fw-bolder ms-20">Booked </span></h2>
                            <div className="separator my-8"></div>
                        </Row>

                        <Row className="d-blick-inline">
                            <Col className="col-sm-6 col-md-5 col-lg-4 py-2"><h5 className="fw-normal">Pickup Location:</h5> </Col>
                            <Col className="col-sm-6 col-md-7 col-lg-8 fw-bold fs-4">Maninagar</Col>
                        </Row>
                        <Row className="d-blick-inline">
                            <Col className="col-sm-6 col-md-5 col-lg-4 py-2"><h5 className="fw-normal">Destination:</h5> </Col>
                            <Col className="col-sm-6 col-md-7 col-lg-8 fw-bold fs-4">Udaipur</Col>
                        </Row>
                        <Row className="d-blick-inline">
                            <Col className="col-sm-6 col-md-5 col-lg-4 py-2"><h5 className="fw-normal">Pickup Location:</h5> </Col>
                            <Col className="col-sm-6 col-md-7 col-lg-8 fw-bold fs-4">Maninagar</Col>
                        </Row>
                        <Row className="d-blick-inline">
                            <Col className="col-sm-6 col-md-5 col-lg-4 py-2"><h5 className="fw-normal">Pickup Date:</h5> </Col>
                            <Col className="col-sm-6 col-md-7 col-lg-8 fw-bold fs-4">24 Mar 2024</Col>
                        </Row>
                        <Row className="d-blick-inline">
                            <Col className="col-sm-6 col-md-5 col-lg-4 py-2"><h5 className="fw-normal">Pickup Time:</h5> </Col>
                            <Col className="col-sm-6 col-md-7 col-lg-8 fw-bold fs-4">6:00am</Col>
                        </Row>
                        <Row className="d-blick-inline">
                            <Col className="col-sm-6 col-md-5 col-lg-4 py-2"><h5 className="fw-normal">Round Trip Date:</h5> </Col>
                            <Col className="col-sm-6 col-md-7 col-lg-8 fw-bold fs-4">27 Mar 2024</Col>
                        </Row>
                        <Row className="d-blick-inline">
                            <Col className="col-sm-6 col-md-5 col-lg-4 py-2"><h5 className="fw-normal">Round Trip Date:</h5> </Col>
                            <Col className="col-sm-6 col-md-7 col-lg-8 fw-bold fs-4">11:30pm</Col>
                        </Row>
                        <Row className="d-blick-inline">
                            <Col className="col-sm-6 col-md-5 col-lg-4 py-2"><h5 className="fw-normal">Car Number:</h5> </Col>
                            <Col className="col-sm-6 col-md-7 col-lg-8 fw-bold fs-4">GJ-1 JG0165</Col>
                        </Row>
                        <Row className="d-blick-inline">
                            <Col className="col-sm-6 col-md-5 col-lg-4 py-2"><h5 className="fw-normal">Rate:</h5> </Col>
                            <Col className="col-sm-6 col-md-7 col-lg-8 fw-bold fs-4">QAR 5500</Col>
                        </Row>
                    </Col>

                    <Col sm={12} md={6} className="text-center col-md-6 d-flex flex-column justify-content-center col-sm-12">
                        <img src={toAbsoluteUrl('/media/product/toyota_prius.png')} style={{ maxWidth: '100%' }} />
                    </Col>
                </Row>


            </div>

            <div className="row align-items-stretch mt-8" >
                <div className="col-sm-12 col-md-6 ">
                    <div className="card  p-10" >
                        <h3 className="mb-5 text-black"> User Information</h3>
                        <div className="separator my-5"></div>
                        <Row className="d-blick-inline">
                            <Col className="col-sm-6 col-md-5 col-lg-4 py-2"><h5 className="fw-normal">Email id:</h5> </Col>
                            <Col className="col-sm-6 col-md-7 col-lg-8 fw-bold fs-4">thomsonvellay@rentify.com</Col>
                        </Row>
                        <div className="separator my-3"></div>
                        <Row className="d-blick-inline">
                            <Col className="col-sm-6 col-md-5 col-lg-4 py-2"><h5 className="fw-normal">Contact No:</h5> </Col>
                            <Col className="col-sm-6 col-md-7 col-lg-8 fw-bold fs-4">+91 98254 36589</Col>
                        </Row>
                        <div className="separator my-3"></div>
                        <Row className="d-blick-inline">
                            <Col className="col-sm-6 col-md-5 col-lg-4 py-2"><h5 className="fw-normal">Message:</h5> </Col>
                            <Col className="col-sm-6 col-md-7 col-lg-8 fw-bold fs-4"> No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally </Col>
                        </Row>
                    </div>
                </div>

                <div className="col-sm-12 col-md-6 ">
                    <div className="card p-10" style={{height:'100%'}} >
                        <h3 className="mb-5 text-black"> Driver Information</h3>
                        <div className="separator my-5"></div>
                        <Row className="d-blick-inline">
                            <Col className="col-sm-6 col-md-5 col-lg-4 py-2"><h5 className="fw-normal">Name:</h5> </Col>
                            <Col className="col-sm-6 col-md-7 col-lg-8 fw-bold fs-4">Max Smith</Col>
                        </Row>
                        <div className="separator my-3"></div>
                        <Row className="d-blick-inline">
                            <Col className="col-sm-6 col-md-5 col-lg-4 py-2"><h5 className="fw-normal">Licence No:</h5> </Col>
                            <Col className="col-sm-6 col-md-7 col-lg-8 fw-bold fs-4">DL-547123654870</Col>
                        </Row>
                     
                    </div>
                </div>
            </div>

        </>
    )
}

export default ViewBookingreportDetails