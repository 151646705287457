/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useMutation, useQueryClient} from 'react-query'
// import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
// import {ID, KTSVG, QUERIES} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {deleteArea} from '../../core/_requests'
import { ID, KTSVG, QUERIES } from '../../../../../../_metronic/helpers'
import { MenuComponent } from '../../../../../../_metronic/assets/ts/components'
import { OverlayTrigger } from 'react-bootstrap'
import Tooltip from 'react-bootstrap/Tooltip';
import DeleteAreasDetails from '../../../DeleteArea'
// import DeleteAreasDetails from '../../../DeleteCarType'

type Props = {
  id: ID
}

const AreaActionsCell: FC<Props> = ({id}) => {
  const {setItemIdForUpdate} = useListView()
  const {query} = useQueryResponse()
  const queryClient = useQueryClient()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setItemIdForUpdate(id)
  }

const [ShowDeleteAreaDetails, setShowDeleteAreaDetails] = useState<boolean>(false)

  // const deleteItem = useMutation(() => deleteArea(id), {
  //   // 💡 response of the mutation is passed to onSuccess
  //   onSuccess: () => {
  //     // ✅ update detail view directly
  //     queryClient.invalidateQueries([`${QUERIES.CARTYPES_LIST}-${query}`])
  //   },
  // })

  return (
    <>
      {/* <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        Actions
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
      </a> */}
      {/* begin::Menu */}
      {/* <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        </div> */}

<div className='d-flex flex-wrap flex-end'>
<div className='menu-item px-3'>
          <a className='menu-link p-1' 
          onClick={openEditModal}
          >
          <OverlayTrigger
              placement='top'
              overlay={<Tooltip className='py-2 px-2 fs-5 '>Edit</Tooltip>}
            >
          <i className="fa-regular fa-pen-to-square fs-4 text-warning"></i>
          </OverlayTrigger>
          </a>
        </div>

        <div className='menu-item px-3'>
          <a className='menu-link p-1'  onClick={() => setShowDeleteAreaDetails(true)} >
          <OverlayTrigger
              placement='top'
              overlay={<Tooltip className='py-2 px-2 fs-5 '>Delete</Tooltip>}
            >
          <i className="fa-solid fa-trash-can fs-4 text-danger"></i>
          </OverlayTrigger>
          </a>

           <DeleteAreasDetails
           show={ShowDeleteAreaDetails} handleClose={() => setShowDeleteAreaDetails(false)}
          />  
        </div>

</div>




        {/* begin::Menu item */}
        {/* <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={openEditModal}>
            Edit
          </a>
        </div> */}
        {/* end::Menu item */}

        {/* begin::Menu item */}
        {/* <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            data-kt-cartypes-table-filter='delete_row'
            onClick={async () => await deleteItem.mutateAsync()}
          >
            Delete
          </a>
        </div> */}
        {/* end::Menu item */}
      
      {/* end::Menu */}
    </>
  )
}

export {AreaActionsCell}
