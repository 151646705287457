// @ts-nocheck
import {Column} from 'react-table'
import {CarInfoCell} from './CarInfoCell'
import {CarLastLoginCell} from './CarLastLoginCell'
import {CarTwoStepsCell} from './CarTwoStepsCell'
import {CarActionsCell} from './CarActionsCell'
import {CarSelectionCell} from './CarSelectionCell'
import {CarCustomHeader} from './CarCustomHeader'
import {CarSelectionHeader} from './CarSelectionHeader'
import {Car} from '../../core/_models'

const carsColumns: ReadonlyArray<Column<Car>> = [
  // {
  //   Header: (props) => <CarSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <CarSelectionCell id={props.data[props.row.index].id} />,
  // },
  {
    Header: (props) => <CarCustomHeader tableProps={props} title='Car Number' className='min-w-125px text-black' />,
    accessor: 'car_number',
    Cell: ({...props}) => "GJ-1 JG 0165"
  },
  {
    Header: (props) => <CarCustomHeader tableProps={props} title='Car Modal' className='min-w-125px text-black' />,
    accessor: 'car_modal',
    Cell: ({...props}) => "Toyota Corolla"
  },
 
  {
    Header: (props) => <CarCustomHeader tableProps={props} title='Car Type' className='min-w-125px text-black' />,
    accessor: 'car_type',
    Cell: ({...props}) => "Hatchback"
  },
  {
    Header: (props) => <CarCustomHeader tableProps={props} title='Area' className='min-w-125px text-black' />,
    accessor: 'area',
    Cell: ({...props}) => "Maninagar"
  },
  {
    Header: (props) => <CarCustomHeader tableProps={props} title='Capacity' className='min-w-125px text-black' />,
    accessor: 'capacity',
    Cell: ({...props}) => "5"
  },

  {
    Header: (props) => <CarCustomHeader tableProps={props} title='Rental' className='min-w-125px text-black' />,
    accessor: 'rental',
    Cell: ({...props}) => "Per Day fees amount"
  },

  {
    Header: (props) => (
      <CarCustomHeader tableProps={props} title='Status' className='min-w-100px' />
    ),
    id: 'two_steps',
    Cell: ({...props}) => <CarTwoStepsCell two_steps={props.data[props.row.index].two_steps} />,
  },
 
  {
    Header: (props) => (
      <CarCustomHeader tableProps={props} title='Actions' className='text-end min-w-125px text-black' />
    ),
    id: 'actions',
    Cell: ({...props}) => <CarActionsCell id={props.data[props.row.index].id} />,
  },
]

export {carsColumns}
