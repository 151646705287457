import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {ExtrasListHeader} from './components/header/ExtrasListHeader'
import {ExtrasTable} from './table/ExtrasTable'
import { KTCard } from '../../../../_metronic/helpers'
 import {ExtraEditModal} from './extra-edit-modal/ExtraEditModal'
// import {KTCard} from '../../../../../_metronic/helpers'

const ExtrasList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <ExtrasListHeader />
        <ExtrasTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <ExtraEditModal />}
    </>
  )
}

const ExtrasListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <ExtrasList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {ExtrasListWrapper}
