import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {CarsListHeader} from './components/header/CarsListHeader'
import {CarsTable} from './table/CarsTable'
import { KTCard } from '../../../../_metronic/helpers'
 import {CarEditModal} from './car-edit-modal/CarEditModal'
// import {KTCard} from '../../../../../_metronic/helpers'

const CarsList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <CarsListHeader />
        <CarsTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <CarEditModal />}
    </>
  )
}

const CarsListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <CarsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {CarsListWrapper}
