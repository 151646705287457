import {useQuery} from 'react-query'
 import {DriverEditModalForm} from './DriverEditModalForm'
//  import {isNotEmpty, QUERIES} from '../../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
 import {getDriverById} from '../core/_requests'
import { QUERIES, isNotEmpty } from '../../../../../_metronic/helpers'

const DriverEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const dataresponse = {name:"demo"}
  const {
    isLoading,
    data: driver,
    error,
  } = useQuery(
    `${QUERIES.DRIVERS_LIST}-driver-${itemIdForUpdate}`,
    () => {
      return getDriverById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
       // setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return <DriverEditModalForm isDriverLoading={false} driver={{id: undefined}} />
  }

  if (itemIdForUpdate) {
    return <DriverEditModalForm isDriverLoading={false} driver={dataresponse} />
  }

  return null
}

export {DriverEditModalFormWrapper}
