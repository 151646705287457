import { FC, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
// import {isNotEmpty, toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import { initialCarreport, Carreport } from '../core/_models'
import clsx from 'clsx'
import { useListView } from '../core/ListViewProvider'
import { CarreportsListLoading } from '../components/loading/CarreportsListLoading'
import { createCarreport, updateCarreport } from '../core/_requests'
import { useQueryResponse } from '../core/QueryResponseProvider'
// import { isNotEmpty, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { Col, Form, Row } from 'react-bootstrap'

import React from 'react';
import Select from 'react-select';
import { isNotEmpty, toAbsoluteUrl } from '../../../../../../_metronic/helpers'

const car_type = [
  { value: 'Sedan', label: 'Sedan' },
  { value: 'SUV', label: 'SUV' },
  { value: 'MUV', label: 'MUV' },
  { value: 'Hatchback', label: 'Hatchback' },
  { value: 'Luxury', label: 'Luxury' },
  { value: 'Wagon', label: 'Wagon' },
  { value: 'Jeep', label: 'Jeep' },
];

type Props = {
  isCarreportLoading: boolean
  carreport: Carreport
}

const editCarreportSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Name is required'),
})

const CarreportEditModalForm: FC<Props> = ({ carreport, isCarreportLoading }) => {
  const { setItemIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()

  const [carreportForEdit] = useState<Carreport>({
    ...carreport,
    avatar: carreport.avatar || initialCarreport.avatar,
    role: carreport.role || initialCarreport.role,
    position: carreport.position || initialCarreport.position,
    name: carreport.name || initialCarreport.name,
    email: carreport.email || initialCarreport.email,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const blankImg = toAbsoluteUrl('/media/svg/avatars/blank.svg')
  const carreportAvatarImg = toAbsoluteUrl(`/media/${carreportForEdit.avatar}`)

  const formik = useFormik({
    initialValues: carreportForEdit,
    validationSchema: editCarreportSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          await updateCarreport(values)
        } else {
          await createCarreport(values)
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  const [selectedCartype, setSelectedCartype] = useState(null);

  return (
    <>
      <form id='kt_modal_add_floor_form' className='form' >
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column  me-n7 pe-7 Krunal'
          id='kt_modal_add_floor_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_floor_header'
          data-kt-scroll-wrappers='#kt_modal_add_floor_scroll'
          data-kt-scroll-offset='300px'
        >


          <Row className='mb-8'>
            <Col sm={12} md={6}>
              <Form.Group className="mb-8" >
                <Form.Label> Carreport Name <span className='fs-6 text-danger'> * </span></Form.Label>
                <Form.Control type="text" className='' placeholder="" required />
              </Form.Group>
            </Col>

            <Col sm={12} md={6}>
              <Form.Group className="mb-8" >
                <Form.Label> Care Type <span className='fs-6 text-danger'> * </span></Form.Label>
                <Select
                  defaultValue={selectedCartype}
                  options={car_type}
                  required />
              </Form.Group>
            </Col>
          </Row>

          <div className='separator mb-4'></div>
          <div className='text-end'>

            <button
              type='submit'
              className='btn btn-primary me-3'
              data-kt-users-modal-action='submit'
            >
              <span className='indicator-label'>Submit</span>
            </button>

            <button
              type='reset'
              onClick={() => cancel()}
              className='btn btn-light-danger '
              data-kt-users-modal-action='cancel'
            >
              Cancel
            </button>
          </div>

        </div>
      </form>
      {(formik.isSubmitting || isCarreportLoading) && <CarreportsListLoading />}
    </>
  )
}

export { CarreportEditModalForm }
