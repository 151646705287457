import React from "react";
import react, { useEffect, useRef, useState } from 'react'
import { Col, Modal } from 'react-bootstrap'
import { createPortal } from 'react-dom'
import { StepperComponent } from "../../../_metronic/assets/ts/components";
// import { StepperComponent } from "../../../../_metronic/assets/ts/components";

type Props = {
  show: boolean
  handleClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const DeleteAreasDetails = ({ show, handleClose }: Props) => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const [isDeleteConfirm, setDeleteConfirm] = useState(false);
  const [isFormSuccess, setFormSuccess] = useState(false);

  const enableDeleteLoading = () => {
    setDeleteConfirm(true);
  };

  useEffect(() => {
    setTimeout(() => {
      handleClose();
    }, 500);
  }, []);
  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-450px'
      show={show}
      onHide={handleClose}
      onEntered={loadStepper}
      backdrop={true}
    >
      <div className='modal-body pt-10'>
        {!isDeleteConfirm && (
          <>

            <div className="dlt-mdl-icon text-center mb-10">
              <i className='fa-solid fa-trash-can text-danger'></i>
            </div>
            <h3 className='text-danger text-center'>Delete Area Details ?</h3>
            <p className='text-center mb-5 fs-4'> Are you Sure, you wan't Delete Area Details ? </p>
            <Col className='me-5  mt-10 mb-10 text-center '>
              <button
                type='button'
                value=''
                className='btn btn-sm px-12 py-3  me-5 btn btn-danger fs-4'
                onClick={() => {
                  setFormSuccess(!isFormSuccess);
                  enableDeleteLoading();
                  setTimeout(() => {
                    handleClose();
                  }, 2000);
                }}
              >
                {' '}
                Yes, Delete
              </button>
              <button type='button' value='' className='btn btn-sm px-12 py-3 me-5 btn btn-light fs-4'
                onClick={handleClose}
              >
                {' '}
                No{' '}
              </button>
            </Col>
          </>
        )}
        {isDeleteConfirm && (
          <div className='deletemessage text-success '>
            Removed Successfully!
          </div>
        )}
      </div>
    </Modal>,
    modalsRoot
  )
}

export default DeleteAreasDetails;