import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {AreasListHeader} from './components/header/AreasListHeader'
import {AreasTable} from './table/AreasTable'
import { KTCard } from '../../../../_metronic/helpers'
 import {AreaEditModal} from './area-edit-modal/AreaEditModal'
// import {KTCard} from '../../../../../_metronic/helpers'

const AreasList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <AreasListHeader />
        <AreasTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <AreaEditModal />}
    </>
  )
}

const AreasListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <AreasList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {AreasListWrapper}
