import React from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";


const ViewDriverDetails = () => {

    const navigate = useNavigate()
    const navigateToDriverListing = () => {
        // 👇️ navigate to /contacts
        navigate('/driver-list')
    }


    return (

        <>
            <Row className="row mx-0">
                <Col md={8} sm={12}><h1> View Driver Details </h1></Col>
                <Col md={4} sm={12} className="text-end">
                    <button
                        onClick={navigateToDriverListing}
                        type='button'
                        value=''
                        className='btn btn-sm fs-5 me-5 btn btn-primary'
                    >
                        Back
                    </button>
                </Col>
            </Row>



            <div className="row  mt-5 " >
                <div className="col-sm-12 col-md-6 card mx-auto p-10">
                        <h2 className="mb-5 text-orange"> Mr. Satpal Seth <span className="badge badge-light-success fs-5 fw-bolder ms-20">Active </span></h2>
                        <Row className="mb-5">
                            <Col>
                                  <p className="fw-bold fs-5">B1106 -1107, Mondeal Square, SG Highway, Ahmedabad, Gujarat 380015, India.</p>
                            </Col>
                        </Row>
                        <Row className="d-blick-inline">
                            <Col md={4} className="col-sm-6 col-md-5 col-lg-4 py-2"><h5 className="fw-normal">Contact No:</h5> </Col>
                            <Col md={8} className="col-sm-6 col-md-7 col-lg-8 fw-bold fs-5">+91 98254 36589</Col>
                        </Row>

                        <Row className="d-blick-inline">
                            <Col md={4} className=" py-2"><h5 className="fw-normal">Email id:</h5> </Col>
                            <Col md={8}className=" fw-bold fs-5">thomsonvellay@rentify.com</Col>
                        </Row>
                        
                        <Row className="d-blick-inline">
                            <Col md={4} className=" py-2"><h5 className="fw-normal">Licence No:</h5> </Col>
                            <Col md={8}className=" fw-bold fs-5"> DL-38065405 </Col>
                        </Row>
                       
                    </div>
                </div>


        


        </>
    )

}

export default ViewDriverDetails