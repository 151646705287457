import React from "react";
import { AreawiseListWrapper } from "./areawises-list/AreawisesList";


const AreaWiseReport =() => {

    return(

        <>
         <h2 className="mb-6"> Area Wise Report</h2>
         <AreawiseListWrapper />
        </>
    )
}

export default AreaWiseReport