import {useQuery} from 'react-query'
 import {BookingEditModalForm} from './BookingEditModalForm'
//  import {isNotEmpty, QUERIES} from '../../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
 import {getBookingById} from '../core/_requests'
import { QUERIES, isNotEmpty } from '../../../../../_metronic/helpers'

const BookingEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const dataresponse = {name:"demo"}
  const {
    isLoading,
    data: booking,
    error,
  } = useQuery(
    `${QUERIES.BOOKINGS_LIST}-booking-${itemIdForUpdate}`,
    () => {
      return getBookingById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
       // setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return <BookingEditModalForm isBookingLoading={false} booking={{id: undefined}} />
  }

  if (itemIdForUpdate) {
    return <BookingEditModalForm isBookingLoading={false} booking={dataresponse} />
  }

  return null
}

export {BookingEditModalFormWrapper}
