import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {CartypesListHeader} from './components/header/CartypesListHeader'
import {CartypesTable} from './table/CartypesTable'
import { KTCard } from '../../../../_metronic/helpers'
 import {CartypeEditModal} from './cartype-edit-modal/CartypeEditModal'
// import {KTCard} from '../../../../../_metronic/helpers'

const CartypesList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <CartypesListHeader />
        <CartypesTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <CartypeEditModal />}
    </>
  )
}

const CartypesListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <CartypesList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {CartypesListWrapper}
