// import {KTSVG} from '../../../../../../../_metronic/helpers'
import { useNavigate } from 'react-router-dom'
import { KTSVG } from '../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {CarsListFilter} from './CarsListFilter'

const CarsListToolbar = () => {
  const {setItemIdForUpdate} = useListView()
  const openAddCarModal = () => {
    setItemIdForUpdate(null)
  }

  const navigate = useNavigate()
  const navigateAddCar = () => {
    // 👇️ navigate to /
    navigate(`/addcar`)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-car-table-toolbar='base'>
      <CarsListFilter />

      {/* begin::Export */}
      {/* <button type='button' className='btn btn-light-primary me-3'>
        <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
        Export
      </button> */}
      {/* end::Export */}

      {/* begin::Add car */}
      <button type='button' className='btn btn-primary' onClick={navigateAddCar}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        Add Car
      </button>
      {/* end::Add car */}
    </div>
  )
}

export {CarsListToolbar}
