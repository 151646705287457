import axios, {AxiosResponse} from 'axios'
// import {ID, Response} from '../../../../../../_metronic/helpers'
import {Contact, ContactsQueryResponse} from './_models'
import { ID, Response } from '../../../../../_metronic/helpers'

const API_URL = process.env.REACT_APP_THEME_API_URL
const CONTACT_URL = `${API_URL}/contact`
const GET_CONTACTS_URL = `${API_URL}/users/query`

const getContacts = (query: string): Promise<ContactsQueryResponse> => {
  return axios
    .get(`${GET_CONTACTS_URL}?${query}`)
    .then((d: AxiosResponse<ContactsQueryResponse>) => d.data)
}

const getContactById = (id: ID): Promise<Contact | undefined> => {
  return axios
    .get(`${CONTACT_URL}/${id}`)
    .then((response: AxiosResponse<Response<Contact>>) => response.data)
    .then((response: Response<Contact>) => response.data)
}

const createContact = (contact: Contact): Promise<Contact | undefined> => {
  return axios
    .put(CONTACT_URL, contact)
    .then((response: AxiosResponse<Response<Contact>>) => response.data)
    .then((response: Response<Contact>) => response.data)
}

const updateContact = (contact: Contact): Promise<Contact | undefined> => {
  return axios
    .post(`${CONTACT_URL}/${contact.id}`, contact)
    .then((response: AxiosResponse<Response<Contact>>) => response.data)
    .then((response: Response<Contact>) => response.data)
}

const deleteContact = (contactId: ID): Promise<void> => {
  return axios.delete(`${CONTACT_URL}/${contactId}`).then(() => {})
}

const deleteSelectedContacts = (contactIds: Array<ID>): Promise<void> => {
  const requests = contactIds.map((id) => axios.delete(`${CONTACT_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getContacts, deleteContact, deleteSelectedContacts, getContactById, createContact, updateContact}
