import axios, {AxiosResponse} from 'axios'
// import {ID, Response} from '../../../../../../_metronic/helpers'
import {Booking, BookingsQueryResponse} from './_models'
import { ID, Response } from '../../../../../_metronic/helpers'

const API_URL = process.env.REACT_APP_THEME_API_URL
const BOOKING_URL = `${API_URL}/booking`
const GET_BOOKINGS_URL = `${API_URL}/users/query`

const getBookings = (query: string): Promise<BookingsQueryResponse> => {
  return axios
    .get(`${GET_BOOKINGS_URL}?${query}`)
    .then((d: AxiosResponse<BookingsQueryResponse>) => d.data)
}

const getBookingById = (id: ID): Promise<Booking | undefined> => {
  return axios
    .get(`${BOOKING_URL}/${id}`)
    .then((response: AxiosResponse<Response<Booking>>) => response.data)
    .then((response: Response<Booking>) => response.data)
}

const createBooking = (booking: Booking): Promise<Booking | undefined> => {
  return axios
    .put(BOOKING_URL, booking)
    .then((response: AxiosResponse<Response<Booking>>) => response.data)
    .then((response: Response<Booking>) => response.data)
}

const updateBooking = (booking: Booking): Promise<Booking | undefined> => {
  return axios
    .post(`${BOOKING_URL}/${booking.id}`, booking)
    .then((response: AxiosResponse<Response<Booking>>) => response.data)
    .then((response: Response<Booking>) => response.data)
}

const deleteBooking = (bookingId: ID): Promise<void> => {
  return axios.delete(`${BOOKING_URL}/${bookingId}`).then(() => {})
}

const deleteSelectedBookings = (bookingIds: Array<ID>): Promise<void> => {
  const requests = bookingIds.map((id) => axios.delete(`${BOOKING_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getBookings, deleteBooking, deleteSelectedBookings, getBookingById, createBooking, updateBooking}
