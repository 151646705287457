// import {KTSVG} from '../../../../../../../_metronic/helpers'
import { KTSVG } from '../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {CartypesListFilter} from './CartypesListFilter'

const CartypesListToolbar = () => {
  const {setItemIdForUpdate} = useListView()
  const openAddCartypeModal = () => {
    setItemIdForUpdate(null)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-cartype-table-toolbar='base'>
      {/* <CartypesListFilter /> */}

      {/* begin::Export */}
      {/* <button type='button' className='btn btn-light-primary me-3'>
        <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
        Export
      </button> */}
      {/* end::Export */}

      {/* begin::Add cartype */}
      <button type='button' className='btn btn-primary' onClick={openAddCartypeModal}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        Add Cartype
      </button>
      {/* end::Add cartype */}
    </div>
  )
}

export {CartypesListToolbar}
