import { FC, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
// import {isNotEmpty, toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import { initialDriverreport, Driverreport } from '../core/_models'
import clsx from 'clsx'
import { useListView } from '../core/ListViewProvider'
import { DriverreportsListLoading } from '../components/loading/DriverreportsListLoading'
import { createDriverreport, updateDriverreport } from '../core/_requests'
import { useQueryResponse } from '../core/QueryResponseProvider'
// import { isNotEmpty, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { Col, Form, Row } from 'react-bootstrap'

import React from 'react';
import Select from 'react-select';
import { isNotEmpty, toAbsoluteUrl } from '../../../../../../_metronic/helpers'

const driverreport_type = [
  { value: 'Sedan', label: 'Sedan' },
  { value: 'SUV', label: 'SUV' },
  { value: 'MUV', label: 'MUV' },
  { value: 'Hatchback', label: 'Hatchback' },
  { value: 'Luxury', label: 'Luxury' },
  { value: 'Wagon', label: 'Wagon' },
  { value: 'Jeep', label: 'Jeep' },
];

type Props = {
  isDriverreportLoading: boolean
  driverreport: Driverreport
}

const editDriverreportSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Name is required'),
})

const DriverreportEditModalForm: FC<Props> = ({ driverreport, isDriverreportLoading }) => {
  const { setItemIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()

  const [driverreportForEdit] = useState<Driverreport>({
    ...driverreport,
    avatar: driverreport.avatar || initialDriverreport.avatar,
    role: driverreport.role || initialDriverreport.role,
    position: driverreport.position || initialDriverreport.position,
    name: driverreport.name || initialDriverreport.name,
    email: driverreport.email || initialDriverreport.email,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const blankImg = toAbsoluteUrl('/media/svg/avatars/blank.svg')
  const driverreportAvatarImg = toAbsoluteUrl(`/media/${driverreportForEdit.avatar}`)

  const formik = useFormik({
    initialValues: driverreportForEdit,
    validationSchema: editDriverreportSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          await updateDriverreport(values)
        } else {
          await createDriverreport(values)
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  const [selectedDriverreporttype, setSelectedDriverreporttype] = useState(null);

  return (
    <>
      <form id='kt_modal_add_floor_form' className='form' >
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column  me-n7 pe-7 Krunal'
          id='kt_modal_add_floor_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_floor_header'
          data-kt-scroll-wrappers='#kt_modal_add_floor_scroll'
          data-kt-scroll-offset='300px'
        >


          <Row className='mb-8'>
            <Col sm={12} md={6}>
              <Form.Group className="mb-8" >
                <Form.Label> Driverreport Name <span className='fs-6 text-danger'> * </span></Form.Label>
                <Form.Control type="text" className='' placeholder="" required />
              </Form.Group>
            </Col>

            <Col sm={12} md={6}>
              <Form.Group className="mb-8" >
                <Form.Label> Driverreporte Type <span className='fs-6 text-danger'> * </span></Form.Label>
                <Select
                  defaultValue={selectedDriverreporttype}
                  options={driverreport_type}
                  required />
              </Form.Group>
            </Col>
          </Row>

          <div className='separator mb-4'></div>
          <div className='text-end'>

            <button
              type='submit'
              className='btn btn-primary me-3'
              data-kt-users-modal-action='submit'
            >
              <span className='indicator-label'>Submit</span>
            </button>

            <button
              type='reset'
              onClick={() => cancel()}
              className='btn btn-light-danger '
              data-kt-users-modal-action='cancel'
            >
              Cancel
            </button>
          </div>

        </div>
      </form>
      {(formik.isSubmitting || isDriverreportLoading) && <DriverreportsListLoading />}
    </>
  )
}

export { DriverreportEditModalForm }
