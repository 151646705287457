// import {KTSVG} from '../../../../../../../_metronic/helpers'
import { useNavigate } from 'react-router-dom'
// import { KTSVG } from '../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
// import {AreawisesListFilter} from './AreawisesListFilter'
import { KTSVG } from '../../../../../../../_metronic/helpers'
import { AreawisesListFilter } from './AreawisesListFilter'

const AreawisesListToolbar = () => {
  const {setItemIdForUpdate} = useListView()
  const openAddAreawiseModal = () => {
    setItemIdForUpdate(null)
  }

  const navigate = useNavigate()
  const navigateAddAreawise = () => {
    // 👇️ navigate to /
    navigate(`/addareawise`)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-areawise-table-toolbar='base'>
      <AreawisesListFilter />

      {/* begin::Export */}
      {/* <button type='button' className='btn btn-light-primary me-3'>
        <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
        Export
      </button> */}
      {/* end::Export */}

      {/* begin::Add areawise */}
      {/* <button type='button' className='btn btn-primary' onClick={navigateAddAreawise}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        Add Areawise
      </button> */}
      {/* end::Add areawise */}
    </div>
  )
}

export {AreawisesListToolbar}
