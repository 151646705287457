// @ts-nocheck
import {Column} from 'react-table'
import {DriverreportInfoCell} from './DriverreportInfoCell'
import {DriverreportLastLoginCell} from './DriverreportLastLoginCell'
import {DriverreportTwoStepsCell} from './DriverreportTwoStepsCell'
import {DriverreportActionsCell} from './DriverreportActionsCell'
import {DriverreportSelectionCell} from './DriverreportSelectionCell'
import {DriverreportCustomHeader} from './DriverreportCustomHeader'
import {DriverreportSelectionHeader} from './DriverreportSelectionHeader'
import {Driverreport} from '../../core/_models'

const driverreportsColumns: ReadonlyArray<Column<Driverreport>> = [
  // {
  //   Header: (props) => <DriverreportSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <DriverreportSelectionCell id={props.data[props.row.index].id} />,
  // },

  {
    Header: (props) => <DriverreportCustomHeader tableProps={props} title='Date' className='min-w-125px text-black' />,
    accessor: 'date',
    Cell: ({...props}) => "2 Mar 2024"
  },
  {
    Header: (props) => <DriverreportCustomHeader tableProps={props} title='Booking No' className='min-w-125px text-black' />,
    accessor: 'booking_number',
    Cell: ({...props}) => "1531"
  },

  {
    Header: (props) => <DriverreportCustomHeader tableProps={props} title='Name' className='min-w-125px text-black' />,
    accessor: 'driver_name',
    Cell: ({...props}) => "Satpal Seth"
  },
  {
    Header: (props) => <DriverreportCustomHeader tableProps={props} title='Email Id' className='min-w-125px text-black' />,
    accessor: 'email',
    Cell: ({...props}) => "satpal@rentify.com"
  },

  {
    Header: (props) => <DriverreportCustomHeader tableProps={props} title='Contact No' className='min-w-125px text-black' />,
    accessor: 'contact_no',
    Cell: ({...props}) => "98254 36589"
  },

  {
    Header: (props) => <DriverreportCustomHeader tableProps={props} title='Licence No' className='min-w-125px text-black' />,
    accessor: 'licence_no',
    Cell: ({...props}) => "DL-38065405"
  },

  {
    Header: (props) => (
      <DriverreportCustomHeader tableProps={props} title='Status' className='min-w-100px' />
    ),
    id: 'two_steps',
    Cell: ({...props}) => <DriverreportTwoStepsCell two_steps={props.data[props.row.index].two_steps} />,
  },
 
  // {
  //   Header: (props) => (
  //     <DriverreportCustomHeader tableProps={props} title='Actions' className='text-end headcol min-w-125px text-black' />
  //   ),
  //   id: 'actions',
  //   Cell: ({...props}) => <DriverreportActionsCell id={props.data[props.row.index].id} />,
  // },
]

export {driverreportsColumns}
