import {useListView} from '../../core/ListViewProvider'
import {UsersListToolbar} from './UserListToolbar'
import {UsersListGrouping} from './UsersListGrouping'
import {UsersListSearchComponent} from './UsersListSearchComponent'

const UsersListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='driverd-header d-flex justify-content-between border-0 pt-10 px-10'>
      <UsersListSearchComponent />
      {/* begin::Userd toolbar */}
      <div className='userd-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <UsersListGrouping /> : <UsersListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Userd toolbar */}
    </div>
  )
}

export {UsersListHeader}
