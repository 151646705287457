import { FC, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
// import {isNotEmpty, toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import { initialExtra, Extra } from '../core/_models'
import clsx from 'clsx'
import { useListView } from '../core/ListViewProvider'
import { ExtrasListLoading } from '../components/loading/ExtrasListLoading'
import { createExtra, updateExtra } from '../core/_requests'
import { useQueryResponse } from '../core/QueryResponseProvider'
import { isNotEmpty, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { Col, Form, Row } from 'react-bootstrap'

import React from 'react';
import Select from 'react-select';

const extra_facilities = [
  { value: 'Anti-lock Braking System (ABS)', label: 'Anti-lock Braking System (ABS)' },
  { value: 'Child seat', label: 'Child seat' },
  { value: 'Bluetooth', label: 'Bluetooth' },
  { value: 'Toolkit', label: 'Toolkit' },
  { value: 'Air Freshener', label: 'Air Freshener' },
  { value: 'Air Conditioning', label: 'Air Conditioning' },
  { value: 'Power steering', label: 'Power steering' },
  { value: 'Power Windows', label: 'Power Windows' },
  { value: 'Spare Tyre', label: 'Spare Tyre' },
];


const car_type = [
  { value: 'Sedan', label: 'Sedan' },
  { value: 'SUV', label: 'SUV' },
  { value: 'MUV', label: 'MUV' },
  { value: 'Hatchback', label: 'Hatchback' },
  { value: 'Luxury', label: 'Luxury' },
  { value: 'Wagon', label: 'Wagon' },
  { value: 'Jeep', label: 'Jeep' },
];


const car_modal = [
  { value: 'Kia Seltos', label: 'Kia Seltos' },
  { value: 'Hyundai Exter', label: 'Hyundai Exter' },
  { value: 'Maruti Suzuki Brezza', label: 'Maruti Suzuki Brezza' },
  { value: 'Toyota Innova', label: 'Toyota Innova' },
  { value: 'Honda Amaze', label: 'Honda Amaze' },

];




type Props = {
  isExtraLoading: boolean
  extra: Extra
}

const editExtraSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Name is required'),
})

const ExtraEditModalForm: FC<Props> = ({ extra, isExtraLoading }) => {
  const { setItemIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()

  const [extraForEdit] = useState<Extra>({
    ...extra,
    avatar: extra.avatar || initialExtra.avatar,
    role: extra.role || initialExtra.role,
    position: extra.position || initialExtra.position,
    name: extra.name || initialExtra.name,
    email: extra.email || initialExtra.email,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const blankImg = toAbsoluteUrl('/media/svg/avatars/blank.svg')
  const extraAvatarImg = toAbsoluteUrl(`/media/${extraForEdit.avatar}`)

  const formik = useFormik({
    initialValues: extraForEdit,
    validationSchema: editExtraSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          await updateExtra(values)
        } else {
          await createExtra(values)
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  const [selectedExtra, setSelectedExtra] = useState(null);
  const [selectedCartype, setSelectedCartype] = useState(null);
  const [selectedCarModal, setSelectedCarModal] = useState(null);

  return (
    <>
      <form id='kt_modal_add_floor_form' className='form' >
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column  me-n7 pe-7 Krunal'
          id='kt_modal_add_floor_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_floor_header'
          data-kt-scroll-wrappers='#kt_modal_add_floor_scroll'
          data-kt-scroll-offset='300px'
        >

{/* <Row className='mb-8'>
<Col sm={12} md={6}>
              <Form.Group className="" >
                <Form.Label> Care Type </Form.Label>
                <Select
                  defaultValue={selectedCartype}
                  options={car_type}
                  required />
              </Form.Group>
            </Col>

            <Col sm={12} md={6}>
              <Form.Group className="" >
                <Form.Label> Car Modal </Form.Label>
                <Select
                  defaultValue={selectedCarModal}
                  options={car_modal}
                  required />
              </Form.Group>

            </Col>
</Row> */}
          <Row className='mb-8'>
          <Col sm={12} md={6}>
          <Form.Group className="mb-8" >
                <Form.Label> Name <span className='fs-6 text-danger'> * </span></Form.Label>
                <Form.Control type="text" className='' placeholder="" required />
              </Form.Group>
              {/* <Form.Group className="mb-8" >
                <Form.Label> Select Extras <span className='fs-6 text-danger'> * </span></Form.Label>
                <Select
                  defaultValue={selectedExtra}
                  options={extra_facilities}
                  isMulti
                  required />
              </Form.Group> */}
            </Col>
            <Col sm={12} md={6}>
              <Form.Group className="mb-8" >
                <Form.Label> Price <span className='fs-6 text-danger'> * </span></Form.Label>
                <Form.Control type="text" className='' placeholder="" required />
              </Form.Group>
            </Col>
          </Row>

          <div className='separator mb-4'></div>
          <div className='text-end'>

            <button
              type='submit'
              className='btn btn-primary me-3'
              data-kt-users-modal-action='submit'
            >
              <span className='indicator-label'>Submit</span>
            </button>

            <button
              type='reset'
              onClick={() => cancel()}
              className='btn btn-light-danger '
              data-kt-users-modal-action='cancel'
            >
              Cancel
            </button>
          </div>

        </div>
      </form>
      {(formik.isSubmitting || isExtraLoading) && <ExtrasListLoading />}
    </>
  )
}

export { ExtraEditModalForm }
