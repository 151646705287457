import axios, {AxiosResponse} from 'axios'
// import {ID, Response} from '../../../../../../_metronic/helpers'
import {Bookingreport, BookingreportsQueryResponse} from './_models'
import { ID, Response } from '../../../../../../_metronic/helpers'
// import { ID, Response } from '../../../../../_metronic/helpers'

const API_URL = process.env.REACT_APP_THEME_API_URL
const BOOKINGREPORT_URL = `${API_URL}/bookingreport`
const GET_BOOKINGREPORTS_URL = `${API_URL}/users/query`

const getBookingreports = (query: string): Promise<BookingreportsQueryResponse> => {
  return axios
    .get(`${GET_BOOKINGREPORTS_URL}?${query}`)
    .then((d: AxiosResponse<BookingreportsQueryResponse>) => d.data)
}

const getBookingreportById = (id: ID): Promise<Bookingreport | undefined> => {
  return axios
    .get(`${BOOKINGREPORT_URL}/${id}`)
    .then((response: AxiosResponse<Response<Bookingreport>>) => response.data)
    .then((response: Response<Bookingreport>) => response.data)
}

const createBookingreport = (bookingreport: Bookingreport): Promise<Bookingreport | undefined> => {
  return axios
    .put(BOOKINGREPORT_URL, bookingreport)
    .then((response: AxiosResponse<Response<Bookingreport>>) => response.data)
    .then((response: Response<Bookingreport>) => response.data)
}

const updateBookingreport = (bookingreport: Bookingreport): Promise<Bookingreport | undefined> => {
  return axios
    .post(`${BOOKINGREPORT_URL}/${bookingreport.id}`, bookingreport)
    .then((response: AxiosResponse<Response<Bookingreport>>) => response.data)
    .then((response: Response<Bookingreport>) => response.data)
}

const deleteBookingreport = (bookingreportId: ID): Promise<void> => {
  return axios.delete(`${BOOKINGREPORT_URL}/${bookingreportId}`).then(() => {})
}

const deleteSelectedBookingreports = (bookingreportIds: Array<ID>): Promise<void> => {
  const requests = bookingreportIds.map((id) => axios.delete(`${BOOKINGREPORT_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getBookingreports, deleteBookingreport, deleteSelectedBookingreports, getBookingreportById, createBookingreport, updateBookingreport}
