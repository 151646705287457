import { useEffect, useState } from 'react'
// import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
// import {initialQueryState, KTSVG} from '../../../../../../../_metronic/helpers'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { useQueryResponse } from '../../core/QueryResponseProvider'
// import { MenuComponent } from '../../../../../../_metronic/assets/ts/components'
// import { KTSVG, initialQueryState } from '../../../../../../_metronic/helpers'
import { Col, Form, Row } from 'react-bootstrap'
import Select from 'react-select';
import { MenuComponent } from '../../../../../../../_metronic/assets/ts/components';
import { KTSVG, initialQueryState } from '../../../../../../../_metronic/helpers';

const car_type = [
  { value: 'Sedan', label: 'Sedan' },
  { value: 'SUV', label: 'SUV' },
  { value: 'MUV', label: 'MUV' },
  { value: 'Hatchback', label: 'Hatchback' },
  { value: 'Luxury', label: 'Luxury' },
  { value: 'Wagon', label: 'Wagon' },
  { value: 'Jeep', label: 'Jeep' },
];

const tra_id = [
  { value: '', label: 'Select' },
  { value: '1708031724431131', label: '1708031724431131' },
  { value: '1578031785641131', label: '1578031785641131' },
  { value: '1156931785641158', label: '1156931785641158' },
  { value: '1145293178564115', label: '1145293178564115' },
  { value: '1459831785641135', label: '1459831785641135' },

];

const bank_name = [
  { value: 'Doha Bank', label: 'Doha Bank' },
  { value: 'Qatar National Bank', label: 'Qatar National Bank' },
  { value: 'Commercial Bank of Qatar', label: 'MCommercial Bank of Qatar' },
  { value: 'Ahli Bank', label: 'Ahli Bank' },
  { value: 'HSBC Bank Middle East', label: 'HSBC Bank Middle East' },
  { value: 'Mashreq', label: 'Mashreq' },
  { value: 'Qatar Development Bank', label: 'Qatar Development Bank' },
  { value: 'International Bank of Qatar', label: 'International Bank of Qatar' },

];




const TransactionsListFilter = () => {
  const { updateState } = useQueryRequest()
  const { isLoading } = useQueryResponse()
  const [role, setRole] = useState<string | undefined>()
  const [lastLogin, setLastLogin] = useState<string | undefined>()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const resetData = () => {
    updateState({ filter: undefined, ...initialQueryState })
  }

  const filterData = () => {
    updateState({
      filter: { role, last_login: lastLogin },
      ...initialQueryState,
    })
  }


  
  const [selectedTransactionID, setSelectedTransactrionId] = useState(null);
  const [selectedCarType, setSelectedCarType] = useState(null);
  const [selectedBankname, setSelectedBankname] = useState(null);
 

  return (
    <>
      {/* begin::Filter Button */}
      <button
        disabled={isLoading}
        type='button'
        className='btn btn-light-primary me-10'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
        Filter
      </button>
      {/* end::Filter Button */}
      {/* begin::SubMenu */}
      <div className='menu menu-sub menu-sub-dropdown w-450px w-md-600px' data-kt-menu='true' style={{height:'500px', overflowY:'auto'}}>
        {/* begin::Header */}
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        {/* begin::Content */}
        <div className='px-7 py-5' data-kt-transaction-table-filter='form'>
        <Row className="mb-5">
            <Col sm={12} md={6}>
            <Form.Group className="" >
                <Form.Label> Date From </Form.Label>
                <Form.Control type='date' className='' placeholder=""  />
              </Form.Group>
            </Col>

            <Col sm={12} md={6}>
            <Form.Group className="" >
                <Form.Label> Date To </Form.Label>
                <Form.Control type='date' className='' placeholder=""  />
              </Form.Group>
            </Col>
            </Row>

            <Row className="mb-5">
            <Col sm={12} md={6}>
            <Form.Group className="" >
                <Form.Label> Booking No. </Form.Label>
                <Form.Control type='text' className='' placeholder="From"  />
              </Form.Group>
            </Col>

            <Col sm={12} md={6}>
            <Form.Group className="" >
                <Form.Label> &nbsp; </Form.Label>
                <Form.Control type='text' className='' placeholder="to"  />
              </Form.Group>
            </Col>
          </Row>

            <Row className="mb-5">
            <Col sm={12} md={6}>
              <Form.Group className="" >
                <Form.Label> Transaction Id </Form.Label>
                <Select
                  defaultValue={selectedTransactionID}
                  options={tra_id}
                   />
              </Form.Group>

            </Col>

          <Col sm={12} md={6}>
              <Form.Group className="" >
                <Form.Label> Payment Method </Form.Label>
                <Form.Select aria-label="Default select example">
                <option value="1">Select</option>
                <option value="1">Credite card</option>
                <option value="2"> Debit Card </option>
              </Form.Select>
              </Form.Group>

            </Col>
            </Row>
          <Row className='mb-5'>
          <Col sm={12} md={6}>
          <Form.Group className="" >
                <Form.Label> Card Type </Form.Label>
                <Form.Select aria-label="Default select example">
                <option value="1">Select</option>
                <option value="1">Visa</option>
                <option value="2"> Mastercard </option>
              </Form.Select>
              </Form.Group>

            </Col>
           
            </Row>
            <Row className='mb-5'>
            <Col sm={12} md={6}>
              <Form.Group className="" >
                <Form.Label> Rate </Form.Label>
                <Form.Control type="text" className='' placeholder="min"  />
              </Form.Group>

            </Col>
            <Col sm={12} md={6}>
              <Form.Group className="" >
                <Form.Label>  </Form.Label>
                <Form.Control type="text" className='' placeholder="max"  />
              </Form.Group>

            </Col>
            </Row>

            <Row className='mb-5'>
            <Col sm={12} md={6} >
              <Form.Label> Status </Form.Label>
              <Form.Select aria-label="Default select example">
                <option value="1">Select</option>
                <option value="1">Success</option>
                <option value="2"> Failed </option>
                <option value="2"> Pending </option>
              </Form.Select>
            </Col>
          </Row>

<div className='separator my-3' ></div>
          {/* begin::Actions */}
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              disabled={isLoading}
              onClick={filterData}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-transaction-table-filter='reset'
            >
              Reset
            </button>
            <button
              disabled={isLoading}
              type='button'
              onClick={resetData}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-transaction-table-filter='filter'
            >
              Apply
            </button>
          </div>
          {/* end::Actions */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::SubMenu */}
    </>
  )
}

export { TransactionsListFilter }
