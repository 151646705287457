import React from "react";
import { CartypesListWrapper } from "./cartypes-list/CartypesList";


const CarTypeListing = () => {

    return (

        <>

            <h2> Car Type List </h2>

            <div className="card  mt-5">
                <CartypesListWrapper />
            </div>
        </>
    )
}

export default CarTypeListing