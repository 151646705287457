import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import FormCheckLabel from "react-bootstrap/esm/FormCheckLabel";
import ImageUploading from 'react-images-uploading';
import { useNavigate } from "react-router-dom";
import Select from 'react-select';


const car_type = [
    { value: 'Sedan', label: 'Sedan' },
    { value: 'SUV', label: 'SUV' },
    { value: 'MUV', label: 'MUV' },
    { value: 'Hatchback', label: 'Hatchback' },
    { value: 'Luxury', label: 'Luxury' },
    { value: 'Wagon', label: 'Wagon' },
    { value: 'Jeep', label: 'Jeep' },
];

const area = [
    { value: 'Maninagar', label: 'Maninagar' },
    { value: 'Shivranjani', label: 'Shivranjani' },
    { value: 'Naroda', label: 'Naroda' },
    { value: 'Thaltej', label: 'Thaltej' },
    { value: 'Bapunagar', label: 'Bapunagar' },
    { value: 'Nikol', label: 'Nikol' },
    { value: 'Paldi', label: 'Paldi' },
];

const car_capacity = [
    { value: '4', label: '4' },
    { value: '5', label: '4' },
    { value: '7', label: '7' },
    { value: '9', label: '9' },
    { value: '16', label: '16' },
    { value: '32', label: '32' },
    { value: '56', label: '56' },
];


const AddEditCarDetails = () => {


    const [images, setImages] = React.useState([]);
    const maxNumber = 69;
    const onChange = (imageList: any, addUpdateIndex: any) => {
        // data for submit
        console.log(imageList, addUpdateIndex);
        setImages(imageList);
    };
    const [selectedCartype, setSelectedCartype] = useState(null);
    const [selectedArea, setSelectedArea] = useState(null);
    const [selectedCapacity, setSelectedCapacity] = useState(null);
    return (
        <>


            <div className="card p-6  mt-5">
                <form id='kt_modal_add_tenant_form' className='form' >
                    <Row className="mb-8">
                        <Col md={12}>
                            <div className="App image upload section">
                                <Form.Label> Upload Images </Form.Label>
                                <ImageUploading
                                    multiple
                                    value={images}
                                    onChange={onChange}
                                    maxNumber={maxNumber}
                                    dataURLKey="data_url"
                                >
                                    {({
                                        imageList,
                                        onImageUpload,
                                        onImageRemoveAll,
                                        onImageUpdate,
                                        onImageRemove,
                                        isDragging,
                                        dragProps,
                                    }) => (
                                        // write your building UI
                                        <div className="upload__image-wrapper text-center">
                                            <button
                                                className="py-3 px-3 fs-4"
                                                style={isDragging ? { color: 'red' } : undefined}
                                                onClick={onImageUpload}
                                                {...dragProps}
                                            >
                                                Upload Car Image
                                            </button>
                                            &nbsp;
                                            {/* <button  className="py-3 px-3 fs-4" onClick={onImageRemoveAll}>Remove all images</button> */}
                                            <div className="d-flex flex-wrap">
                                                {imageList.map((image, index) => (

                                                    <div key={index} className="image-item my-10 mx-5">
                                                        <img src={image['data_url']} alt="" style={{ maxWidth: "100%" }} />
                                                        <div className="image-item__btn-wrapper text-center">
                                                            {/* <button onClick={() => onImageUpdate(index)} className="me-2 px-1 update bg-light-orange text-orange"> Update </button> */}
                                                            <button onClick={() => onImageRemove(index)} className="px-1 remove"> X </button>
                                                        </div>
                                                    </div>

                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </ImageUploading>

                            </div>

                        </Col>
                    </Row>


                    <Row className="mb-8 mt-8">
                        <Col sm={12} md={6} lg={4}>
                            <Form.Group className="mb-8" >
                                <Form.Label> Select Area <span className='fs-6 text-danger'> * </span></Form.Label>
                                <Select
                                    defaultValue={selectedArea}
                                    options={area}
                                    isMulti
                                    required />
                            </Form.Group>

                        </Col>
                        <Col sm={12} md={6} lg={4}>
                            <Form.Group className="mb-8" >
                                <Form.Label> Care Type <span className='fs-6 text-danger'> * </span></Form.Label>
                                <Select
                                    defaultValue={selectedCartype}
                                    options={car_type}
                                    required />
                            </Form.Group>

                        </Col>
                        <Col sm={12} md={6} lg={4}>
                            <Form.Group className="mb-8" >
                                <Form.Label> Car Modal <span className='fs-6 text-danger'> * </span></Form.Label>
                                <Form.Control className='form-control form-control-solid' placeholder="" required />
                            </Form.Group>

                        </Col>
                    </Row>

                    <Row className="mb-8">
                        <Col sm={12} md={6} lg={4}>
                            <Form.Group className="mb-8" >
                                <Form.Label> Car Number <span className='fs-6 text-danger'> * </span></Form.Label>
                                <Form.Control className='form-control form-control-solid' placeholder="" required />
                            </Form.Group>

                        </Col>
                        <Col sm={12} md={6} lg={4}>
                            <Form.Group className="mb-8" >
                                <Form.Label> Car Capacity <span className='fs-6 text-danger'> * </span></Form.Label>
                                <Select
                                    defaultValue={selectedCapacity}
                                    options={car_capacity}
                                    required />
                            </Form.Group>

                        </Col>


                        <Col sm={12} md={6} lg={4}>
                            <Form.Label> Car Rental <span className='fs-6 text-danger'> * </span></Form.Label>
                            <Form.Select aria-label="Default select example">
                                <option value="1">Select</option>
                                <option value="1">Per Hourly fees amount</option>
                                <option value="2">Per Day fees amount</option>
                                <option value="3">Deposite</option>
                            </Form.Select>

                        </Col>
                        <Col sm={12} md={6} lg={4}>

                            <Form.Group className="mb-3">
                                <Form.Label> Rent Amount <span className='fs-6 text-danger'> * </span></Form.Label>
                                <Form.Control type="text" className='form-control form-control-solid' placeholder=" " required />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-8">
                        <Col sm={12} md={6} lg={4}>

                            <Form.Group className="mb-3">
                                <Form.Label> Description </Form.Label>
                                <Form.Control type="text" as="textarea" rows={3} className='form-control form-control-solid' placeholder=" "  />
                            </Form.Group>
                        </Col>
                        <Col sm={12} md={6} lg={4}>

                            <Form.Group className="mb-3">
                                <Form.Label> Specifications </Form.Label>
                                <Form.Control type="text" as="textarea" rows={3} className='form-control form-control-solid' placeholder=" "  />
                            </Form.Group>
                        </Col>
                        <Col sm={12} md={6} lg={4}>
                            <Form.Label> Status </Form.Label>
                            <div className="form-check mb-3">
                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked />
                                <label className="form-check-label"  >
                                    Ready to Book
                                </label>
                            </div>
                            <div className="form-check mb-3">
                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                                <label className="form-check-label" >
                                    Under Maintenance
                                </label>

                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                                <label className="form-check-label" >
                                    Repairing
                                </label>

                            </div>
                        </Col>
                    </Row>

                    <div className="separator mb-4"></div>

                    <div className='text-center pt-5'>

                        <button
                            type='submit'
                            className='btn btn-primary me-3'
                            data-kt-users-modal-action='submit'
                        >
                            <span className='indicator-label'>Submit</span>
                        </button>

                        <button
                            type='reset'
                            className='btn btn-light-danger '
                            data-kt-users-modal-action='cancel'
                        >
                            Cancel
                        </button>
                    </div>
                </form>

            </div>

        </>
    )
}

export default AddEditCarDetails