
import { BookingreportModel, ID, Response } from "../../../../../../_metronic/helpers"

export type Bookingreport = {
  id?: ID
  name?: string
  booking_name?: string
  avatar?: string
  email?: string
  position?: string
  role?: string
  last_login?: string
  two_steps?: boolean
  joined_day?: string
  online?: boolean
  initials?: {
    label: string
    state: string
  }
}

export type BookingreportsQueryResponse = Response<Array<BookingreportModel>>

export const initialBookingreport: Bookingreport = {
  avatar: 'avatars/300-6.jpg',
  position: 'Art Director',
  role: 'Administrator',
  name: '',
  email: '',
}
