// @ts-nocheck
import {Column} from 'react-table'
import {ContactInfoCell} from './ContactInfoCell'
import {ContactLastLoginCell} from './ContactLastLoginCell'
import {ContactTwoStepsCell} from './ContactTwoStepsCell'
import {ContactActionsCell} from './ContactActionsCell'
import {ContactSelectionCell} from './ContactSelectionCell'
import {ContactCustomHeader} from './ContactCustomHeader'
import {ContactSelectionHeader} from './ContactSelectionHeader'
import {Contact} from '../../core/_models'

const contactsColumns: ReadonlyArray<Column<Contact>> = [
  // {
  //   Header: (props) => <ContactSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <ContactSelectionCell id={props.data[props.row.index].id} />,
  // },

  {
    Header: (props) => <ContactCustomHeader tableProps={props} title='Name' className='min-w-250px text-black' />,
    accessor: 'name',
    Cell: ({...props}) => "Mr. Pravin Rathod"
  },

  {
    Header: (props) => <ContactCustomHeader tableProps={props} title='Contact No' className='min-w-150px text-black' />,
    accessor: 'contact_no',
    Cell: ({...props}) => "98254 23648"
  },


  {
    Header: (props) => <ContactCustomHeader tableProps={props} title='Message' className='min-w-125px text-black msg-lmt' />,
    accessor: 'message',
    Cell: ({...props}) => "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident"
  },

  
  {
    Header: (props) => (
      <ContactCustomHeader tableProps={props} title='Actions' className='text-end min-w-125px text-black' />
    ),
    id: 'actions',
    Cell: ({...props}) => <ContactActionsCell id={props.data[props.row.index].id} />,
  },
]

export {contactsColumns}
