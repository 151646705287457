import {useListView} from '../../core/ListViewProvider'
import {CartypesListToolbar} from './CartypeListToolbar'
import {CartypesListGrouping} from './CartypesListGrouping'
import {CartypesListSearchComponent} from './CartypesListSearchComponent'

const CartypesListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <CartypesListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <CartypesListGrouping /> : <CartypesListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {CartypesListHeader}
