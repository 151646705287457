import {useListView} from '../../core/ListViewProvider'
import {DriversListToolbar} from './DriverListToolbar'
import {DriversListGrouping} from './DriversListGrouping'
import {DriversListSearchComponent} from './DriversListSearchComponent'

const DriversListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='driverd-header d-flex justify-content-between border-0 pt-10 px-10'>
      <DriversListSearchComponent />
      {/* begin::Driverd toolbar */}
      <div className='driverd-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <DriversListGrouping /> : <DriversListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Driverd toolbar */}
    </div>
  )
}

export {DriversListHeader}
