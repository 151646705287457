import React from "react";
import { DriversListWrapper } from "./derivers-list/DriversList";


const DriverListing = () => {

    return (

        <>
            <h2> Driver List </h2>
            <div className="card mt-5">
                <DriversListWrapper />
            </div>

        </>
    )
}

export default DriverListing