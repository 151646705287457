import {useListView} from '../../core/ListViewProvider'
import {CarsListToolbar} from './CarListToolbar'
import {CarsListGrouping} from './CarsListGrouping'
import {CarsListSearchComponent} from './CarsListSearchComponent'

const CarsListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='driverd-header d-flex justify-content-between border-0 pt-10 px-10'>
      <CarsListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <CarsListGrouping /> : <CarsListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {CarsListHeader}
