import {useListView} from '../../core/ListViewProvider'
import {AreawisesListToolbar} from './AreawiseListToolbar'
import {AreawisesListGrouping} from './AreawisesListGrouping'
import { BookingsListSearchComponent } from './AreawisesListSearchComponent'
// import {AreawisesListSearchComponent} from './AreawisesListSearchComponent'

const AreawisesListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='areawised-header d-flex justify-content-between border-0 pt-6'>
      <BookingsListSearchComponent />
      {/* begin::Areawised toolbar */}
      <div className='areawised-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <AreawisesListGrouping /> : <AreawisesListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Areawised toolbar */}
    </div>
  )
}

export {AreawisesListHeader}
