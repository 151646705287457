import {useQuery} from 'react-query'
 import {UserEditModalForm} from './UserEditModalForm'
//  import {isNotEmpty, QUERIES} from '../../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
 import {getUserById} from '../core/_requests'
import { QUERIES, isNotEmpty } from '../../../../../_metronic/helpers'

const UserEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const dataresponse = {name:"demo"}
  const {
    isLoading,
    data: car,
    error,
  } = useQuery(
    `${QUERIES.MANAGEUSERS_LIST}-car-${itemIdForUpdate}`,
    () => {
      return getUserById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
       // setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return <UserEditModalForm isUserLoading={false} car={{id: undefined}} />
  }

  if (itemIdForUpdate) {
    return <UserEditModalForm isUserLoading={false} car={dataresponse} />
  }

  return null
}

export {UserEditModalFormWrapper}
