/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
// import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
// import {ID, KTSVG, QUERIES} from '../../../../../../../_metronic/helpers'
import { useListView } from '../../core/ListViewProvider'
// import { useQueryResponse } from '../../core/QueryResponseProvider'
import { deleteBookingreport } from '../../core/_requests'
// import { ID, KTSVG, QUERIES } from '../../../../../../_metronic/helpers'
// import { MenuComponent } from '../../../../../../_metronic/assets/ts/components'
import { OverlayTrigger } from 'react-bootstrap'
import Tooltip from 'react-bootstrap/Tooltip';
// import DeleteBookingreportsDetails from '../../../DeleteBookingreport'
import { useNavigate } from 'react-router-dom'
// import DeleteBookingreportsDetails from '../../../DeleteBookingreportDetails'
import { MenuComponent } from '../../../../../../../_metronic/assets/ts/components'
import { ID } from '../../../../../../../_metronic/helpers'
import { useQueryResponse } from '../../core/QueryResponseProvider'
// import DeleteBookingreportsDetails from '../../../DeleteBookingreport'
// import DeleteBookingreportsDetails from '../../../DeleteBookingreportType'

type Props = {
  id: ID
}

const BookingreportActionsCell: FC<Props> = ({ id }) => {
  const { setItemIdForUpdate } = useListView()
  // const { query } = useQueryResponse()
  // const queryClient = useQueryClient()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const navigate = useNavigate()
    const navigateToViewBookingreportDetails = () => {
      // 👇️ navigate to /contacts
      navigate('/report/view-bookingreport')
    }


  const [ShowDeleteBookingreportDetails, setShowDeleteBookingreportDetails] = useState<boolean>(false)

  return (
    <>
     

      <div className='d-flex flex-wrap flex-end'>
      <div className='menu-item px-1'>
          <a className='menu-link p-1'
            onClick={navigateToViewBookingreportDetails}
          >
            <OverlayTrigger
              placement='top'
              overlay={<Tooltip className='py-2 px-2 fs-5 '>View</Tooltip>}
            >
              {/* <i className="fa-regular fa-pen-to-square fs-4 text-warning"></i> */}
              <i className="fa-solid fa-eye fs-4 text-info"></i>
            </OverlayTrigger>
          </a>
        </div>

        {/* <div className='menu-item '>
          <a className='menu-link p-1'
            onClick={navigateToEditBookingreport}
          >
            <OverlayTrigger
              placement='top'
              overlay={<Tooltip className='py-2 px-2 fs-5 '>Edit</Tooltip>}
            >
              <i className="fa-regular fa-pen-to-square fs-4 text-warning"></i>
            </OverlayTrigger>
          </a>
        </div> */}

        {/* <div className='menu-item '>
          <a className='menu-link p-1' onClick={() => setShowDeleteBookingreportDetails(true)} >
            <OverlayTrigger
              placement='top'
              overlay={<Tooltip className='py-2 px-2 fs-5 '>Delete</Tooltip>}
            >
              <i className="fa-solid fa-trash-can fs-4 text-danger"></i>
            </OverlayTrigger>
          </a>

           <DeleteBookingreportsDetails
           show={ShowDeleteBookingreportDetails} handleClose={() => setShowDeleteBookingreportDetails(false)}
          />   
        </div> */}

      </div>




      {/* begin::Menu item */}
      {/* <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={openEditModal}>
            Edit
          </a>
        </div> */}
      {/* end::Menu item */}

      {/* begin::Menu item */}
      {/* <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            data-kt-bookingreporttypes-table-filter='delete_row'
            onClick={async () => await deleteItem.mutateAsync()}
          >
            Delete
          </a>
        </div> */}
      {/* end::Menu item */}

      {/* end::Menu */}
    </>
  )
}

export { BookingreportActionsCell }
