import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
// import {AreawiseListHeader} from './components/header/AreawiseListHeader'
// import {AreawiseTable} from './table/AreawiseTable'
// import { KTCard } from '../../../../_metronic/helpers'
// import { AreawiseEditModal } from './areawisereportreportreport-edit-modal/AreawiseEditModal'
import { KTCard } from '../../../../../_metronic/helpers'
import { AreawisesListHeader } from './components/header/AreawisesListHeader'
import { AreawisesTable } from './table/AreawisesTable'
import { AreawiseEditModal } from './areawise-edit-modal/AreawiseEditModal'
//  import {AreawiseEditModal} from './car-edit-modal/AreawiseEditModal'
// import {KTAreawised} from '../../../../../_metronic/helpers'

const AreawiseList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <AreawisesListHeader />
        <AreawisesTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <AreawiseEditModal />}
    </>
  )
}

const AreawiseListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <AreawiseList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {AreawiseListWrapper}
