import React from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";


const ViewUserDetails = () => {

    const navigate = useNavigate()
    const navigateToDriverListing = () => {
        // 👇️ navigate to /contacts
        navigate('/user-list')
    }


    return (

        <>
            <Row className="row mx-0">
                <Col md={8} sm={12}><h1> View User Details </h1></Col>
                <Col md={4} sm={12} className="text-end">
                    <button
                        onClick={navigateToDriverListing}
                        type='button'
                        value=''
                        className='btn btn-sm fs-5 me-5 btn btn-primary'
                    >
                        Back
                    </button>
                </Col>
            </Row>



            <div className="row  mt-5 " >
                <div className="col-sm-12 col-md-6 card mx-auto p-10">
                        <h2 className="mb-5 text-orange"> Mr. Aiyub Panara <span className="badge badge-light-success fs-5 fw-bolder ms-20">Active </span></h2>
                        <Row className="mb-5">
                            <Col>
                                  <p className="fw-bold fs-5"> 1 Bhoomi Apartment-15 Motinagar Society, Mahalaxmi, Paldi, Ahmedabad, Gujarat 380007, India.</p>
                            </Col>
                        </Row>
                        <Row className="d-blick-inline">
                            <Col md={4} className="col-sm-6 col-md-5 col-lg-4 py-2"><h5 className="fw-normal">Contact No:</h5> </Col>
                            <Col md={8} className="col-sm-6 col-md-7 col-lg-8 fw-bold fs-5">+91 98254 36589</Col>
                        </Row>

                        <Row className="d-blick-inline">
                            <Col md={4} className=" py-2"><h5 className="fw-normal">Email id:</h5> </Col>
                            <Col md={8}className=" fw-bold fs-5">aiyubpanara@rentify.com</Col>
                        </Row>
                        
                    </div>
                </div>
        </>
    )

}

export default ViewUserDetails