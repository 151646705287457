import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {CarreportsListHeader} from './components/header/CarreportsListHeader'
import {CarreportsTable} from './table/CarreportsTable'
// import { KTCard } from '../../../../_metronic/helpers'
 import {CarreportEditModal} from './carreport-edit-modal/CarreportEditModal'
import { KTCard } from '../../../../../_metronic/helpers'
// import {KTCard} from '../../../../../_metronic/helpers'

const CarreportsList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <CarreportsListHeader />
        <CarreportsTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <CarreportEditModal />}
    </>
  )
}

const CarreportsListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <CarreportsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {CarreportsListWrapper}
