// import {KTSVG} from '../../../../../../../_metronic/helpers'
// import { KTSVG } from '../../../../../../_metronic/helpers'
import { KTSVG } from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {CarreportsListFilter} from './CarreportsListFilter'

const CarreportsListToolbar = () => {
  const {setItemIdForUpdate} = useListView()
  const openAddCarreportModal = () => {
    setItemIdForUpdate(null)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-area-table-toolbar='base'>
      <CarreportsListFilter />

      {/* begin::Export */}
      {/* <button type='button' className='btn btn-light-primary me-3'>
        <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
        Export
      </button> */}
      {/* end::Export */}

      {/* begin::Add area */}
      {/* <button type='button' className='btn btn-primary' onClick={openAddCarreportModal}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        Add Carreport
      </button> */}
      {/* end::Add area */}
    </div>
  )
}

export {CarreportsListToolbar}
