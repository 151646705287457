import axios, {AxiosResponse} from 'axios'
// import {ID, Response} from '../../../../../../_metronic/helpers'
import {Cartype, CartypesQueryResponse} from './_models'
import { ID, Response } from '../../../../../_metronic/helpers'

const API_URL = process.env.REACT_APP_THEME_API_URL
const CARTYPE_URL = `${API_URL}/cartype`
const GET_CARTYPES_URL = `${API_URL}/users/query`

const getCartypes = (query: string): Promise<CartypesQueryResponse> => {
  return axios
    .get(`${GET_CARTYPES_URL}?${query}`)
    .then((d: AxiosResponse<CartypesQueryResponse>) => d.data)
}

const getCartypeById = (id: ID): Promise<Cartype | undefined> => {
  return axios
    .get(`${CARTYPE_URL}/${id}`)
    .then((response: AxiosResponse<Response<Cartype>>) => response.data)
    .then((response: Response<Cartype>) => response.data)
}

const createCartype = (cartype: Cartype): Promise<Cartype | undefined> => {
  return axios
    .put(CARTYPE_URL, cartype)
    .then((response: AxiosResponse<Response<Cartype>>) => response.data)
    .then((response: Response<Cartype>) => response.data)
}

const updateCartype = (cartype: Cartype): Promise<Cartype | undefined> => {
  return axios
    .post(`${CARTYPE_URL}/${cartype.id}`, cartype)
    .then((response: AxiosResponse<Response<Cartype>>) => response.data)
    .then((response: Response<Cartype>) => response.data)
}

const deleteCartype = (cartypeId: ID): Promise<void> => {
  return axios.delete(`${CARTYPE_URL}/${cartypeId}`).then(() => {})
}

const deleteSelectedCartypes = (cartypeIds: Array<ID>): Promise<void> => {
  const requests = cartypeIds.map((id) => axios.delete(`${CARTYPE_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getCartypes, deleteCartype, deleteSelectedCartypes, getCartypeById, createCartype, updateCartype}
