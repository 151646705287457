import React from "react";
import { ExtrasListWrapper } from "./extras-list/ExtrasList";


const ManageExtra =() => {

    return(

        <>
         <h2> Manage Extra</h2>
         <ExtrasListWrapper />
        
        </>
    )
}

export default ManageExtra