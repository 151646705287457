import {useEffect, useState} from 'react'
// import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
// import {initialQueryState, KTSVG} from '../../../../../../../_metronic/helpers'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
// import { MenuComponent } from '../../../../../../_metronic/assets/ts/components'
// import { KTSVG, initialQueryState } from '../../../../../../_metronic/helpers'
import { Col, Form, Row } from 'react-bootstrap'
// import Select from 'react-select'
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { MenuComponent } from '../../../../../../../_metronic/assets/ts/components';
import { KTSVG, initialQueryState } from '../../../../../../../_metronic/helpers';
import Select from 'react-select';

const car_type = [
  { value: 'Sedan', label: 'Sedan' },
  { value: 'SUV', label: 'SUV' },
  { value: 'MUV', label: 'MUV' },
  { value: 'Hatchback', label: 'Hatchback' },
  { value: 'Luxury', label: 'Luxury' },
  { value: 'Wagon', label: 'Wagon' },
  { value: 'Jeep', label: 'Jeep' },
];

const car_modal = [
  { value: 'Kia Seltos', label: 'Kia Seltos' },
  { value: 'Hyundai Exter', label: 'Hyundai Exter' },
  { value: 'Maruti Suzuki Brezza', label: 'Maruti Suzuki Brezza' },
  { value: 'Toyota Innova', label: 'Toyota Innova' },
  { value: 'Honda Amaze', label: 'Honda Amaze' },

];



const CarreportsListFilter = () => {
  const {updateState} = useQueryRequest()
  const {isLoading} = useQueryResponse()
  const [role, setRole] = useState<string | undefined>()
  const [lastLogin, setLastLogin] = useState<string | undefined>()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const resetData = () => {
    updateState({filter: undefined, ...initialQueryState})
  }

  const filterData = () => {
    updateState({
      filter: {role, last_login: lastLogin},
      ...initialQueryState,
    })
  }
  
  const [selectedCartype, setSelectedCartype] = useState(null);
  const [selectedCarModal, setSelectedCarModal] = useState(null);

  return (
    <>
      {/* begin::Filter Button */}
      <button
        disabled={isLoading}
        type='button'
        className='btn btn-light-primary me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
        Filter
      </button>
      {/* end::Filter Button */}
      {/* begin::SubMenu */}
      <div className='menu menu-sub menu-sub-dropdown w-400px w-md-425px' data-kt-menu='true'>
        {/* begin::Header */}
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        {/* begin::Content */}
        <div className='px-7 py-5' data-kt-carreport-table-filter='form'>
        <Row className="mb-5">
            <Col sm={12} md={6}>
            <Form.Group className="" >
                <Form.Label> Date From </Form.Label>
                <Form.Control type='date' className='' placeholder=""  />
              </Form.Group>
            </Col>

            <Col sm={12} md={6}>
            <Form.Group className="" >
                <Form.Label> Date To </Form.Label>
                <Form.Control type='date' className='' placeholder=""  />
              </Form.Group>
            </Col>
            </Row>

            <Row className="mb-5">
            <Col sm={12} md={6}>
              <Form.Group className="" >
                <Form.Label> Care Type </Form.Label>
                <Select
                  defaultValue={selectedCartype}
                  options={car_type}
                   />
              </Form.Group>

            </Col>
            <Col sm={12} md={6}>
              <Form.Group className="" >
                <Form.Label> Car Modal </Form.Label>
                <Select
                  defaultValue={selectedCarModal}
                  options={car_modal}
                   />
              </Form.Group>

            </Col>
            </Row>

        <Row className='mb-8'>
        <Col sm={12} md={6} >
              <Form.Label> Status </Form.Label>
              <Form.Select aria-label="Default select example">
                <option value="1">Select</option>
                <option value="1">Ready to Book</option>
                <option value="2"> Under Maintenance</option>
                <option value="3">Repairing</option>
              </Form.Select>
            </Col>
          </Row>

          {/* begin::Actions */}
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              disabled={isLoading}
              onClick={filterData}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-carreport-table-filter='reset'
            >
              Reset
            </button>
            <button
              disabled={isLoading}
              type='button'
              onClick={resetData}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-carreport-table-filter='filter'
            >
              Apply
            </button>
          </div>
          {/* end::Actions */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::SubMenu */}
    </>
  )
}

export {CarreportsListFilter}
