import {useListView} from '../../core/ListViewProvider'
import {CarreportsListToolbar} from './CarreportListToolbar'
import {CarreportsListGrouping} from './CarreportsListGrouping'
import {CarreportsListSearchComponent} from './CarreportsListSearchComponent'

const CarreportsListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <CarreportsListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <CarreportsListGrouping /> : <CarreportsListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {CarreportsListHeader}
