import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
// import {TransactionListHeader} from './components/header/TransactionListHeader'
// import {TransactionTable} from './table/TransactionTable'
// import { KTCard } from '../../../../_metronic/helpers'
// import { TransactionEditModal } from './transactionreportreportreport-edit-modal/TransactionEditModal'
import { KTCard } from '../../../../../_metronic/helpers'
import { TransactionsListHeader } from './components/header/TransactionsListHeader'
import { TransactionsTable } from './table/TransactionsTable'
import { TransactionEditModal } from './transaction-edit-modal/TransactionEditModal'
//  import {TransactionEditModal} from './car-edit-modal/TransactionEditModal'
// import {KTTransactiond} from '../../../../../_metronic/helpers'

const TransactionList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <TransactionsListHeader />
        <TransactionsTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <TransactionEditModal />}
    </>
  )
}

const TransactionListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <TransactionList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {TransactionListWrapper}
