import React, { useState } from "react";
// import { Row } from "react-bootstrap";
import Select from 'react-select';
import { Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

// import AddEditBookingDetails from "./AddEditBookingDetails";


const booking_type = [
    { value: 'Sedan', label: 'Sedan' },
    { value: 'SUV', label: 'SUV' },
    { value: 'MUV', label: 'MUV' },
    { value: 'Hatchback', label: 'Hatchback' },
    { value: 'Luxury', label: 'Luxury' },
    { value: 'Wagon', label: 'Wagon' },
    { value: 'Jeep', label: 'Jeep' },
  ];
  
  const area = [
    { value: 'Maninagar', label: 'Maninagar' },
    { value: 'Shivranjani', label: 'Shivranjani' },
    { value: 'Naroda', label: 'Naroda' },
    { value: 'Thaltej', label: 'Thaltej' },
    { value: 'Bapunagar', label: 'Bapunagar' },
    { value: 'Nikol', label: 'Nikol' },
    { value: 'Paldi', label: 'Paldi' },
  ];
  
  const destination = [
    { value: 'Goa', label: 'Goa' },
    { value: 'Kerala', label: 'Kerala' },
    { value: 'Agra', label: 'Agra' },
    { value: 'Udaipur', label: 'Udaipur' },
    { value: 'Bombay', label: 'Bombay' },
    { value: 'Manali', label: 'Manali' },
    { value: 'Varanasi', label: 'Varanasi' },
  ];
  
  
  const user_email = [
    { value: 'aiyub@demo.com', label: 'aiyub@demo.com' },
    { value: 'parth@demo.com', label: 'parth@demo.com' },
    { value: 'karan@demo.com', label: 'karan@demo.com' },
    { value: 'irfan@demo.com', label: 'irfan@demo.com' },
    { value: 'divyesh@demo.com', label: 'divyesh@demo.com' },
    { value: 'mayur@demo.com', label: 'mayur@demo.com' },
    { value: 'pravin@demo.com', label: 'pravin@demo.com' },
  ];
  
  const user_contact = [
    { value: '98254 26354', label: '98254 26354' },
    { value: '99684 36254', label: '99684 36254' },
    { value: '98524 36985', label: '98524 36985' },
    { value: '97241 36598', label: '97241 36598' },
    { value: '97584 26584', label: '97584 26584' },
    { value: '99254 36547', label: '99254 36547' },
    { value: '98745 21546', label: '98745 21546' },
  ];
  
  
  const driver_name = [
    { value: 'Aiyub Panara', label: 'Aiyub Panara' },
    { value: 'Parth Dave', label: 'Parth Dave' },
    { value: 'Karan Patel', label: 'Karan Patel' },
    { value: 'Irfan Pathan', label: 'Irfan Pathan' },
    { value: 'Divyesh Rana', label: 'Divyesh Rana' },
    { value: 'Mayur Hole', label: 'Mayur Hole' },
    { value: 'Pravin Rathod', label: 'Pravin Rathod' },
  ];
  
  const licence_number = [
    { value: 'DL-69854040236', label: 'DL-69854040236' },
    { value: 'DL-42050987053', label: 'DL-42050987053' },
    { value: 'DL-65840570356', label: 'DL-65840570356' },
    { value: 'DL-35042058702', label: 'DL-35042058702' },
  
  ];



const AddEditBookingForm = () => {

    const [selecteddestination, setSelecteddestination] = useState(null);
    const [selectedArea, setSelectedArea] = useState(null);
    const [selectedBookingType, setSelectedBookingType] = useState(null);
    const [selectedUserEmail, setSelectedUserEmail] = useState(null);
    const [selectedUserContact, setSelectedUserContact] = useState(null);
    const [selectedDriverName, setSelectedDriverName] = useState(null);
    const [selectedLicenceNumber, setSelectedLicenceNumber] = useState(null);

    return(
        <>
         <form id='kt_modal_add_tenant_form' className='form' >
          <Row >
          <Col sm={12} md={6} lg={4} xl={3} className="mb-8">
              <Form.Group className="" >
                <Form.Label> Pick Up Location <span className="text-danger fs-5"> * </span></Form.Label>
                <Select
                  defaultValue={selectedArea}
                  options={area}
                  required />
              </Form.Group>
            </Col>

          <Col sm={12} md={6} lg={4} xl={3} className="mb-8">
              <Form.Group className="" >
                <Form.Label> Destination <span className="text-danger fs-5"> * </span></Form.Label>
                <Select
                  defaultValue={selecteddestination}
                  options={destination}
                  required />
              </Form.Group>

            </Col>
         </Row>

         <Row >
            <Col sm={12} md={6} lg={4} xl={3} className="mb-8">
            <Form.Group className="" >
                <Form.Label> Pick Up Date <span className="text-danger fs-5"> * </span></Form.Label>
                <Form.Control type='date' className='' placeholder=""  required />
              </Form.Group>
            </Col>

            <Col sm={12} md={6}  lg={4}  xl={3} className="mb-8">
            <Form.Group className="" >
                <Form.Label> Pick Up Time <span className="text-danger fs-5"> * </span></Form.Label>
                <Form.Control type='time' className='' placeholder="" required  />
              </Form.Group>
            </Col>

            <Col sm={12} md={6}  lg={4}  xl={3} className="mb-8">
            <Form.Group className="" >
                <Form.Label> Round Trip Date <span className="text-danger fs-5"> * </span></Form.Label>
                <Form.Control type='date' className='' placeholder="" required />
              </Form.Group>
            </Col>

            <Col sm={12} md={6}  lg={4}  xl={3} className="mb-8">
            <Form.Group className="" >
                <Form.Label> Round Trip Time <span className="text-danger fs-5"> * </span></Form.Label>
                <Form.Control type='time' className='' placeholder="" required />
              </Form.Group>
            </Col>

</Row>
    <h3 className="py-5"> User Information </h3>
<Row>
            <Col sm={12} md={6}  lg={4} xl={3} className="mb-8">
            <Form.Group className="" >
                <Form.Label> User Email <span className="text-danger fs-5"> * </span></Form.Label>
                <Select
                  defaultValue={selectedUserEmail}
                  options={user_email}
                  required
                   />
              </Form.Group>
            </Col>

            <Col sm={12} md={6}  lg={4} xl={3} className="mb-8">
            <Form.Group className="" >
                <Form.Label> User Phone No <span className="text-danger fs-5"> * </span></Form.Label>
                <Select
                  defaultValue={selectedUserContact}
                  options={user_contact}
                  required
                   />
              </Form.Group>
            </Col>

            <Col sm={12} md={6}  lg={4} xl={3} className="mb-8">
            <Form.Group className="" >
                <Form.Label> Message From User </Form.Label>
                <Form.Control as="textarea" rows={3} className='form-control form-control-solid' placeholder=""  />
              </Form.Group>
            </Col>
</Row>
<h3 className="py-5"> Driver  Information </h3>
<Row>
            <Col sm={12} md={6}  lg={4} xl={3} className="mb-8">
            <Form.Group className="" >
                <Form.Label> Driver Name <span className="text-danger fs-5"> * </span></Form.Label>
                <Select
                  defaultValue={selectedDriverName}
                  options={driver_name}
                  required />
              </Form.Group>
            </Col>

            <Col sm={12} md={6}  lg={4} xl={3} className="mb-8">
            <Form.Group className="" >
                <Form.Label> Licence No <span className="text-danger fs-5"> * </span></Form.Label>
                <Select
                  defaultValue={selectedLicenceNumber}
                  options={licence_number}
                  required  />
              </Form.Group>
            </Col>
  </Row>

  <h3 className="py-5"> Payment information </h3>
  <Row>
            <Col sm={12} md={6}  lg={4} xl={3} className="mb-8">
              <Form.Label> Rate <span className="text-danger fs-5"> * </span></Form.Label>
              <Form.Control  className='form-control form-control-solid' placeholder="" required />
            </Col>

            <Col sm={12} md={6}  lg={4} xl={3} className="mb-8">
              <Form.Label> Credit/Debit Card <span className="text-danger fs-5"> * </span> </Form.Label>
              <Form.Control  className='form-control form-control-solid' placeholder=" " required />
            </Col>
            <Col sm={12} md={6}  lg={4} xl={3} className="mb-8">
              <Form.Label> Exp. Date <span className="text-danger fs-5"> * </span></Form.Label>
              <Form.Control type="date"  className='form-control form-control-solid' placeholder=" " required />
            </Col>
            <Col sm={12} md={6}  lg={4} xl={3} className="mb-8">
              <Form.Label> Cvv <span className="text-danger fs-5"> * </span></Form.Label>
              <Form.Control type="text"  className='form-control form-control-solid' placeholder=" " required />
            </Col>
            </Row>
            <Row>
            <Col sm={12} md={6}  lg={4} xl={3} className="mb-8">
              <Form.Label> Status <span className="text-danger fs-5"> * </span></Form.Label>
              <Form.Select aria-label="Default select example"  required>
                <option value="1">Select</option>
                <option value="1">Booked</option>
                <option value="2"> Ongoing</option>
                <option value="3">Completed</option>
              </Form.Select>
            </Col>
          </Row>
                
          <div className="separator mb-4"></div>

<div className='text-center pt-5'>

    <button
        type='submit'
        className='btn btn-primary me-3'
        data-kt-users-modal-action='submit'
    >
        <span className='indicator-label'>Submit</span>
    </button>

    <button
        type='reset'
        className='btn btn-light-danger '
        data-kt-users-modal-action='cancel'
    >
        Cancel
    </button>
</div>
</form>
        
        </>
    )
}
 export default AddEditBookingForm