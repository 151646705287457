import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
// import {BookingreportListHeader} from './components/header/BookingreportListHeader'
// import {BookingreportTable} from './table/BookingreportTable'
// import { KTCard } from '../../../../_metronic/helpers'
// import { BookingreportEditModal } from './bookingreportreportreportreport-edit-modal/BookingreportEditModal'
import { KTCard } from '../../../../../_metronic/helpers'
import { BookingreportsListHeader } from './components/header/BookingreportsListHeader'
import { BookingreportsTable } from './table/BookingreportsTable'
import { BookingreportEditModal } from './bookingreport-edit-modal/BookingreportEditModal'
//  import {BookingreportEditModal} from './car-edit-modal/BookingreportEditModal'
// import {KTBookingreportd} from '../../../../../_metronic/helpers'

const BookingreportList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <BookingreportsListHeader />
        <BookingreportsTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <BookingreportEditModal />}
    </>
  )
}

const BookingreportListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <BookingreportList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {BookingreportListWrapper}
