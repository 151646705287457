import axios, {AxiosResponse} from 'axios'
// import {ID, Response} from '../../../../../../_metronic/helpers'
import {Car, CarsQueryResponse} from './_models'
import { ID, Response } from '../../../../../_metronic/helpers'

const API_URL = process.env.REACT_APP_THEME_API_URL
const CAR_URL = `${API_URL}/car`
const GET_CARS_URL = `${API_URL}/users/query`

const getCars = (query: string): Promise<CarsQueryResponse> => {
  return axios
    .get(`${GET_CARS_URL}?${query}`)
    .then((d: AxiosResponse<CarsQueryResponse>) => d.data)
}

const getCarById = (id: ID): Promise<Car | undefined> => {
  return axios
    .get(`${CAR_URL}/${id}`)
    .then((response: AxiosResponse<Response<Car>>) => response.data)
    .then((response: Response<Car>) => response.data)
}

const createCar = (car: Car): Promise<Car | undefined> => {
  return axios
    .put(CAR_URL, car)
    .then((response: AxiosResponse<Response<Car>>) => response.data)
    .then((response: Response<Car>) => response.data)
}

const updateCar = (car: Car): Promise<Car | undefined> => {
  return axios
    .post(`${CAR_URL}/${car.id}`, car)
    .then((response: AxiosResponse<Response<Car>>) => response.data)
    .then((response: Response<Car>) => response.data)
}

const deleteCar = (carId: ID): Promise<void> => {
  return axios.delete(`${CAR_URL}/${carId}`).then(() => {})
}

const deleteSelectedCars = (carIds: Array<ID>): Promise<void> => {
  const requests = carIds.map((id) => axios.delete(`${CAR_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getCars, deleteCar, deleteSelectedCars, getCarById, createCar, updateCar}
