// import {KTSVG} from '../../../../../../../_metronic/helpers'
import { useNavigate } from 'react-router-dom'
// import { KTSVG } from '../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
// import {TransactionsListFilter} from './TransactionsListFilter'
import { KTSVG } from '../../../../../../../_metronic/helpers'
import { TransactionsListFilter } from './TransactionsListFilter'

const TransactionsListToolbar = () => {
  const {setItemIdForUpdate} = useListView()
  const openAddTransactionModal = () => {
    setItemIdForUpdate(null)
  }

  const navigate = useNavigate()
  const navigateAddTransaction = () => {
    // 👇️ navigate to /
    navigate(`/addtransaction`)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-transaction-table-toolbar='base'>
      <TransactionsListFilter />

      {/* begin::Export */}
      {/* <button type='button' className='btn btn-light-primary me-3'>
        <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
        Export
      </button> */}
      {/* end::Export */}

      {/* begin::Add transaction */}
      {/* <button type='button' className='btn btn-primary' onClick={navigateAddTransaction}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        Add Transaction
      </button> */}
      {/* end::Add transaction */}
    </div>
  )
}

export {TransactionsListToolbar}
