import {useMemo} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {useQueryResponseData, useQueryResponseLoading} from '../core/QueryResponseProvider'
import {bookingreportsColumns} from './columns/_columns'
import {Bookingreport} from '../core/_models'
import {BookingreportsListLoading} from '../components/loading/BookingreportsListLoading'
import {BookingreportsListPagination} from '../components/pagination/BookingreportsListPagination'
import { KTCardBody } from '../../../../../../_metronic/helpers'
//  import { KTCardBody } from '../../../../../_metronic/helpers'
//  import {KTBookingreportdBody} from '../../../../../../_metronic/helpers'

const BookingreportsTable = () => {
  const bookingreports = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => bookingreports, [bookingreports])
  const columns = useMemo(() => bookingreportsColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_bookingreports'
          className='table align-middle table-row-dashed  fs-6 gy-5 dataTable bookingreport-listing no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start bookingreportreport-light-secondary fw-bolder fs-6 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<Bookingreport>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<Bookingreport>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <BookingreportsListPagination />
      {isLoading && <BookingreportsListLoading />}
    </KTCardBody>
  )
}

export {BookingreportsTable}
