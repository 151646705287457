import { useEffect, useState } from 'react'
// import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
// import {initialQueryState, KTSVG} from '../../../../../../../_metronic/helpers'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { MenuComponent } from '../../../../../../_metronic/assets/ts/components'
import { KTSVG, initialQueryState } from '../../../../../../_metronic/helpers'
import { Col, Form, Row } from 'react-bootstrap'
import Select from 'react-select';

const user_email = [
  { value: 'aiyub@demo.com', label: 'aiyub@demo.com' },
  { value: 'parth@demo.com', label: 'parth@demo.com' },
  { value: 'karan@demo.com', label: 'karan@demo.com' },
  { value: 'irfan@demo.com', label: 'irfan@demo.com' },
  { value: 'divyesh@demo.com', label: 'divyesh@demo.com' },
  { value: 'mayur@demo.com', label: 'mayur@demo.com' },
  { value: 'pravin@demo.com', label: 'pravin@demo.com' },
];

const user_contact = [
  { value: '98254 26354', label: '98254 26354' },
  { value: '99684 36254', label: '99684 36254' },
  { value: '98524 36985', label: '98524 36985' },
  { value: '97241 36598', label: '97241 36598' },
  { value: '97584 26584', label: '97584 26584' },
  { value: '99254 36547', label: '99254 36547' },
  { value: '98745 21546', label: '98745 21546' },
];


const user_name = [
  { value: 'Aiyub Panara', label: 'Aiyub Panara' },
  { value: 'Parth Dave', label: 'Parth Dave' },
  { value: 'Karan Patel', label: 'Karan Patel' },
  { value: 'Irfan Pathan', label: 'Irfan Pathan' },
  { value: 'Divyesh Rana', label: 'Divyesh Rana' },
  { value: 'Mayur Hole', label: 'Mayur Hole' },
  { value: 'Pravin Rathod', label: 'Pravin Rathod' },
];

const licence_number = [
  { value: 'DL-69854040236', label: 'DL-69854040236' },
  { value: 'DL-42050987053', label: 'DL-42050987053' },
  { value: 'DL-65840570356', label: 'DL-65840570356' },
  { value: 'DL-35042058702', label: 'DL-35042058702' },

];

const state = [
  { value: 'Gujarat', label: 'Gujarat' },
  { value: 'Maharashtra', label: 'Maharashtra' },
  { value: 'Punjab', label: 'Punjab' },
  { value: 'Rajasthan', label: 'Rajasthan' },
  { value: 'Madhya Pradesh', label: 'Madhya Pradesh' },

];

const city = [
  { value: 'Ahmedabad', label: 'Ahmedabad' },
  { value: 'Surat', label: 'Surat' },
  { value: 'Bombay', label: 'Bombay' },
  { value: 'Jaipur', label: 'Jaipur' },
  { value: 'Bhopal', label: 'Bhopal' },

];

const zipcode = [
  { value: '392456', label: '392456' },
  { value: '396514', label: '396514' },
  { value: '361478', label: '361478' },
  { value: '884512', label: '884512' },
  { value: '963214', label: '963214' },

];

const UsersListFilter = () => {
  const { updateState } = useQueryRequest()
  const { isLoading } = useQueryResponse()
  const [role, setRole] = useState<string | undefined>()
  const [lastLogin, setLastLogin] = useState<string | undefined>()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const resetData = () => {
    updateState({ filter: undefined, ...initialQueryState })
  }

  const filterData = () => {
    updateState({
      filter: { role, last_login: lastLogin },
      ...initialQueryState,
    })
  }


  const [selectedDriverEmail, setSelectedDriverEmail] = useState(null);
  const [selectedDriverContact, setSelectedDriverContact] = useState(null);
  const [selectedDriverName, setSelectedDriverName] = useState(null);
  const [selectedLicenceNumber, setSelectedLicenceNumber] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedZipcode, setSelectedZipcode] = useState(null);

  return (
    <>
      {/* begin::Filter Button */}
      <button
        disabled={isLoading}
        type='button'
        className='btn btn-light-primary me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
        Filter
      </button>
      {/* end::Filter Button */}
      {/* begin::SubMenu */}
      <div className='menu menu-sub menu-sub-dropdown w-450px w-md-475px' data-kt-menu='true'>
        {/* begin::Header */}
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        {/* begin::Content */}
        <div className='px-7 py-5' data-kt-user-table-filter='form'>
        <Row className="mb-5">
            <Col sm={12} >
            <Form.Group className="" >
                <Form.Label> User Name </Form.Label>
                <Select
                  defaultValue={selectedDriverName}
                  options={user_name}
                   />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-5">
          <Col sm={12} md={6}>
            <Form.Group className="" >
                <Form.Label> Contact No </Form.Label>
                <Select
                  defaultValue={selectedDriverContact}
                  options={user_contact}
                   />
              </Form.Group>
            </Col>

            <Col sm={12} md={6}>
            <Form.Group className="" >
                <Form.Label> Email Id</Form.Label>
                <Select
                  defaultValue={selectedDriverEmail}
                  options={user_email}
                   />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-5">
          <Col sm={12} md={6}>
            <Form.Group className="" >
                <Form.Label> State </Form.Label>
                <Select
                  defaultValue={selectedState}
                  options={state}
                   />
              </Form.Group>
            </Col>

            <Col sm={12} md={6}>
            <Form.Group className="" >
                <Form.Label> City</Form.Label>
                <Select
                  defaultValue={selectedCity}
                  options={city}
                   />
              </Form.Group>
            </Col>
          </Row>
          

            <Row className="mb-5">
            <Col sm={12} md={6}>
            <Form.Group className="" >
                <Form.Label> Zipcode </Form.Label>
                <Select
                  defaultValue={selectedZipcode}
                  options={zipcode}
                   />
              </Form.Group>
            </Col>

            <Col sm={12} md={6} >
              <Form.Label> Status </Form.Label>
              <Form.Select aria-label="Default select example">
                <option value="1">Select</option>
                <option value="1">Active</option>
                <option value="2"> Deactive</option>
                
              </Form.Select>
            </Col>
          </Row>

          {/* begin::Actions */}
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              disabled={isLoading}
              onClick={filterData}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              Reset
            </button>
            <button
              disabled={isLoading}
              type='button'
              onClick={resetData}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              Apply
            </button>
          </div>
          {/* end::Actions */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::SubMenu */}
    </>
  )
}

export { UsersListFilter }
