import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {DriversListHeader} from './components/header/DriversListHeader'
import {DriversTable} from './table/DriversTable'
import { KTCard } from '../../../../_metronic/helpers'
 import {DriverEditModal} from './driver-edit-modal/DriverEditModal'
// import {KTDriverd} from '../../../../../_metronic/helpers'

const DriversList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <DriversListHeader />
        <DriversTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <DriverEditModal />}
    </>
  )
}

const DriversListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <DriversList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {DriversListWrapper}
