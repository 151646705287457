import axios, {AxiosResponse} from 'axios'
// import {ID, Response} from '../../../../../../_metronic/helpers'
import {Area, AreasQueryResponse} from './_models'
import { ID, Response } from '../../../../../_metronic/helpers'

const API_URL = process.env.REACT_APP_THEME_API_URL
const AREA_URL = `${API_URL}/area`
const GET_AREAS_URL = `${API_URL}/users/query`

const getAreas = (query: string): Promise<AreasQueryResponse> => {
  return axios
    .get(`${GET_AREAS_URL}?${query}`)
    .then((d: AxiosResponse<AreasQueryResponse>) => d.data)
}

const getAreaById = (id: ID): Promise<Area | undefined> => {
  return axios
    .get(`${AREA_URL}/${id}`)
    .then((response: AxiosResponse<Response<Area>>) => response.data)
    .then((response: Response<Area>) => response.data)
}

const createArea = (area: Area): Promise<Area | undefined> => {
  return axios
    .put(AREA_URL, area)
    .then((response: AxiosResponse<Response<Area>>) => response.data)
    .then((response: Response<Area>) => response.data)
}

const updateArea = (area: Area): Promise<Area | undefined> => {
  return axios
    .post(`${AREA_URL}/${area.id}`, area)
    .then((response: AxiosResponse<Response<Area>>) => response.data)
    .then((response: Response<Area>) => response.data)
}

const deleteArea = (areaId: ID): Promise<void> => {
  return axios.delete(`${AREA_URL}/${areaId}`).then(() => {})
}

const deleteSelectedAreas = (areaIds: Array<ID>): Promise<void> => {
  const requests = areaIds.map((id) => axios.delete(`${AREA_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getAreas, deleteArea, deleteSelectedAreas, getAreaById, createArea, updateArea}
