import {useListView} from '../../core/ListViewProvider'
import {DriverreportsListToolbar} from './DriverreportListToolbar'
import {DriverreportsListGrouping} from './DriverreportsListGrouping'
import { DriversListSearchComponent } from './DriverreportsListSearchComponent'
// import {DriverreportsListSearchComponent} from './DriverreportsListSearchComponent'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';


const totaldriver = 100;

const DriverreportsListHeader = () => {
  const {selected} = useListView()
  return (
<>

    <div className='driverreportd-header d-flex justify-content-between align-items-center border-0 pt-6'>
      <DriversListSearchComponent />
      {/* begin::Driverreportd toolbar */}

      <div className='driverreportd-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <DriverreportsListGrouping /> : <DriverreportsListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Driverreportd toolbar */}
    </div>
    
    </>
  )
}

export {DriverreportsListHeader}
