import axios, {AxiosResponse} from 'axios'
// import {ID, Response} from '../../../../../../_metronic/helpers'
import {Areawise, AreawisesQueryResponse} from './_models'
import { ID, Response } from '../../../../../../_metronic/helpers'
// import { ID, Response } from '../../../../../_metronic/helpers'

const API_URL = process.env.REACT_APP_THEME_API_URL
const AREAWISE_URL = `${API_URL}/areawise`
const GET_AREAWISES_URL = `${API_URL}/users/query`

const getAreawises = (query: string): Promise<AreawisesQueryResponse> => {
  return axios
    .get(`${GET_AREAWISES_URL}?${query}`)
    .then((d: AxiosResponse<AreawisesQueryResponse>) => d.data)
}

const getAreawiseById = (id: ID): Promise<Areawise | undefined> => {
  return axios
    .get(`${AREAWISE_URL}/${id}`)
    .then((response: AxiosResponse<Response<Areawise>>) => response.data)
    .then((response: Response<Areawise>) => response.data)
}

const createAreawise = (areawise: Areawise): Promise<Areawise | undefined> => {
  return axios
    .put(AREAWISE_URL, areawise)
    .then((response: AxiosResponse<Response<Areawise>>) => response.data)
    .then((response: Response<Areawise>) => response.data)
}

const updateAreawise = (areawise: Areawise): Promise<Areawise | undefined> => {
  return axios
    .post(`${AREAWISE_URL}/${areawise.id}`, areawise)
    .then((response: AxiosResponse<Response<Areawise>>) => response.data)
    .then((response: Response<Areawise>) => response.data)
}

const deleteAreawise = (areawiseId: ID): Promise<void> => {
  return axios.delete(`${AREAWISE_URL}/${areawiseId}`).then(() => {})
}

const deleteSelectedAreawises = (areawiseIds: Array<ID>): Promise<void> => {
  const requests = areawiseIds.map((id) => axios.delete(`${AREAWISE_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getAreawises, deleteAreawise, deleteSelectedAreawises, getAreawiseById, createAreawise, updateAreawise}
