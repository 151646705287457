import {useListView} from '../../core/ListViewProvider'
import {TransactionsListToolbar} from './TransactionListToolbar'
import {TransactionsListGrouping} from './TransactionsListGrouping'
import { TransactionsListSearchComponent } from './TransactionsListSearchComponent'
// import {TransactionsListSearchComponent} from './TransactionsListSearchComponent'

const TransactionsListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='transactiond-header d-flex justify-content-between border-0 pt-6'>
      <TransactionsListSearchComponent />
      {/* begin::Transactiond toolbar */}
      <div className='transactiond-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <TransactionsListGrouping /> : <TransactionsListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Transactiond toolbar */}
    </div>
  )
}

export {TransactionsListHeader}
