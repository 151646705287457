// import {KTSVG} from '../../../../../../../_metronic/helpers'
import { useNavigate } from 'react-router-dom'
// import { KTSVG } from '../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {BookingreportsListFilter} from './BookingreportsListFilter'
import { KTSVG } from '../../../../../../../_metronic/helpers'

const BookingreportsListToolbar = () => {
  const {setItemIdForUpdate} = useListView()
  const openAddBookingreportModal = () => {
    setItemIdForUpdate(null)
  }

  const navigate = useNavigate()
  const navigateAddBookingreport = () => {
    // 👇️ navigate to /
    navigate(`/addbookingreport`)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-bookingreport-table-toolbar='base'>
      <BookingreportsListFilter />

      {/* begin::Export */}
      {/* <button type='button' className='btn btn-light-primary me-3'>
        <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
        Export
      </button> */}
      {/* end::Export */}

      {/* begin::Add bookingreport */}
      {/* <button type='button' className='btn btn-primary' onClick={navigateAddBookingreport}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        Add Bookingreport
      </button> */}
      {/* end::Add bookingreport */}
    </div>
  )
}

export {BookingreportsListToolbar}
