import {useMemo} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {useQueryResponseData, useQueryResponseLoading} from '../core/QueryResponseProvider'
import {driverreportsColumns} from './columns/_columns'
import {Driverreport} from '../core/_models'
import {DriverreportsListLoading} from '../components/loading/DriverreportsListLoading'
import {DriverreportsListPagination} from '../components/pagination/DriverreportsListPagination'
import { KTCardBody } from '../../../../../../_metronic/helpers'
//  import { KTCardBody } from '../../../../../_metronic/helpers'
//  import {KTDriverreportdBody} from '../../../../../../_metronic/helpers'

const DriverreportsTable = () => {
  const driverreports = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => driverreports, [driverreports])
  const columns = useMemo(() => driverreportsColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_driverreports'
          className='table align-middle table-row-dashed  fs-6 gy-5 dataTable driverreport-listing no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start driverreportreport-light-secondary fw-bolder fs-6 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<Driverreport>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<Driverreport>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <DriverreportsListPagination />
      {isLoading && <DriverreportsListLoading />}
    </KTCardBody>
  )
}

export {DriverreportsTable}
