// @ts-nocheck
import {Column} from 'react-table'
import {CartypeInfoCell} from './CartypeInfoCell'
import {CartypeLastLoginCell} from './CartypeLastLoginCell'
import {CartypeTwoStepsCell} from './CartypeTwoStepsCell'
import {CartypeActionsCell} from './CartypeActionsCell'
import {CartypeSelectionCell} from './CartypeSelectionCell'
import {CartypeCustomHeader} from './CartypeCustomHeader'
import {CartypeSelectionHeader} from './CartypeSelectionHeader'
import {Cartype} from '../../core/_models'

const cartypesColumns: ReadonlyArray<Column<Cartype>> = [
  // {
  //   Header: (props) => <CartypeSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <CartypeSelectionCell id={props.data[props.row.index].id} />,
  // },
  {
    Header: (props) => <CartypeCustomHeader tableProps={props} title='Car Type' className='min-w-125px text-black' />,
    accessor: 'car_type',
    Cell: ({...props}) => "Hatchback"
  },

  {
    Header: (props) => (
      <CartypeCustomHeader tableProps={props} title='Status' className='min-w-125px' />
    ),
    id: 'two_steps',
    Cell: ({...props}) => <CartypeTwoStepsCell two_steps={props.data[props.row.index].two_steps} />,
  },
 
  {
    Header: (props) => (
      <CartypeCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px text-black' />
    ),
    id: 'actions',
    Cell: ({...props}) => <CartypeActionsCell id={props.data[props.row.index].id} />,
  },
]

export {cartypesColumns}
