// @ts-nocheck
import {Column} from 'react-table'
import {UserInfoCell} from './UserInfoCell'
import {UserLastLoginCell} from './UserLastLoginCell'
import {UserTwoStepsCell} from './UserTwoStepsCell'
import {UserActionsCell} from './UserActionsCell'
import {UserSelectionCell} from './UserSelectionCell'
import {UserCustomHeader} from './UserCustomHeader'
import {UserSelectionHeader} from './UserSelectionHeader'
import {User} from '../../core/_models'

const usersColumns: ReadonlyArray<Column<User>> = [
  // {
  //   Header: (props) => <UserSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <UserSelectionCell id={props.data[props.row.index].id} />,
  // },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Name' className='min-w-125px text-black' />,
    accessor: 'user_name',
    Cell: ({...props}) => "Aiyub Panara"
  },


  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Email Id' className='min-w-125px text-black' />,
    accessor: 'email',
    Cell: ({...props}) => "aiyubpanara@rentify.com"
  },

  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Contact No' className='min-w-125px text-black' />,
    accessor: 'contact_no',
    Cell: ({...props}) => "98254 36589"
  },


  {
    Header: (props) => <UserCustomHeader tableProps={props} title='State' className='min-w-125px text-black' />,
    accessor: 'state',
    Cell: ({...props}) => "Maharashtra"
  },

  {
    Header: (props) => <UserCustomHeader tableProps={props} title='City' className='min-w-125px text-black' />,
    accessor: 'city',
    Cell: ({...props}) => "Bombay"
  },

  {
    Header: (props) => <UserCustomHeader tableProps={props} title='ZipCode' className='min-w-125px text-black' />,
    accessor: 'zipcode',
    Cell: ({...props}) => "485125"
  },

  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Status' className='min-w-100px' />
    ),
    id: 'two_steps',
    Cell: ({...props}) => <UserTwoStepsCell two_steps={props.data[props.row.index].two_steps} />,
  },
 
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Actions' className='text-end min-w-125px text-black' />
    ),
    id: 'actions',
    Cell: ({...props}) => <UserActionsCell id={props.data[props.row.index].id} />,
  },
]

export {usersColumns}
