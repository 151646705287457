
// import { DriverreportModel, ID, Response } from "../../../../../../_metronic/helpers"

import { DriverreportModel, ID, Response } from "../../../../../../_metronic/helpers"

export type Driverreport = {
  id?: ID
  name?: string
  booking_name?: string
  avatar?: string
  email?: string
  position?: string
  role?: string
  last_login?: string
  two_steps?: boolean
  joined_day?: string
  online?: boolean
  initials?: {
    label: string
    state: string
  }
}

export type DriverreportsQueryResponse = Response<Array<DriverreportModel>>

export const initialDriverreport: Driverreport = {
  avatar: 'avatars/300-6.jpg',
  position: 'Art Director',
  role: 'Administrator',
  name: '',
  email: '',
}
