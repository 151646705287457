/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
// import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
// import {ID, KTSVG, QUERIES} from '../../../../../../../_metronic/helpers'
import { useListView } from '../../core/ListViewProvider'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { deleteUser } from '../../core/_requests'
import { ID, KTSVG, QUERIES } from '../../../../../../_metronic/helpers'
import { MenuComponent } from '../../../../../../_metronic/assets/ts/components'
import { OverlayTrigger } from 'react-bootstrap'
import Tooltip from 'react-bootstrap/Tooltip';
// import DeleteUsersDetails from '../../../DeleteUser'
import { useNavigate } from 'react-router-dom'
import DeleteUsersDetails from '../../../DeleteUserDetails'
// import DeleteUsersDetails from '../../../DeleteUser'
// import DeleteUsersDetails from '../../../DeleteUserType'

type Props = {
  id: ID
}

const UserActionsCell: FC<Props> = ({ id }) => {
  const { setItemIdForUpdate } = useListView()
  const { query } = useQueryResponse()
  const queryClient = useQueryClient()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const navigate = useNavigate()
    const navigateToEditUser = () => {
      // 👇️ navigate to /contacts
      navigate('/edit-user')
    }

    const navigateToViewUserDetails = () => {
      // 👇️ navigate to /contacts
      navigate('/view-user')
    }


  const [ShowDeleteUserDetails, setShowDeleteUserDetails] = useState<boolean>(false)

  return (
    <>
      {/* <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        Actions
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
      </a> */}
      {/* begin::Menu */}
      {/* <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        </div> */}

      <div className='d-flex flex-wrap flex-end'>
      <div className='menu-item px-1'>
          <a className='menu-link p-1'
            onClick={navigateToViewUserDetails}
          >
            <OverlayTrigger
              placement='top'
              overlay={<Tooltip className='py-2 px-2 fs-5 '>View</Tooltip>}
            >
              {/* <i className="fa-regular fa-pen-to-square fs-4 text-warning"></i> */}
              <i className="fa-solid fa-eye fs-4 text-info"></i>
            </OverlayTrigger>
          </a>
        </div>

        <div className='menu-item '>
          <a className='menu-link p-1'
            onClick={navigateToEditUser}
          >
            <OverlayTrigger
              placement='top'
              overlay={<Tooltip className='py-2 px-2 fs-5 '>Edit</Tooltip>}
            >
              <i className="fa-regular fa-pen-to-square fs-4 text-warning"></i>
            </OverlayTrigger>
          </a>
        </div>

        <div className='menu-item '>
          <a className='menu-link p-1' onClick={() => setShowDeleteUserDetails(true)} >
            <OverlayTrigger
              placement='top'
              overlay={<Tooltip className='py-2 px-2 fs-5 '>Delete</Tooltip>}
            >
              <i className="fa-solid fa-trash-can fs-4 text-danger"></i>
            </OverlayTrigger>
          </a>

           <DeleteUsersDetails
           show={ShowDeleteUserDetails} handleClose={() => setShowDeleteUserDetails(false)}
          />   
        </div>

      </div>




      {/* begin::Menu item */}
      {/* <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={openEditModal}>
            Edit
          </a>
        </div> */}
      {/* end::Menu item */}

      {/* begin::Menu item */}
      {/* <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            data-kt-usertypes-table-filter='delete_row'
            onClick={async () => await deleteItem.mutateAsync()}
          >
            Delete
          </a>
        </div> */}
      {/* end::Menu item */}

      {/* end::Menu */}
    </>
  )
}

export { UserActionsCell }
