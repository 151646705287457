import React from "react";
import { CarsListWrapper } from "./cars-list/CarsList";


const CarListing =() => {

    return(

        <>
         <h2> Car List </h2>
         
<div className="card mt-5">
    <CarsListWrapper />
</div>
        
        </>
    )
}

export default CarListing