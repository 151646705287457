import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Select from 'react-select';


const state = [
    { value: 'Gujarat', label: 'Gujarat' },
    { value: 'Maharashtra', label: 'Maharashtra' },
    { value: 'Punjab', label: 'Punjab' },
    { value: 'Rajasthan', label: 'Rajasthan' },
    { value: 'Madhya Pradesh', label: 'Madhya Pradesh' },

];

const city = [
    { value: 'Ahmedabad', label: 'Ahmedabad' },
    { value: 'Surat', label: 'Surat' },
    { value: 'Bombay', label: 'Bombay' },
    { value: 'Jaipur', label: 'Jaipur' },
    { value: 'Bhopal', label: 'Bhopal' },

];

const zipcode = [
    { value: '392456', label: '392456' },
    { value: '396514', label: '396514' },
    { value: '361478', label: '361478' },
    { value: '884512', label: '884512' },
    { value: '963214', label: '963214' },

];

const AddEditDriverDetails = () => {


    const [selectedState, setSelectedState] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [selectedZipcode, setSelectedZipcode] = useState(null);

    return (

        <>
            <div className="card p-6  mt-5">
                <form id='kt_modal_add_tenant_form' className='form' >

                    <Row className="mb-5">
                        <Col sm={12} md={6} lg={4}>
                            <Form.Group className="mb-8" >
                                <Form.Label> Driver Name <span className='fs-6 text-danger'> * </span></Form.Label>
                                <Form.Control className='form-control form-control-solid' placeholder="" required />
                            </Form.Group>

                        </Col>

                        <Col sm={12} md={6} lg={4}>
                            <Form.Group className="mb-8" >
                                <Form.Label> Email Id <span className='fs-6 text-danger'> * </span></Form.Label>
                                <Form.Control className='form-control form-control-solid' placeholder="" required />
                            </Form.Group>

                        </Col>

                        <Col sm={12} md={6} lg={4}>
                            <Form.Group className="mb-8" >
                                <Form.Label> Contact No. <span className='fs-6 text-danger'> * </span></Form.Label>
                                <Form.Control className='form-control form-control-solid' placeholder="" required />
                            </Form.Group>

                        </Col>



                        <Col sm={12} md={6} lg={4}>
                            <Form.Group className="mb-8" >
                                <Form.Label> Licence No. <span className='fs-6 text-danger'> * </span></Form.Label>
                                <Form.Control className='form-control form-control-solid' placeholder="" required />
                            </Form.Group>

                        </Col>
                    </Row>

                    <h3 className="py-3"> Address Details </h3>

                    <Row className="mb-5">
                        <Col sm={12} md={6} lg={4}>
                            <Form.Group className="mb-8" >
                                <Form.Label> Address 1 <span className='fs-6 text-danger'> * </span></Form.Label>
                                <Form.Control className='form-control form-control-solid' placeholder="" required />
                            </Form.Group>

                        </Col>

                        <Col sm={12} md={6} lg={4}>
                            <Form.Group className="mb-8" >
                                <Form.Label> Address 2 <span className='fs-6 text-danger'> * </span></Form.Label>
                                <Form.Control className='form-control form-control-solid' placeholder="" required />
                            </Form.Group>

                        </Col>

                        <Col sm={12} md={6} lg={4}>
                            <Form.Group className="mb-8" >
                                <Form.Label> Country </Form.Label>
                                <Form.Control value={'India'} className='form-control form-control-solid' placeholder="" disabled />
                            </Form.Group>

                        </Col>
                    </Row>
                    <Row className="mb-5">
                        <Col sm={12} md={6} lg={4}>
                            <Form.Group className="" >
                                <Form.Label> State </Form.Label>
                                <Select
                                    defaultValue={selectedState}
                                    options={state}
                                    required />
                            </Form.Group>

                        </Col>

                        <Col sm={12} md={6} lg={4}>
                            <Form.Group className="" >
                                <Form.Label> City</Form.Label>
                                <Select
                                    defaultValue={selectedCity}
                                    options={city}
                                    required />
                            </Form.Group>

                        </Col>

                        <Col sm={12} md={6} lg={4}>
                            <Form.Group className="" >
                                <Form.Label> Zipcode </Form.Label>
                                <Select
                                    defaultValue={selectedZipcode}
                                    options={zipcode}
                                    required />
                            </Form.Group>

                        </Col>


                    </Row>

                    <div className="separator my-8"></div>

                    <div className='text-center pt-5'>

                        <button
                            type='submit'
                            className='btn btn-primary me-3'
                            data-kt-users-modal-action='submit'
                        >
                            <span className='indicator-label'>Submit</span>
                        </button>

                        <button
                            type='reset'
                            className='btn btn-light-danger '
                            data-kt-users-modal-action='cancel'
                        >
                            Cancel
                        </button>
                    </div>
                </form>

            </div>
        </>
    )
}

export default AddEditDriverDetails