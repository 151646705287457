import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Select from 'react-select';
import AddEditUserDetails from "./AddEditUserDetails";




const AddUserDetails = () => {

    const navigate = useNavigate()
    const navigateToUserListing = () => {
        // 👇️ navigate to /contacts
        navigate('/user-list')
    }

    

    return (

        <>

            <Row className="row mx-0">
                <Col md={8} sm={12}><h1> Add User Details </h1></Col>
                <Col md={4} sm={12} className="text-end">
                    <button
                        onClick={navigateToUserListing}
                        type='button'
                        value=''
                        className='btn btn-sm fs-5 me-5 btn btn-primary'
                    >
                        Back
                    </button>
                </Col>
            </Row>



            <div className="card p-6  mt-5">
                <AddEditUserDetails />
                
            </div>
        </>
    )
}

export default AddUserDetails