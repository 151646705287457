// @ts-nocheck
import {Column} from 'react-table'
import {BookingreportInfoCell} from './BookingreportInfoCell'
import {BookingreportLastLoginCell} from './BookingreportLastLoginCell'
import {BookingreportTwoStepsCell} from './BookingreportTwoStepsCell'
import {BookingreportActionsCell} from './BookingreportActionsCell'
import {BookingreportSelectionCell} from './BookingreportSelectionCell'
import {BookingreportCustomHeader} from './BookingreportCustomHeader'
import {BookingreportSelectionHeader} from './BookingreportSelectionHeader'
import {Bookingreport} from '../../core/_models'

const bookingreportsColumns: ReadonlyArray<Column<Bookingreport>> = [
  // {
  //   Header: (props) => <BookingreportSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <BookingreportSelectionCell id={props.data[props.row.index].id} />,
  // },

  {
    Header: (props) => <BookingreportCustomHeader tableProps={props} title='Date' className='min-w-125px text-black' />,
    accessor: 'date',
    Cell: ({...props}) => "2 Mar 2024"
  },
  {
    Header: (props) => <BookingreportCustomHeader tableProps={props} title='Booking No' className='min-w-125px text-black' />,
    accessor: 'booking_number',
    Cell: ({...props}) => "1531"
  },


  {
    Header: (props) => <BookingreportCustomHeader tableProps={props} title='Pick Up Location' className='min-w-125px text-black' />,
    accessor: 'pick_up_location',
    Cell: ({...props}) => "Maninagar"
  },

  {
    Header: (props) => <BookingreportCustomHeader tableProps={props} title='Destination' className='min-w-125px text-black' />,
    accessor: 'destination',
    Cell: ({...props}) => "Udaipur"
  },

  {
    Header: (props) => <BookingreportCustomHeader tableProps={props} title='Pick Up Date' className='min-w-125px text-black' />,
    accessor: 'pick_up_date',
    Cell: ({...props}) => "24 Mar 2024"
  },

  {
    Header: (props) => <BookingreportCustomHeader tableProps={props} title='Pick Up Time' className='min-w-125px text-black' />,
    accessor: 'pick_up_time',
    Cell: ({...props}) => "6:00am"
  },

  {
    Header: (props) => <BookingreportCustomHeader tableProps={props} title='Round Trip Date' className='min-w-125px text-black' />,
    accessor: 'round_trip_date',
    Cell: ({...props}) => "27 Mar 2024"
  },

  {
    Header: (props) => <BookingreportCustomHeader tableProps={props} title='Round Trip Time' className='min-w-125px text-black' />,
    accessor: 'round_trip_time',
    Cell: ({...props}) => "11:30pm"
  },

  // {
  //   Header: (props) => <BookingreportCustomHeader tableProps={props} title='User Email' className='min-w-125px text-black' />,
  //   accessor: 'user_email',
  //   Cell: ({...props}) => "thomsonvellay@rentify.com"
  // },

  // {
  //   Header: (props) => <BookingreportCustomHeader tableProps={props} title='User Phone No' className='min-w-125px text-black' />,
  //   accessor: 'user_phone',
  //   Cell: ({...props}) => "9825436589"
  // },
  {
    Header: (props) => <BookingreportCustomHeader tableProps={props} title='Car Modal' className='min-w-125px text-black' />,
    accessor: 'car_modal',
    Cell: ({...props}) => "Toyota Corolla"
  },
  {
    Header: (props) => <BookingreportCustomHeader tableProps={props} title='Car Number' className='min-w-125px text-black' />,
    accessor: 'car_number',
    Cell: ({...props}) => "GJ-1 JG 0165"
  },

  {
    Header: (props) => <BookingreportCustomHeader tableProps={props} title='Driver Name' className='min-w-125px text-black' />,
    accessor: 'driver_name',
    Cell: ({...props}) => "Thomson Vellay"
  },

  // {
  //   Header: (props) => <BookingreportCustomHeader tableProps={props} title='Licence No' className='min-w-125px text-black' />,
  //   accessor: 'licence_number',
  //   Cell: ({...props}) => "DL-547123654870"
  // },

  {
    Header: (props) => <BookingreportCustomHeader tableProps={props} title='Rate' className='min-w-125px text-black' />,
    accessor: 'rate',
    Cell: ({...props}) => "QAR 5500"
  },


  {
    Header: (props) => (
      <BookingreportCustomHeader tableProps={props} title='Status' className='min-w-100px' />
    ),
    id: 'two_steps',
    Cell: ({...props}) => <BookingreportTwoStepsCell two_steps={props.data[props.row.index].two_steps} />,
  },
 
  {
    Header: (props) => (
      <BookingreportCustomHeader tableProps={props} title='Actions' className='text-end min-w-125px text-black' />
    ),
    id: 'actions',
    Cell: ({...props}) => <BookingreportActionsCell id={props.data[props.row.index].id} />,
  },
]

export {bookingreportsColumns}
